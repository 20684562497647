import React, { useState } from 'react'
import styles from './TanComponents.module.css'
import Button from 'components/Button'
import PlayIcon from 'components/PlayIcon'
import LocationModal from 'components/LocationModal'
import HomeLocationIcon from 'components/HomeLocationIcon'
import RadioButtonLocationItem from 'components/RadioButtonLocationItem'
import WhatNewCard from 'components/WhatNewCard'
interface IProps {

}

export const TanComponents: React.FC<IProps> = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [radioNumber, setRadioNumber] = useState(1)

  return (
    <div className={''}>
      <div className={''}>
        <Button title='check out' />
        <PlayIcon onClick={() => console.log('play Icon')} />
        <p onClick={() => setIsOpen(true)}>map</p>
      </div>
      {isOpen && <LocationModal venueName='Anaheim A, CA' address='12021 Harbor BLVD Garden Grove, CA 92840 USA' onChangeVisible={(visible) => setIsOpen(visible)} />}
      <HomeLocationIcon className={styles.logo} />
      <RadioButtonLocationItem name='Ontario' address1='2000 E Convention Center Way Ontario, CA 91764' isSelected={radioNumber === 1} onChange={() => setRadioNumber(1)} />
      <RadioButtonLocationItem name='San Diego' address1='805 Magnolia Ave' address2='Santee, CA 92071' isSelected={radioNumber === 2} onChange={() => setRadioNumber(2)} />
      <RadioButtonLocationItem isSelected={radioNumber === 3} onChange={() => setRadioNumber(3)} />
      <WhatNewCard
        header='HomeSite'
        subHeader='Location'
        description='Choose the location you typically attend for your Local Functions. You can still attend other locations.'
        footer='Note: You can edit your addresses in the Settings app.'
      >
        <RadioButtonLocationItem name='Ontario' address1='2000 E Convention Center Way Ontario, CA 91764' isSelected={radioNumber === 1} onChange={() => setRadioNumber(1)} />
        <RadioButtonLocationItem name='San Diego' address1='805 Magnolia Ave' address2='Santee, CA 92071' isSelected={radioNumber === 2} onChange={() => setRadioNumber(2)} />
      </WhatNewCard>
    </div>
  )
}
