import PropTypes from "prop-types";
import styles from "./TicketSummary.module.css";
import { format, parseISO } from "date-fns";

/**
 * View component displays a summary of tickets under a heading showing the event name.
 */

export const TicketSummary = ({ city, state, eventId, startsOn, endsOn, tickets }) => {
  const Tickets = tickets.map((ticket) => (
    <div key={ticket.id} className={styles.ticket}>
      {/* Ticket > ID */}
      <div className={styles.ticketId}>Ticket #{ticket.orderAccountingId}</div>
      {/* Ticket > Price */}
      <div className={styles.ticketPrice}>${ticket.cost.toFixed(2)}</div>
      {/* Ticket Type */}
      <div className={styles.ticketType}>{ticket.type}</div>
    </div>
  ));

  return (
    <div className={styles.ticketSummary}>
      <div className={styles.containerEvent}>
        <div className={styles.containereventId}>
          {/* Event Name */}
          <span className={styles.eventId}>
            {city} {state}
          </span>
        </div>
        <div className={styles.containerstartsOn}>
          {/* Start Date */}
          <span className={styles.startsOn}>
            {format(parseISO(startsOn), "MM.dd.yyy")}
          </span>
        </div>
        <div className={styles.containerDateSeparator}>
          {/* Date Seperator */}
          <span className={styles.dateSeparator}>&nbsp;-&nbsp;</span>
        </div>
        <div className={styles.containerendsOn}>
          {/* End Date */}
          <span className={styles.endsOn}>
            {format(parseISO(endsOn), "MM.dd.yyy")}
          </span>
        </div>
      </div>
      <div className={styles.containerTickets}>{Tickets}</div>
    </div>
  );
};

TicketSummary.propTypes = {
  eventId: PropTypes.string.isRequired,
  startsOn: PropTypes.string.isRequired,
  endsOn: PropTypes.string.isRequired,
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      orderAccountingId: PropTypes.number.isRequired,
      price: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};
