import { BlockHeader } from "components/BlockHeader/BlockHeader";
import { EagleLookup } from "components/EagleLookup/EagleLookup";
import { RegisteredEagle } from "components/RegisteredEagle/RegisteredEagle";
import styles from "./EagleRegistration.module.css";
import { useEaglesData } from "./useEaglesData";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import env from "../../environment"

export function EagleRegistration() {
  const eaglesData = useEaglesData();

  function onDeleteClicked(id) {
    const eaglesId = id;
    eaglesData.deleteEagleMutation.mutate(eaglesId);
  }

  function onSubmit(info) {
    eaglesData.addEagleMutation.mutate(info);
  }

  async function lookupFn(id) {
    return makeAuthedRequest(`/ibos/${id}`, { method: "get" }, env.apiBaseOld).then(
      async (res) => {
        const json = await res.json();
        if (res.ok) {
          return json;
        } else {
          throw new Error(json.message);
        }
      }
    );
  }

  const qualifications = [
    {
      id: 1,
      name: "Eagle",
    },
    {
      id: 2,
      name: "Double Eagle",
    },
  ];

  return (
    <div className={styles.eaglesPage}>
      <div className={styles.header}>Add Eagle/Double Eagle</div>
      <div className={styles.listTitle}>Reporting Eagles</div>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          Eagles and Double Eagles must have qualified in either August or
          September 2021.
        </li>
        <li className={styles.listItem}>
          Reporting Eagles and Double Eagles must be done by 11:59 PM PT on
          cutoff date.
        </li>
      </ul>
      <div className={styles.listTitle}>Reporting Cutoff Dates</div>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <b>Las Vegas #1</b> - 10.04.2021 11:59 PM PT
        </li>
        <li className={styles.listItem}>
          <b>Las Vegas #2</b> - 10.04.2021 11:59 PM PT
        </li>
        <li className={styles.listItem}>
          <b>Virtual</b> - 10.04.2021 11:59 PM PT
        </li>
      </ul>

      <EagleLookup
        events={eaglesData.isLoading === false ? eaglesData.data.locations : ""}
        lookupFn={lookupFn}
        qualifications={qualifications}
        onSubmit={onSubmit}
      />
      <BlockHeader title={"Registered Eagles"} />

      {eaglesData.isLoading === false &&
      eaglesData.data.eagles &&
      eaglesData.data.eagles.length > 0
        ? eaglesData.data.eagles.map((eagle, index) => {
            return (
              <div className={styles.registeredEagle}>
                <RegisteredEagle
                  id={eagle.id}
                  name={eagle.name}
                  eventName={eagle.location}
                  qualification={eagle.qualification}
                  onDelete={() => onDeleteClicked(eagle.id)}
                />
              </div>
            );
          })
        : eaglesData.error}
    </div>
  );
}
