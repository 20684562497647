import { useEffect } from "react";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useQuery, useMutation, useQueryClient } from "react-query";
import env from "../../environment";

export function useMajorFunctions() {
  const { data, isLoading, refetch } = useQuery("majorFunctionData", () =>
    makeAuthedRequest(
      "major-functions",
      {
        method: "GET",
      },
      env.apiBase
    ).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error((await res.json()).message);
      }
    })
  );

  return {
    data,
    isLoading,
    refetch,
  };
}
