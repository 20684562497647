import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./CartCheckout.module.css";
import CartList from "components/CartList";
import Footer from "components/Footer";
import TermAndCondition from "components/TermAndCondition";
import { CartHeader } from "components/CartHeader/CartHeader";
import { useCartInfo } from "pages/CartPage/useCartInfo";
import { useCart } from "stores/CartContext";
import { CardListModal } from "components/CardListModal/CardListModal";
import Loader from "components/Loader";

export const CartCheckout = () => {
  const history = useHistory();
  const { data: itemsData = [], isLoading } = useCartInfo();
  const { updateCartItem } = useCart();

  const [isOpenTermAndCondition, setIsOpenTermAndCondition] = useState(false);

  // const handleCartChange = (ticketId, newPrice) => {
  //   updateCartItem(ticketId, newPrice);
  // };

  const handlePlaceOrder = () => {
    setIsOpenTermAndCondition(true);
  };

  const handleTermsAndCndition = () => {
    //TODO: Place the order or confirm the purchase
    setIsOpenTermAndCondition(false);
    history.go(-3);
  };

  if (isLoading)
    return (
      <div>
        <Loader color={"#415364"} size={85} />
      </div>
    );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <CartHeader
            // events={events}
            name={"Regional"}
            className={""}
            isCartPage={false}
          />
          <div className={styles.hr}></div>
        </div>
        <div className={styles.body}>
          <CartList
            location={"Spokane, WA"}
            eventDate={"October 8 - 10, 2024"}
            items={itemsData}
            // onItemsChange={handleCartChange}
            onItemsChange={() => {}}
            onDelete={() => {}}
            isCartPage={false}
          />
        </div>

        {/* <div className={styles.cartCheckoutFooter}> */}
        <Footer isOpen={true} onClose={() => {}}>
          <div className={styles.footer}>
            <div className={styles.footerText}>
              {/* You have 17:45 left to complete this purchase. */}
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.cancel}
                onClick={() => {
                  history.go(-2);
                }}
              >
                Cancel
              </button>
              <button className={styles.action} onClick={handlePlaceOrder}>
                Place Your Order
              </button>
            </div>
          </div>
        </Footer>
        {/* </div> */}

        {isOpenTermAndCondition && (
          <TermAndCondition
            isOpen={isOpenTermAndCondition}
            onClose={() => {
              setIsOpenTermAndCondition(false);
            }}
            onClick={handleTermsAndCndition}
          />
        )}
      </div>
    </>
  );
};
