import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "utils";
import styles from "./MajorFunctions.module.css";
import { MajorFunctionsList } from "./MajorFunctionsList/MajorFunctionsList";
import { EmergencyContactModal } from "components/EmergencyContactModal/EmergencyContactModal";
import { IMajorFunctions } from "../../stores/majorInterface";

import { TicketStatus } from "stores/interfaces.types";

import { useUser, useCart, useCartAddPost } from "../../stores/CartContext";
import Footer from "components/Footer";
import Button from "components/Button";
import CartPopup from "components/CartPopup";
import { useMajorFunctions } from "./useMajorFunctions";
// import { useUser } from "stores/useUser";
import Loader from "components/Loader";

export const MajorFunctions = () => {
  const history = useHistory();
  const { addToCart } = useCart();
  const { data: majorFuncData, isLoading: majorFunctionLoading } =
    useMajorFunctions();
  const { data: userData, isLoading: userLoading } = useUser();

  const cartAddPost = useCartAddPost();

  const [isAddToCartOpen, setIsAddToCartOpen] = useState(false);
  const [isFooterOpen, setIsFooterOpen] = useState(false);

  const [users, setUsers] = useState([]);

  // const [functions, setEventSet] = useState([]);
  // const [selectedFunction, setSelectedFunction] = useState({} as IFunction);
  const [majorEvents, setMajorEvents] = useState<IMajorFunctions>(
    {} as IMajorFunctions
  );

  const [eventSet, setEventSet] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentEventIndex, setCurrentEventIndex] = useState<number>(0);

  const [ticketCount, setTicketCount] = useState(1);
  const [eventCount, setEventCount] = useState(1);

  const [total, setTotal] = useState(0);
  const [currencyCodes, setCurrencyCodes] = useState([]);
  const [selectedEventWithUser, setSelectedEventWithUser] = useState(null);

  useEffect(() => {
    if (majorFuncData && majorFuncData.length > 0) {
      setEventSet(majorFuncData);
      setMajorEvents(majorFuncData[0]);
      setCurrentEventIndex(0);
    } else {
      setEventSet([]);
      setMajorEvents({} as IMajorFunctions);
      setCurrentEventIndex(-1);
    }
  }, [majorFuncData]);

  useEffect(() => {
    if (userData) {
      setUsers(userData);
    }
  }, [userData]);

  //Combine events and user
  useEffect(() => {
    if (majorFuncData && userData && currentEventIndex >= 0) {
      const selectedEvent = majorFuncData[currentEventIndex];

      const itemsWithUsers = selectedEvent.items?.map((eventItem) => {
        const usersWithTickets = userData?.map((user) => {
          const userTicketsForEvent = user.tickets
            ? user.tickets.filter(
                (ticket) => ticket.eventId === eventItem.eventId
              )
            : [];

          return {
            ...user,
            tickets: userTicketsForEvent,
          };
        });

        return {
          ...eventItem,
          users: usersWithTickets,
        };
      });

      const selectedEventWithUsers = {
        ...selectedEvent,
        items: itemsWithUsers,
      };

      setSelectedEventWithUser(selectedEventWithUsers);
    }
  }, [majorFuncData, userData, currentEventIndex]);

  useEffect(() => {
    if (isAddToCartOpen && selectedItems.length > 0) {
      selectedItems.forEach((item) => addToCart(item));
      setSelectedItems([]);
    }
  }, [selectedItems, isAddToCartOpen, addToCart]);

  //Count event and tickets
  useEffect(() => {
    if (!selectedItems || selectedItems.length === 0) {
      setEventCount(0);
      setTicketCount(0);
      setTotal(0);
      setCurrencyCodes([]);
      setIsFooterOpen(false);
      return;
    }

    // Flatten tickets from selectedItems and filter those with TicketStatus.CART
    const updatedTicketList = selectedItems
      .flatMap((user) => user.tickets)
      .filter((ticket) => ticket.ticketStatusId === TicketStatus.CART);

    // Calculate the ticket count
    const newTicketCount = updatedTicketList.length;

    // Calculate unique event count by using eventId
    const uniqueEventIds = new Set(
      updatedTicketList.map((ticket) => ticket.eventId)
    );
    const newEventCount = uniqueEventIds.size;

    // Calculate total price and collect currency codes
    let newTotal = 0;
    const currencyCodesSet = new Set();

    updatedTicketList.forEach((ticket) => {
      const price = ticket.amount || 0;
      newTotal += price;

      if (ticket.currencyCode) {
        currencyCodesSet.add(ticket.currencyCode);
      }
    });

    // Set state values
    setTicketCount(newTicketCount);
    setEventCount(newEventCount);
    setTotal(newTotal);
    setCurrencyCodes(Array.from(currencyCodesSet));

    // Open the footer if there are tickets selected
    setIsFooterOpen(newTicketCount > 0);
  }, [selectedItems]);

  //Handles the arrow button which changes the events
  const onChangeSelectedFunction = (isForward: boolean) => {
    let currentIndex = majorFuncData.findIndex(
      (event) => event.eventTemplateId === majorEvents.eventTemplateId
    );
    let selectedIndex = -1;

    if (isForward) {
      if (currentIndex < eventSet.length - 1) {
        selectedIndex = currentIndex + 1;
      }
    } else {
      if (currentIndex > 0) {
        selectedIndex = currentIndex - 1;
      }
    }

    // setMajorEvents(majorFuncData[selectedIndex]);
    if (selectedIndex !== currentIndex) {
      setMajorEvents(eventSet[selectedIndex]);
      setCurrentEventIndex(selectedIndex);
    }
  };

  const onClickAdd = (event, user) => {
    if (!event || !user) {
      return;
    }

    const newTicket = {
      companyId: user.tickets?.[0]?.companyId || null,
      userId: user.userId,
      eventId: event.eventId,
      ticketStatusId: TicketStatus.CART,
      statusStr: "CART",
      type: event.eventType || "MAJOR",
      title: event.title || "Major Function Ticket",
      startsOn: event.startsOn,
      endsOn: event.endsOn,
      taxAmount: 0,
      taxRate: 0,
      ticket: event.ticket,
    };

    setSelectedItems((prevItems) => {
      const userExists = prevItems.find((item) => item.userId === user.userId);

      if (userExists) {
        return prevItems.map((item) => {
          if (item.userId === user.userId) {
            return {
              ...item,
              tickets: [...item.tickets, newTicket],
            };
          }
          return item;
        });
      } else {
        const newUserEntry = {
          ...user,
          tickets: [newTicket],
        };
        return [...prevItems, newUserEntry];
      }
    });
  };

  const onClickCancel = (event, user) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = prevSelectedItems
        .map((item) => {
          if (item.userId === user.userId) {
            const updatedTickets = item.tickets.filter(
              (ticket) => ticket.eventId !== event.eventId
            );

            if (updatedTickets.length === 0) {
              return null;
            }

            return {
              ...item,
              tickets: updatedTickets,
            };
          }

          return item;
        })
        .filter(Boolean);

      return updatedSelectedItems;
    });
  };

  async function handleAddToCart() {
    if (selectedItems.length === 0) return;

    const newCartItems = selectedItems.flatMap((item) =>
      (item.tickets || [])
        .filter((ticket) => ticket.eventId && item.userId)
        .map((ticket) => ({
          contactId: item.contactId,
          ticketId: ticket.ticket[0].ticketId || null, //Temporary
          userId: item.userId,
          eventId: ticket.eventId,
          isTandem: false,
          amount: ticket.amount || 0,
        }))
    );

    if (newCartItems.length === 0) {
      return;
    }

    try {
      await cartAddPost.mutateAsync(newCartItems);

      setSelectedItems([]);

      setIsAddToCartOpen(true);
      setTimeout(() => {
        setIsAddToCartOpen(false);
        history.push(PATHS.CART);
      }, 700);
    } catch (error) {
      console.error("Error adding items to cart:", error);
    }
  }

  const onClickBuyNow = () => {
    setShowModal(true);
  };

  const handleRedirectToCart = () => {
    setShowModal(false);
    history.push(PATHS.CART_CHECKOUT);
  };

  if (majorFunctionLoading || userLoading) {
    return (
      <div>
        <Loader color={"#415364"} size={85} />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <MajorFunctionsList
        selectedFunction={majorEvents}
        onNext={() => onChangeSelectedFunction(true)}
        onPrevious={() => onChangeSelectedFunction(false)}
        users={userData}
        onClickAdd={(event, user) => onClickAdd(event, user)}
        onClickCancel={(event, user) => onClickCancel(event, user)}
        onClickPurchased={() => console.log("onClickPurchased")}
        isNextDisabled={currentEventIndex === eventSet.length - 1}
        isPreviousDisabled={currentEventIndex === 0}
      />

      {isFooterOpen && (
        <>
          <Footer isOpen={isFooterOpen} onClose={() => {}}>
            <div className={styles.footerAddToCart}>
              <div className={styles.cartPrice}>
                <b>{eventCount}</b> Events <b>x {ticketCount}</b> Tickets ={" "}
                <b>
                  {Array.from(currencyCodes).join(", ")} ${total.toFixed(2)}
                </b>
              </div>
              <div className={styles.btn}>
                <Button
                  title="Add to Cart"
                  onClick={handleAddToCart}
                  className={styles.add}
                />
                <Button
                  title="Buy Now"
                  onClick={handleRedirectToCart}
                  className={styles.buy}
                />
              </div>
            </div>
          </Footer>

          <CartPopup isOpen={isAddToCartOpen} />
        </>
      )}
    </div>
  );
};
