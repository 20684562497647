/**
 * Extracts and formats the date and time from an ISO 8601 formatted string.
 * @param {string} isoString - The ISO 8601 date string.
 * @returns {object} An object containing formatted date and time as separate strings.
 */

import { format } from "date-fns";

export function extractDate(isoString) {
  const date = new Date(isoString);

  // Formatting date as "January 23, 2024"
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

// const test = "2023-09-18T20:00:00Z";
// console.log("DATE:", extractDate(test));

export function extractTime(isoString) {
  const time = new Date(isoString);

  // Formatting time as "8:00 PM"
  return time.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
}

//Format date (Monday, September 24)
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

export const formatInUTC = (date, dateFormat) => {
  let parsedDate;

  if (typeof date === "string") {
    parsedDate = new Date(date);
  } else if (date instanceof Date) {
    parsedDate = date;
  } else {
    throw new RangeError("Invalid time value");
  }

  if (isNaN(parsedDate.getTime())) {
    throw new RangeError("Invalid time value");
  }

  return format(
    new Date(
      parsedDate.getUTCFullYear(),
      parsedDate.getUTCMonth(),
      parsedDate.getUTCDate(),
      parsedDate.getUTCHours(),
      parsedDate.getUTCMinutes(),
      parsedDate.getUTCSeconds()
    ),
    dateFormat
  );
};

//Format range date and check if they are the same date
// June 10 - 11, 2024
export const formatDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return "";
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    return "";
  }

  // Compare only the date parts (year, month, day)
  const isSameDay =
    start.getUTCFullYear() === end.getUTCFullYear() &&
    start.getUTCMonth() === end.getUTCMonth() &&
    start.getUTCDate() === end.getUTCDate();

  if (isSameDay) {
    return formatInUTC(start, "MMMM dd, yyyy");
  }

  // const startDay = formatInUTC(start, "MMMM dd");
  // const endDay = formatInUTC(end, "dd, yyyy");

  // return `${startDay} - ${endDay}`;

  const startMonth = start.getUTCMonth();
  const endMonth = end.getUTCMonth();
  const startYear = start.getUTCFullYear();
  const endYear = end.getUTCFullYear();

  if (startMonth === endMonth && startYear === endYear) {
    const startDay = format(start, "MMMM dd");
    const endDay = format(end, "dd, yyyy");
    return `${startDay} - ${endDay}`;
  } else {
    const startDay = format(start, "MMMM dd");
    const endDay = format(end, "MMMM dd, yyyy");
    return `${startDay} - ${endDay}`;
  }
};

export const extractTimeAndTimezone = (isoString) => {
  if (!isoString) {
    return "";
  }

  const time = new Date(isoString);

  if (isNaN(time.getTime())) {
    return "";
  }

  // Formatting time as "8:00 AM"
  let formattedTime = time.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  // Replace "AM" with "a.m." and "PM" with "p.m."
  formattedTime = formattedTime.replace("AM", "a.m.").replace("PM", "p.m.");

  return formattedTime;
};

//Format date range without year (ex. June 10 - 11)
export const formatDateNoYear = (startDate, endDate) => {
  // Check if startDate and endDate are provided
  if (!startDate || !endDate) {
    return "";
  }

  // Parse the dates
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Check if dates are valid
  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    return "";
  }

  // Check if startDate and endDate are the same
  if (start.toDateString() === end.toDateString()) {
    return format(start, "MMMM d");
  }

  const startMonthDay = format(start, "MMMM d");
  const endDay = format(end, "d");

  const isSameMonth =
    start.getUTCFullYear() === end.getUTCFullYear() &&
    start.getUTCMonth() === end.getUTCMonth();

  if (isSameMonth) {
    return `${startMonthDay} - ${endDay}`;
  }

  const endMonthDay = format(end, "MMMM d");
  return `${startMonthDay} - ${endMonthDay}`;
};
