import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Ticket.module.css";

import { Button, Selector } from "@wwg/ui";

/**
 * This component allows the user to configure what type of ticket they are going to purchase and some accessibility options that go along with the ticket.
 */

export function Ticket({
  orderAccountingId,
  cost,
  priceTypeId,
  onSpecialNeedsClick,
  onDeleteClick,
  onTicketTypeChange,
}) {
  return (
    // Ticket container
    <div className={styles.ticket}>
      {/* Ticket > ID */}
      <div className={styles.orderAccountingId}>
        Ticket #{orderAccountingId}
      </div>
      {/* Ticket > cost */}
      <div className={styles.cost} aria-label="">
        ${cost.toFixed(2)}
      </div>
      {/* Ticket > Select Types */}
      <div className={styles.containerSelectTicketTypes} aria-label="cost">
        <Selector
          className={styles.selectTicketTypes}
          onChange={onTicketTypeChange}
          aria-label="select ticket types"
          value={priceTypeId}
        >
          {/* {ticketTypes.map((opt) => (
            <option key={opt.id} value={opt.id}>
              {opt.label}
            </option>
          ))} */}
        </Selector>
      </div>
      {/* Ticket Button > Special Needs */}
      <div className={styles.containerBtnSpecialNeeds}>
        <Button
          className={styles.btnSpecialNeeds}
          label="Special Needs"
          onClick={onSpecialNeedsClick}
        />
      </div>
      {/* Ticket Button > Delete */}
      <div className={styles.containerBtnDelete}>
        <Button
          className={styles.btnDelete}
          label="Delete"
          buttonStyle="danger"
          onClick={onDeleteClick}
        />
      </div>
    </div>
  );
}

Ticket.propTypes = {
  orderAccountingId: PropTypes.number.isRequired,
  cost: PropTypes.number.isRequired,
  priceTypeId: PropTypes.number.isRequired,
  onSpecialNeedsClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onTicketTypeChange: PropTypes.func.isRequired,
};
 