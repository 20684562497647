import React, { useEffect, useState } from 'react'
import CartPopup from 'components/CartPopup';
import TermAndCondition from 'components/TermAndCondition';
interface IProps {

}

export const KhoaComponents: React.FC<IProps> = props => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // if (isOpen)
    //   setTimeout(() => setIsOpen(false), 1000)
  }, [isOpen])
  return (
    <div className={''}>
      <div className={''}>
        <div onClick={() => setIsOpen(true)}>Test</div>
        {/* {isOpen && <CartPopup isOpen={true} />} */}
        {isOpen && <TermAndCondition isOpen={true} />}
      </div>
    </div>
  )
}
