import PropTypes from "prop-types";
import styles from "./ScrollRequiredModal.module.css";
import { Button } from "@wwg/ui";
import { useState } from "react";

/**
 * This is an interactive component that will be displayed inside of a modal. The controls at the bottom block the user from continuing until the content has been scrolled through to the end. Once the user scrolls to the bottom of the content the Yes button is enabled.
 */

export function ScrollRequiredModal({ title, content, onYes, onNo }) {
  const [bottom, setBottom] = useState(false);

  const handleScroll = (e) => {
    if (!bottom) {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      setBottom(bottom);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.title}>{title}</h1>
      </div>
      <div
        className={styles.innerContainer}
        onScroll={handleScroll}
        data-testid="scrollContainer"
      >
        <div className={styles.termsAndConditions}>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
      <div className={styles.controls}>
        <Button
          disabled={bottom === false ? true : false}
          className={styles.btnYes}
          onClick={onYes}
        >
          Yes
        </Button>{" "}
        <Button buttonStyle="hollow" onClick={onNo} className={styles.btnNo}>
          No
        </Button>
      </div>
    </div>
  );
}

ScrollRequiredModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
};
