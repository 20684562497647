import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./EagleLookup.module.css";
import { Button, Selector, SearchInput } from "@wwg/ui";

/**
 * This is an interactive component that provides an interface for searching for an IBO by IBO number, then choosing the event and their qualification for that event, and finally submitting the registration. The initial state shows a search field and a search button. The user will enter one of their downline's IBO numbers to find them. If found, the IBO's name will be displayed above the event field (displaying a dropdown list of upcoming events to choose from) and a qualification field (showing Eagle or Double Eagle). One value for each field must be selected before the Submit button can be clicked. If Cancel is clicked, the component goes back to its initial state showing the search input.
 */

export function EagleLookup({ qualifications, events, onSubmit, lookupFn }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [qualification, setQualification] = useState("0");
  const [eventId, setEventId] = useState("0");
  const [location, setLocation] = useState("");
  const [iboInfo, setIboInfo] = useState(null);
  const [error, setError] = useState(null);

  const onSearch = (value) => {
    setSearchTerm(value);
  };

  const changeQualification = (value) => {
    setQualification(value);
  };

  const changeEvent = (value) => {
    setEventId(value);
    const event = events.filter((event) => event.eventId === parseInt(value));
    setLocation(event[0].name);
  };

  const onSearchClicked = () => {
    lookupFn(searchTerm).then((response) => {
      if(response.length > 0) {
        setIboInfo(response[0]);
        setError(null);
      } else {
        setError("IBO Not Found");
        setIboInfo(null);
      }
    });

  };

  const cancelLookupClicked = () => {
    setIboInfo(null);
    setEventId("0");
    setQualification("0");
    setSearchTerm("");
  };

  return (
    <div>
      <div>
        <div className={styles.row}>
          <div className={classNames(styles.search)}>
            <SearchInput
              placeholder="Enter IBO #"
              onSearch={onSearch}
              value={searchTerm}
            />
          </div>
          <div className={classNames(styles.button)}>
            <Button
              disabled={searchTerm.length === 0 ? true : false}
              onClick={onSearchClicked}
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      {error !== null ? (
        <div className={styles.error}>
          {error}
        </div>
      ) : ""}

      {iboInfo !== null ? (
        <div>
          <div className={styles.iboName}>{iboInfo.name}</div>
          <Selector
            data-testid="event"
            onChange={(e) => {
              changeEvent(e.target.value);
            }}
          >
            <option value="0">Locations</option>
            {events.map((event, index) => {
              return (
                <option key={index} value={event.eventId}>
                  {event.name}
                </option>
              );
            })}
          </Selector>
          <div className={styles.marginBottom}></div>
          <Selector
            data-testid="qualification"
            onChange={(e) => {
              changeQualification(e.target.value);
            }}
          >
            <option value="0">Qualification</option>
            {qualifications.map((qualification, index) => {
              return (
                <option key={index} value={qualification.name}>
                  {qualification.name}
                </option>
              );
            })}
          </Selector>

          <div className={styles.btns}>
            <Button
              data-testid="register"
              disabled={qualification === "0" || eventId === "0" ? true : false}
              className={styles.btnSubmit}
              onClick={() =>
                onSubmit({
                  eventId: eventId,
                  ibo: iboInfo.id,
                  location: location,
                  name: iboInfo.name,
                  qualification: qualification,
                })
              }
            >
              Register
            </Button>
            <Button buttonStyle="hollow" onClick={cancelLookupClicked}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

EagleLookup.propTypes = {
  qualifications: PropTypes.arrayOf(
    PropTypes.shape([
      {
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      },
    ])
  ),
  events: PropTypes.arrayOf(
    PropTypes.shape([
      {
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      },
    ])
  ),
  onSubmit: PropTypes.func,

  lookupFn: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired,
  }),
};
