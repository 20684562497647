import React, { FC, useState, useEffect } from "react";
import styles from "./LocalFunctionTicketPurchaseItem.module.css";
import Add_Button from "assets/icons/Add_Button.svg";
import Close_button from "assets/icons/Close_button.svg";
import Ticket_Added from "assets/icons/Ticket_Added.svg";
import Purchased_Icon from "assets/icons/Purchased_Icon.svg";
import classNames from "classnames";
import {
  TicketStatus,
  UserIBO,
  EventSessions,
  EventSets,
} from "stores/interfaces.types";
import { extractFirstName } from "utils/extractFirstName";
import user_Placeholder from "assets/icons/User_Placeholder.svg";

interface IProps {
  user: UserIBO;
  // session: EventSessions;
  eventSet: EventSets;
  onClickAdd: Function;
  onClickPurchased: Function;
  onClickCancel: Function;
}

export const LocalFunctionTicketPurchaseItem: FC<IProps> = ({
  user,
  eventSet,
  onClickAdd,
  onClickPurchased,
  onClickCancel,
}) => {
  const currentTicket = user.tickets.find((ticket) => {
    return eventSet.eventSetTickets.some(
      (setTicket) => setTicket.ticketId === ticket.eventTicketId
    );
  });

  const [ticketStatus, setTicketStatus] = useState(
    currentTicket?.ticketStatusId || TicketStatus.AVAILABLE
  );

  const firstName = extractFirstName(user.name);

  useEffect(() => {
    if (currentTicket) {
      setTicketStatus(currentTicket.ticketStatusId);
    }
  }, [currentTicket]);

  const handleAddClick = () => {
    setTicketStatus(TicketStatus.CART);
    onClickAdd(eventSet, user);
  };

  const handleCancelClick = () => {
    setTicketStatus(TicketStatus.AVAILABLE);
    onClickCancel(eventSet, user);
  };

  return (
    <div className={styles.container}>
      <div className={styles.ticket}>
        <div>
          {" "}
          <img
            className={classNames(
              user.src ? styles.image : styles.placeholder,
              {
                [styles.opacity]: [
                  TicketStatus.AVAILABLE,

                  // TicketStatus.SELECTED,
                  // TicketStatus.PURCHASED,
                  // TicketStatus.INCLUDED,
                ].includes(ticketStatus),
              }
            )}
            src={user.src || user_Placeholder}
            alt={"Ibo User"}
          />
        </div>
        {ticketStatus === TicketStatus.CART ? (
          <>
            <div className={styles.buttonIBO}>
              <img src={Ticket_Added} alt="Ticket Added" />
            </div>
            <div className={styles.closeButton} onClick={handleCancelClick}>
              <img src={Close_button} alt="Close" />
            </div>
          </>
        ) : ticketStatus === TicketStatus.CART_INCLUDED ? (
          <>
            <div className={styles.buttonIBO}>
              <img src={Ticket_Added} alt="Ticket Added" />
            </div>
            <div className={styles.textIncluded}>
              <p>Included with Purchase!</p>
            </div>
          </>
        ) : ticketStatus === TicketStatus.PURCHASED ? (
          <div
            className={styles.buttonPurchased}
            onClick={() => onClickPurchased(user)}
          >
            <img src={Purchased_Icon} alt="Purchased" />
          </div>
        ) : (
          ticketStatus === TicketStatus.AVAILABLE && (
            <div className={styles.buttonAdd} onClick={handleAddClick}>
              <img src={Add_Button} alt="Add" />
            </div>
          )
        )}
      </div>
      {/* <div className={styles.iboName}>{user.firstName}</div> */}
      <div className={styles.iboName}>{firstName}</div>

      {ticketStatus === TicketStatus.PURCHASED_INCLUDED && (
        <div className={styles.textIncluded}>
          <p>Included with in-person ticket!</p>
        </div>
      )}

      {ticketStatus === TicketStatus.UNAVAILABLE && (
        <div className={styles.iboName}>{firstName}</div>
      )}
    </div>
  );
};
