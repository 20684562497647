import SLMIcon from "assets/icons/Second_Look_Icon.svg";
import SeminarIcon from "assets/icons/Seminar_Icon.svg";
import VirtualIcon from "assets/icons/Virtual_Icon.svg";
import OrganizationalIcon from "assets/icons/Organizational_icon.svg";
import RegionalIcon from "assets/icons/Regional_Icon.svg";

export const getIconByTitle = (titleOrSubTitle) => {
  if (titleOrSubTitle === "Seminar" || titleOrSubTitle?.includes("Seminar")) {
    return SeminarIcon;
  } else if (
    titleOrSubTitle === "Second Look" ||
    titleOrSubTitle?.includes("Second Look")
  ) {
    return SLMIcon;
  } else if (
    titleOrSubTitle === "Virtual" ||
    titleOrSubTitle?.includes("Virtual")
  ) {
    return VirtualIcon;
  } else if (
    titleOrSubTitle === "Organizational" ||
    titleOrSubTitle?.includes("Organizational")
  ) {
    return OrganizationalIcon;
  } else if (
    titleOrSubTitle === "Regional Rally™" ||
    titleOrSubTitle?.includes("Regional") ||
    titleOrSubTitle?.includes("Rally")
  ) {
    return RegionalIcon;
  }
  return SLMIcon;
};
