import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useQuery, useMutation, useQueryClient } from "react-query";
import env from "../../environment";

interface CardData {
  cardHolder: string;
  expireMonth: string;
  expireYear: string;
  nickname: string;
  zip: string;
  membershipCreditCardId: string;
  isPrimary: boolean;
}

export function useCardUpdate() {
  const queryClient = useQueryClient();

  return useMutation(
    async (cardData: CardData) => {
      const res = await makeAuthedRequest(
        "card/update-card",
        {
          method: "PUT",
          body: JSON.stringify(cardData),
        },
        env.apiBase
      );

      const text = await res.text();

      if (!res.ok) {
        try {
          const parsedBody = text ? JSON.parse(text) : {};
          throw new Error(parsedBody.message || "Unknown error");
        } catch (error) {
          throw new Error("Invalid JSON response");
        }
      }

      if (text) {
        try {
          return JSON.parse(text);
        } catch (error) {
          throw new Error("Invalid JSON response");
        }
      }
      return {};
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cards");
      },
      onError: (err) => {
        console.error("Error details:", err);
      },
    }
  );
}
