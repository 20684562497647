import React, { useState, useEffect } from "react";
import WhatNewCard from "components/WhatNewCard";
import styles from "./WhatsNewHomeSite.module.css";
import HomeSite_Bullet_icon from "assets/icons/HomeSite_Bullet_icon.svg";
import PlayIcon from "components/PlayIcon";
import Button from "components/Button";
import RadioButtonLocationItem from "components/RadioButtonLocationItem";
import arrow from "assets/demo/arrow.svg";
import { useHistory } from "react-router-dom";
import { PATHS } from "utils";
import { useAddresses } from "./useAddresses";
import Loader from "components/Loader";
import { useHomeSite } from "pages/HomeSite/useHomeSite";
import { useHomesitesLocation } from "./useHomesitesLocation";
import { useAddHomesite } from "./useHomesiteAdd";
import { getDistanceFromLatLon } from "utils/getDistance";
import InfoModal from "components/InfoModal";

interface IProps {
  lat?: number;
  lon?: number;
  primary?: number;
}

export enum EStep {
  FIRST = 1,
  SECOND,
  THIRD,
}

const dummyLocation = {
  line1: "STREET ADDRESS",
  line2: "",
  city: "CITY",
  state: "STATE",
  postalCode: "ZIP CODE",
  country: "COUNTRY",
};

export const WhatsNewHomeSite: React.FC<IProps> = ({ lat, lon }) => {
  const history = useHistory();

  const { data: addressesData, isLoading: addressDataLoading } = useAddresses();
  const { data: homesite } = useHomeSite();
  const [selectedAddress, setSelectedAddress] = useState(null);

  const { data: homesitesData, isLoading: homesitesDataLoading } =
    useHomesitesLocation(
      selectedAddress?.latitude,
      selectedAddress?.longitude,
      selectedAddress?.primary
    );

  const addHomesite = useAddHomesite();

  const [addresses, setAddresses] = useState([]);
  const [users, setUsers] = useState([]);
  const [sortedHomesites, setSortedHomesites] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [showAllLocations, setShowAllLocations] = useState(false);

  useEffect(() => {
    if (addressesData) {
      setAddresses(addressesData.addresses);
      setUsers(addressesData.users);

      // Set the primary address as the selected address
      const primaryAddress = addressesData.addresses.find(
        (address) => address.primary === 1
      );

      if (primaryAddress) {
        setSelectedAddress(primaryAddress);
      } else if (lat && lon) {
        // If not primary address, use provided lat and lon
        setSelectedAddress({
          ...primaryAddress,
          latitude: lat,
          longitude: lon,
        });
      }
    }
  }, [addressesData, lat, lon]);

  // Filter location based on distance to the selected address
  useEffect(() => {
    if (selectedAddress && Array.isArray(homesitesData)) {
      // Calculate distances and sort homesites based on proximity to the selected location
      const sorted = homesitesData
        .map((homesite) => {
          const distance = getDistanceFromLatLon(
            selectedAddress.latitude,
            selectedAddress.longitude,
            homesite.lat,
            homesite.long
          );
          return { ...homesite, distance };
        })

        //Filter location within 500 miles
        .filter((homesite) => homesite.distance <= 500)
        //Sort by distance from nearest to farthest
        .sort((a, b) => a.distance - b.distance);
      // Return the top 4 closest homesites
      // .slice(0, 4);

      setSortedHomesites(sorted);
    }
  }, [selectedAddress, homesitesData]);

  const [step, setStep] = useState(EStep.FIRST);
  const widthPercent = Math.ceil(100 / EStep.THIRD) * step;

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
    if (step === EStep.SECOND) {
      setDefaultSelectedStep2(false);
      // refetchHomesites();
    } else if (step === EStep.THIRD) {
      setDefaultSelectedStep3(false);
    }
  };

  const [defaultSelectedStep2, setDefaultSelectedStep2] = useState(true);
  const [defaultSelectedStep3, setDefaultSelectedStep3] = useState(true);

  //Handles the video click
  const handlePlayVideoClick = () => {
    setShowVideo(true);
  };

  const handleDefaultSelectionStep2 = () => {
    setSelectedAddress("");
    setSelectedLocation("");
    setDefaultSelectedStep2(true);
  };

  const handleDefaultSelectionStep3 = () => {
    setDefaultSelectedStep3(true);
  };

  //Handles the skip button
  const onRedirectToPage = () => {
    if (!homesite && homesite?.length === 0) {
      history.push(PATHS.HOMESITE_NO_SETUP);
    } else {
      history.push(PATHS.HOMESITE);
    }
  };

  //Adding homesite
  const handleAddHomesite = async () => {
    const newHomesite = { groupId: selectedLocation.groupId };

    try {
      await addHomesite.mutateAsync(newHomesite);
      history.push(PATHS.HOMESITE);
    } catch (error) {
      console.error("Failed to add homesite:", error);
    }
  };

  //What's New content of the page
  const renderContentStep1 = () => {
    return (
      <div className={styles.content}>
        <div className={styles.item}>
          <img src={HomeSite_Bullet_icon} alt={"Homesite Icon"} />
          <p>Quick access to your local function ticket purchasing!</p>
        </div>
        <div className={styles.item}>
          <img src={HomeSite_Bullet_icon} alt={"Homesite Icon"} />
          <p>Reserve your spot at your HomeSite Function!</p>
        </div>
        <div className={styles.item}>
          <img src={HomeSite_Bullet_icon} alt={"Homesite Icon"} />
          <p>Purchase your tickets up to 30 days prior to the event!</p>
        </div>
      </div>
    );
  };

  //Play button
  const renderFooterBtnStep1 = () => {
    return (
      <div className={styles.footer}>
        <div
          className={styles.videoWrapper}
          style={{ display: showVideo ? "block" : "none" }}
        >
          <iframe
            title="YouTube Video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ScMzIvxBSi4"
            allowFullScreen
            className={styles.videoPlayer}
          ></iframe>
        </div>
        {!showVideo && (
          <React.Fragment>
            <PlayIcon onClick={handlePlayVideoClick} />
            <p>Watch the Tutorial!</p>
          </React.Fragment>
        )}
      </div>
    );
  };

  //What's New: Homesite page
  const renderWhatsNewStep1 = () => {
    return (
      <div className={styles.bodyContainer}>
        {/* <div className={styles.body3}> */}
        <WhatNewCard header="What's New:" subHeader="HomeSite">
          <div className={styles.body3}>
            <div className={styles.textContent}>{renderContentStep1()}</div>

            <div>{renderFooterBtnStep1()}</div>
          </div>
        </WhatNewCard>
        {/* </div> */}
      </div>
    );
  };

  const formatTitleCase = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  //Homesite Choose address page
  const renderChooseAddressStep2 = () => {
    if (users.length === 0) return null;

    const userName = users[0].Name;

    return (
      <div className={styles.bodyContainer}>
        {addressDataLoading ? (
          <div className={styles.loader}>
            <Loader color={"#415364"} size={85} />
          </div>
        ) : (
          <WhatNewCard
            header="Choose"
            subHeader="Address"
            description="Choose the address that is closest to the Local Function site you attend the most."
          >
            <div className={styles.body3}>
              {addresses.map((address, index) => (
                <div key={index}>
                  <RadioButtonLocationItem
                    name={formatTitleCase(userName)}
                    address1={formatTitleCase(address.line1)}
                    address2={`${formatTitleCase(address.city)}, ${
                      address.state
                    } ${address.postalCode}`}
                    isSelected={
                      // (step === EStep.SECOND && defaultSelectedStep2) ||
                      !!selectedAddress &&
                      selectedAddress.iboAddressId === address.iboAddressId
                    }
                    onChange={() => {
                      if (step === EStep.SECOND) {
                        handleDefaultSelectionStep2();
                      }
                      handleAddressChange(address);
                    }}
                  />
                </div>
              ))}
            </div>
          </WhatNewCard>
        )}
      </div>
    );
  };

  //Homesite location page
  const renderLocationStep3 = () => {
    if (!selectedAddress || !sortedHomesites.length) return null;

    // const formattedAddress = (origin) => {
    //   const parts = origin.split("|");
    //   return `${formatTitleCase(parts[0].toLowerCase())}, ${parts[1]} ${
    //     parts[2]
    //   }`;
    // };

    const handleLocationChange = (location) => {
      setSelectedLocation(location);
    };

    const handleShowMore = () => {
      setShowAllLocations(true);
    };

    return (
      <div className={styles.bodyContainer}>
        {homesitesDataLoading ? (
          <div className={styles.loader}>
            <Loader color={"#415364"} size={85} />
          </div>
        ) : (
          <WhatNewCard
            header="HomeSite"
            subHeader="Location"
            description="Choose the location you typically attend for your Local Functions. You can still attend other locations."
          >
            <div className={styles.body3}>
              {(showAllLocations
                ? sortedHomesites
                : sortedHomesites.slice(0, 4)
              ).map((location, index) => (
                <div key={index}>
                  <RadioButtonLocationItem
                    name={formatTitleCase(location.region)}
                    // address1={formatTitleCase(location.origin.split("||")[0])}
                    // address2={formattedAddress(location.origin.split("||")[1])}
                    address1={formatTitleCase(dummyLocation.line1)}
                    address2={`${formatTitleCase(dummyLocation.city)}, ${
                      dummyLocation.state
                    } ${dummyLocation.postalCode}`}
                    isSelected={
                      // (step === EStep.THIRD && defaultSelectedStep3) ||
                      !!selectedLocation &&
                      selectedLocation.groupId === location.groupId
                    }
                    onChange={() => {
                      if (step === EStep.THIRD) {
                        handleDefaultSelectionStep3();
                      }
                      handleLocationChange(location);
                    }}
                  />
                </div>
              ))}

              {!showAllLocations && sortedHomesites.length > 4 && (
                <div onClick={handleShowMore} className={styles.moreButton}>
                  More...
                </div>
              )}
            </div>
          </WhatNewCard>
        )}
      </div>
    );
  };

  const renderBody = () => {
    switch (step) {
      case EStep.FIRST:
        return renderWhatsNewStep1();
      case EStep.SECOND:
        return renderChooseAddressStep2();
      case EStep.THIRD:
        return renderLocationStep3();
      default:
        return null;
    }
  };

  //What's New content footer
  const renderFooterStep1 = () => {
    return (
      <div className={styles.navigateFooter}>
        <div className={styles.progress}>
          <div
            className={styles.progressBar}
            style={{ width: widthPercent + "%" }}
          ></div>
        </div>
        <div className={styles.navigateBtn}>
          <div className={styles.left} />
          <Button
            title="Let's get started!"
            onClick={() => setStep((s) => s + 1)}
            className={styles.action}
          />
          <div className={styles.right} />
        </div>
      </div>
    );
  };

  //Choose address footer
  const renderFooterStep2 = () => {
    return (
      <div className={styles.navigateFooter}>
        <div className={styles.progress}>
          <div
            className={styles.progressBar}
            style={{ width: widthPercent + "%" }}
          ></div>
        </div>
        <div className={styles.navigateBtn}>
          <div className={styles.left}>
            <Button
              img={arrow}
              onClick={() => setStep((s) => s - 1)}
              className={styles.arrow}
            />
          </div>
          <Button
            title="Next"
            onClick={() => setStep((s) => s + 1)}
            className={styles.action}
            disabled={!selectedAddress}
          />
          <div className={styles.right} />
        </div>
      </div>
    );
  };

  //Homesite location footer
  const renderFooterStep3 = () => {
    return (
      <>
        <div className={styles.navigateFooter}>
          <div className={styles.progress}>
            <div
              className={styles.progressBar}
              style={{ width: widthPercent + "%" }}
            ></div>
          </div>
          <div className={styles.navigateBtn}>
            <div className={styles.left}>
              <Button
                img={arrow}
                onClick={() => setStep((s) => s - 1)}
                className={styles.arrow}
              />
            </div>
            <Button
              title="Visit HomeSite!"
              className={styles.action}
              disabled={!selectedLocation}
              onClick={handleAddHomesite}
            />
            <div className={styles.right} />
          </div>
        </div>
      </>
    );
  };

  const renderNavigatorFooter = () => {
    switch (step) {
      case EStep.FIRST:
        return renderFooterStep1();
      case EStep.SECOND:
        return renderFooterStep2();
      case EStep.THIRD:
        return renderFooterStep3();
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      {addressDataLoading || homesitesDataLoading ? (
        <div className={styles.loader}>
          <Loader color={"#415364"} size={85} />
        </div>
      ) : (
        <div className={`${styles.wrapper} whatsNewPage`}>
          <div className={styles.skipContainer}>
            <Button
              title="Skip"
              onClick={onRedirectToPage}
              className={styles.skip}
            />
          </div>
          <div className={styles.card}>{renderBody()}</div>
          {renderNavigatorFooter()}
        </div>
      )}
    </div>
  );
};
