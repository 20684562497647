import React from "react";
import styles from "./MajorFunctionsEvent.module.css";
import Note_Icon from "assets/icons/Note_Icon.svg";
import { MajorFunctionsIBO } from "../MajorFunctionsIBO/MajorFunctionsIBO";
import { IItems, IFunctionIBO, IFunction } from "models";
import { formatDateNoYear } from "utils/formatDate";
import { ITickets, IEventItems } from "stores/majorInterface";
import { UserIBO } from "../../../stores/interfaces.types";
import { getIconByTitle } from "../getIconByTitle";

interface IProps {
  item: IEventItems;
  users: UserIBO[];
  onClickAdd: Function;
  onClickPurchased: Function;
  onClickCancel: Function;
}

export const MajorFunctionsEvent: React.FC<IProps> = (props) => {
  let { item, users, onClickAdd, onClickPurchased, onClickCancel } = props;

  //Dates and time
  const startDate = item?.startsOn ? new Date(item?.startsOn) : null;
  const endDate = item?.endsOn ? new Date(item?.endsOn) : null;

  const eventDate =
    startDate && endDate ? formatDateNoYear(startDate, endDate) : null;

  return (
    <div className={styles.container}>
      <div className={styles.eventContainer}>
        <div className={styles.event}>
          <div>
            {/* <img src={event.icon} alt={""} className={styles.icon} /> */}
            <img
              src={getIconByTitle(item.title)}
              alt={"Event Icon"}
              className={styles.icon}
            />
          </div>
          <div className={styles.location}>{item.title}</div>
          <div className={styles.dateRange}>{eventDate}</div>
          <div className={styles.price}>
            {/* {event.price != null ? (
              <>
                {event.currencyUnit}
                {event.price}
              </>
            ) : null} */}
          </div>
        </div>

        {/* CART */}
        <div className={styles.tickets}>
          {users?.map((user, index) => {
            const eventTicketIds = item.ticket?.map((t) => t.ticketId) || [];

            const userTicket = user.tickets?.find((t) =>
              eventTicketIds.includes(t.eventTicketId)
            );

            return (
              <div key={index}>
                <MajorFunctionsIBO
                  user={user}
                  ticket={userTicket}
                  onClickAdd={onClickAdd}
                  onClickPurchased={onClickPurchased}
                  onClickCancel={onClickCancel}

                  //     onClickAdd={() => onClickAdd(user)}
                  // onClickPurchased={() => onClickPurchased(user)}
                  // onClickCancel={() => onClickCancel(user)}
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* {item.serviceCharges &&
        item.serviceCharges.map((item, index) => (
          <div className={styles.serviceCharge} key={index}>
            <div className={styles.serviceChargeIcon}>
              <img src={Note_Icon} alt={""} />
            </div>
            <div className={styles.serviceChargeDescription}>
              {item.description}
            </div>
          </div>
        ))} */}
    </div>
  );
};
