import SLMIcon from "assets/icons/Second_Look_Icon.svg";
import SeminarIcon from "assets/icons/Seminar_Icon.svg";
import VirtualIcon from "assets/icons/Virtual_Icon.svg";
import OrganizationalIcon from "assets/icons/Organizational_icon.svg";
import RegionalIcon from "assets/icons/Regional_Icon.svg";
import FEIcon from "assets/icons/Free_Enterprise_icon_Header.svg";
import SummitLogo from "assets/icons/Summit_Logo.svg";
import DreamNightLogo from "assets/icons/DreamNight_Logo.svg";

export const getIconByTitle = (titleOrSubTitle) => {
  if (titleOrSubTitle === "Summit" || titleOrSubTitle?.includes("Summit")) {
    return SummitLogo;
  } else if (
    titleOrSubTitle === "DreamNight" ||
    titleOrSubTitle?.includes("DreamNight")
  ) {
    return DreamNightLogo;
  } else if (
    titleOrSubTitle === "Virtual" ||
    titleOrSubTitle?.includes("Virtual")
  ) {
    return VirtualIcon;
  }
  //Temporary
  return FEIcon;
};
