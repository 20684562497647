import { FC } from 'react'
import styles from './SearchIcon.module.css';

interface IISearchIconProps {
  width?: number,
  height?: number,
  fill?: string,
  onClick?: () => void
}

export const SearchIcon: FC<IISearchIconProps> = props => {
  const width = props.width || 19.627,
    height = props.width || 20,
    fill = props.fill || '#000';

  return <svg xmlns="http://www.w3.org/2000/svg" className={styles.svg} onClick={() => props.onClick && props.onClick()} width={width} height={height} viewBox="0 0 19.627 20"><g transform="translate(-129.388 -102.017)"><g data-name="Group 14161" transform="translate(129.388 102.017)"><path fill={fill} d="M7.7 0A7.7 7.7 0 110 7.7 7.709 7.709 0 017.7 0zm0 13.585A5.885 5.885 0 101.816 7.7 5.891 5.891 0 007.7 13.585z"></path></g><rect width="2.007" height="10.033" fill={fill} data-name="Rectangle 3237" rx="1.003" transform="rotate(-45 208.974 -112.139)"></rect></g></svg>
}
