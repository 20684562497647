import PastMeetingItem from 'components/PastMeetingItem'
import React from 'react'
import styles from './HomeSiteNoMeeting.module.css'
import HomeSite_Icon from 'assets/icons/HomeSite_Icon.svg'
interface IProps {

}

export const HomeSiteNoMeeting: React.FC<IProps> = props => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <img src={HomeSite_Icon} alt="" />
      </div>
      <div className={styles.text}>
        <i>No Regional Rally Meetings™ or Second Look Meetings™ scheduled.</i>
      </div>
      <div className={styles.list}>
        <div className={styles.item}><PastMeetingItem icon='' months={['2023-11-10T12:01:53.588Z', '2023-12-10T12:01:53.588Z']} title='' /></div>
        <div className={styles.item}><PastMeetingItem icon='' months={['2023-05-10T12:01:53.588Z', '2023-06-10T12:01:53.588Z', '2023-07-10T12:01:53.588Z', '2023-08-10T12:01:53.588Z']} title='Regional Rally Meetings' /></div>
        <div className={styles.item}><PastMeetingItem icon='' months={['2023-01-10T12:01:53.588Z', '2023-02-10T12:01:53.588Z', '2023-03-10T12:01:53.588Z']} title='Organizational' /></div>
      </div>
      <div className={styles.note}>
        <p>Check the Local Functions section for Organizational Open Meetings near you.</p>
      </div>

    </div>
  )
}
