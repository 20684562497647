import { FC } from 'react'
import styles from './PastMeetingItem.module.css'
import { IPastMeetingItem } from './PastMeetingItem.types'
import demoImg from 'assets/icons/Second_Look_Icon.svg'
import { format} from 'date-fns'
interface IProps extends IPastMeetingItem {
}

export const PastMeetingItem: FC<IProps> = props => {
  const { icon, months, title } = props

  const mappingMonthsToText = () => { 
    if(!months) return []
    return months?.map(_ => format(new Date(_),'LLLL'))
   }
  const renderMonthsText = () => { 
      const monthNames = mappingMonthsToText()
      const lastMonthName = monthNames.pop()
      const monthNotTheLast = monthNames.join(', ')
    return `${monthNotTheLast} and ${lastMonthName}`
   }
  return (
    <div
      className={styles.section}
    >
      <div className={styles.left}>
        <img src={icon || demoImg} />
      </div>
      <div className={styles.right}>
        <p className={styles.title}>{title || 'Second Look Meetings'}</p>
        <p className={styles.month}>happen in {renderMonthsText()}</p>
      </div>
    </div>
  )
}