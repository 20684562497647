import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./EventListItem.module.css";
import { Selector } from "@wwg/ui";
import { Ticket } from "../Ticket/Ticket";
import { useState } from "react";
import { format, parseISO } from "date-fns";

/**
 * Interactive component that will be displayed once for each event with tickets available to purchase.
 * It displays event info on the left side and provides a dropdown to select a number of tickets on the right side.
 * When tickets are selected the user is able to configure ticket details for each using Ticket components.
 */

export function EventListItem({
  city,
  state,
  startsOn,
  endsOn,
  ticketsPurchased = 0,
  iboTicketsLeft = 0,
  onTicketsChanged,
  tickets = [],
  children,
}) {
  console.log({ ticketsPurchased, tickets });

  // Render SelectTickets
  const SelectTickets = () => {
    // If tickets are available
    if (iboTicketsLeft > 0 && tickets.length < iboTicketsLeft) {
      let optionList = [];

      // Increment number of ticketsAvailable and create 0...X number select options
      for (let i = tickets.length; i <= iboTicketsLeft; i++) {
        console.log({ i, iboTicketsLeft });
        optionList.push(<option key={i} value={i} label={i} />);
      }
      // Render the Selector with generated options
      return (
        <Selector
          className={styles.selectTickets}
          onChange={onTicketsChanged}
          aria-label="event select tickets"
        >
          {optionList}
        </Selector>
      );
    }
    // Else, no tickets are avialable
    else {
      return (
        <span className={styles.noLongerAvailable}>No Longer Available</span>
      );
    }
  };

  console.log("TEST");

  return (
    <div className={styles.containerEventListItem}>
      <div className={styles.eventListItem}>
        <div className={styles.containerEventName}>
          <span className={styles.eventName}>
            {city} {state}
          </span>
        </div>
        <div className={styles.containerStartsOn}>
          <span className={styles.startsOn}>
            {format(parseISO(startsOn), "MM.dd.yyy")}
          </span>
        </div>
        <div className={styles.containerDateSeparator}>
          <span className={styles.dateSeparator}>&nbsp;-&nbsp;</span>
        </div>
        <div className={styles.containerEndsOn}>
          <span className={styles.endsOn}>
            {format(parseISO(endsOn), "MM.dd.yyy")}
          </span>
        </div>
        <div className={styles.containerSelectTickets}>
          <SelectTickets />
        </div>
      </div>
      <div className={styles.containerTicketsList}>{children}</div>
    </div>
  );
}

EventListItem.propTypes = {
  state: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  startsOn: PropTypes.string.isRequired,
  endsOn: PropTypes.string.isRequired,
  ticketsPurchased: PropTypes.number.isRequired,
  iboTicketsLeft: PropTypes.number.isRequired,
  onTicketsChanged: PropTypes.func.isRequired,
};
