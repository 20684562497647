import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./BlockHeader.module.css";

export function BlockHeader({
  title,
  textColor = "#FFFFFF",
  fillColor = "#91B6BA",
}) {
  return (
    <div
      style={{ backgroundColor: fillColor }}
      className={classNames(styles.blockHeader)}
    >
      <p style={{ color: textColor }} className={classNames(styles.blockTitle)}>
        {title}
      </p>
    </div>
  );
}

BlockHeader.propTypes = {
  title: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  fillColor: PropTypes.string,
};

export default BlockHeader;
