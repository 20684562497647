import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useQuery } from "react-query";
import env from "../../environment";

export function useCards() {
  const { data, isLoading, refetch } = useQuery(
    "cards",
    () =>
      makeAuthedRequest(
        "card",
        {
          method: "GET",
        },
        env.apiBase
      ).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error((await res.json()).message);
        }
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return {
    data: data && Array.isArray(data.cards) ? data.cards : [],
    isLoading,
    refetch,
  };
}
