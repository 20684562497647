import React from 'react'
interface IProps {

}

export const PhucComponents: React.FC<IProps> = props => {
  return (
    <div className={''}>
      <div className={''}>
      PhucComponents
      </div>
    </div>
  )
}
