import React from "react";
import { FC } from "react";

import styles from "./LocalFunctionEventTicketItem.module.css";
import { UserIBO, EventSets } from "../../../stores/interfaces.types";
import LocalFunctionEventIcon from "components/LocalFunctionComponents/LocalFunctionEventIcon";
import LocalFunctionTicketPurchaseItem from "components/LocalFunctionComponents/LocalFunctionTicketPurchaseItem";

interface IProps {
  eventSet: EventSets;
  users: UserIBO[];

  onClickAdd: Function;
  onClickPurchased: Function;
  onClickCancel: Function;
}

export const LocalFunctionEventTicketItem: FC<IProps> = ({
  eventSet,
  users,
  onClickAdd,
  onClickPurchased,
  onClickCancel,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.left}>
        <LocalFunctionEventIcon events={eventSet} />
      </div>
      <div className={styles.right}>
        {users?.map((user) => {
          return (
            <LocalFunctionTicketPurchaseItem
              key={user.userId}
              user={user}
              eventSet={eventSet}
              onClickAdd={() => onClickAdd(user)}
              onClickPurchased={() => onClickPurchased(user)}
              onClickCancel={() => onClickCancel(user)}
            />
          );
        })}
      </div>
    </div>
  );
};
