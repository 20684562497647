import PropTypes from "prop-types";
import styles from "./SpecialNeedsModal.module.css";
import { Button } from "@wwg/ui";
import { useState } from "react";
import options from "./options";
/**
 * Interactive component that allows the user to configure ticket options
 * notifying WWG of any accommodations they may need when attending an event.
 */

export const SpecialNeedsModal = ({ ticket, onSave, onCancel }) => {
  // Accessible state
  const [accessibleState, setAccessible] = useState(() => {
    if (ticket.adaValue & options.accessible.seating.bitwise) {
      return options.accessible.seating.id;
    } else if (ticket.adaValue & options.accessible.companion.bitwise) {
      return options.accessible.companion.id;
    } else {
      return null;
    }
  });

  // Hearing state
  const [hearingState, setHearing] = useState(() => {
    if (ticket.adaValue & options.hearing.device.bitwise) {
      return options.hearing.device.id;
    } else if (ticket.adaValue & options.hearing.interpreter.bitwise) {
      return options.hearing.interpreter.id;
    } else if (ticket.adaValue & options.hearing.companion.bitwise) {
      return options.hearing.companion.id;
    } else {
      return null;
    }
  });

  // Handlers
  function handleAccessibleCheckboxClick(id) {
    if (accessibleState != id) {
      setAccessible(id);
    } else {
      setAccessible(null);
    }
    // Update accessible checkbox states
    const accessible = options.accessible;
    accessible.seating.ref.current.checked = id == accessible.seating.id;
    accessible.companion.ref.current.checked = id == accessible.companion.id;
  }

  function handleHearingCheckboxClick(id) {
    if (hearingState != id) {
      setHearing(id);
    } else {
      setHearing(null);
    }

    // Update hearing checkbox states
    const hearing = options.hearing;
    hearing.device.ref.current.checked = id == hearing.device.id;
    hearing.interpreter.ref.current.checked = id == hearing.interpreter.id;
    hearing.companion.ref.current.checked = id == hearing.companion.id;
  }

  // Get on save object
  function getOnSaveOptions() {
    let adaValue = 0x0;
    if (accessibleState === options.accessible.seating.id) {
      adaValue += options.accessible.seating.bitwise;
    } else if (accessibleState == options.accessible.companion.id) {
      adaValue += options.accessible.companion.bitwise;
    }

    if (hearingState === options.hearing.device.id) {
      adaValue += options.hearing.device.bitwise;
    } else if (hearingState === options.hearing.interpreter.id) {
      adaValue += options.hearing.interpreter.bitwise;
    } else if (hearingState === options.hearing.companion.id) {
      adaValue += options.hearing.companion.bitwise;
    }
    return adaValue;
  }

  return (
    <div className={styles.specialNeedsModal}>
      <span className={styles.specialNeedsTitle}>Special Needs</span>
      <p className={styles.specialNeedsDesc}>
        Special needs requests are associated to an individual ticket. If you
        have multple tickets requiring a special request, please make sure you
        mark each ticket with the appropriate response in order to secure your
        place in the accessible seating sections. We cannot guarantee
        availability for onsite requests.
      </p>
      <form className={styles.specialNeedsOptions}>
        {/* Accessible seating */}
        <input
          type="checkbox"
          ref={options.accessible.seating.ref}
          name={options.accessible.seating.id}
          id={options.accessible.seating.id}
          data-testid={options.accessible.seating.id}
          checked={accessibleState == options.accessible.seating.id}
          onChange={({ target }) => {
            handleAccessibleCheckboxClick(target.id);
          }}
        />
        <label htmlFor={options.accessible.seating.id}>
          I require accessible seating
        </label>
        <span className={styles.specialNeedsOr}>or</span>
        {/* Accessible companion */}
        <input
          type="checkbox"
          ref={options.accessible.companion.ref}
          name={options.accessible.companion.id}
          id={options.accessible.companion.id}
          data-testid={options.accessible.companion.id}
          checked={accessibleState == options.accessible.companion.id}
          onChange={({ target }) => {
            handleAccessibleCheckboxClick(target.id);
          }}
        />
        <label htmlFor={options.accessible.companion.id}>
          I require an accessible seating companion ticket
        </label>
        <hr />
        {/* Hearing device */}
        <input
          type="checkbox"
          ref={options.hearing.device.ref}
          name={options.hearing.device.id}
          id={options.hearing.device.id}
          data-testid={options.hearing.device.id}
          checked={hearingState == options.hearing.device.id}
          onChange={({ target }) => {
            handleHearingCheckboxClick(target.id);
          }}
        />
        <label htmlFor={options.hearing.device.id}>
          I require use of a hearing amplification device
        </label>
        <span className={styles.specialNeedsOr}>or</span>
        {/* Hearing interpreter */}
        <input
          type="checkbox"
          ref={options.hearing.interpreter.ref}
          name={options.hearing.interpreter.id}
          id={options.hearing.interpreter.id}
          data-testid={options.hearing.interpreter.id}
          checked={hearingState == options.hearing.interpreter.id}
          onChange={({ target }) => {
            handleHearingCheckboxClick(target.id);
          }}
        />
        <label htmlFor={options.hearing.interpreter.id}>
          I require use of an American Sign Language Interpreter (at least 4
          weeks advance notice is required)
        </label>
        <span className={styles.specialNeedsOr}>or</span>
        {/* Hearing companion */}
        <input
          type="checkbox"
          ref={options.hearing.companion.ref}
          name={options.hearing.companion.id}
          id={options.hearing.companion.id}
          data-testid={options.hearing.companion.id}
          checked={hearingState == options.hearing.companion.id}
          onChange={({ target }) => {
            handleHearingCheckboxClick(target.id);
          }}
        />
        <label htmlFor={options.hearing.companion.id}>
          I require a hearing accessible seating companion ticket
        </label>
      </form>
      <div className={styles.containerSpecialNeedsButtons}>
        <Button
          label="Save"
          className={styles.onSaveButton}
          onClick={() => onSave({ ...ticket, adaValue: getOnSaveOptions() })}
        />
        <Button
          label="Cancel"
          className={styles.onCancelButton}
          buttonStyle="hollow"
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

SpecialNeedsModal.propTypes = {
  ticket: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
