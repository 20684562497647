import React, { FC } from "react";
import LocalFunctionEventTicketItem from "../LocalFunctionEventTicketItem";
import styles from "./LocalFunctionEventsList.module.css";
import {
  EventInfo,
  EventItem,
  Tickets,
  UserIBO,
  EventSessions,
  EventSets,
} from "../../../stores/interfaces.types";

interface IProps {
  // eventTickets: Tickets[];
  // eventDetails: EventItem[]
  eventSets: EventSets[];
  users: UserIBO[];
  // eventTickets: EventInfo[];
  onClickAdd: Function;
  onClickCancel: Function;
  onClickPurchased: Function;
}

export const LocalFunctionEventsList: FC<IProps> = ({
  eventSets,
  users,
  onClickAdd,
  onClickCancel,
  onClickPurchased,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.title}>
        <p>Event</p>
        <p>Tickets</p>
      </div>
      {eventSets.map((eventSet, index) => {
        return (
          <div key={index} className={styles.eventItem}>
            <LocalFunctionEventTicketItem
              eventSet={eventSet}
              users={users}
              onClickAdd={(user) => onClickAdd(eventSet, user)}
              onClickPurchased={(user) => onClickPurchased(eventSet, user)}
              onClickCancel={(user) => onClickCancel(eventSet, user)}
            />
          </div>
        );
      })}
    </div>
  );
};
