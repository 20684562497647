import { useQuery, useQueryClient, useMutation } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import env from "../../environment"

export const purchaseEndPoints = {
  post: {
    purchase: "/api/purchase/",
  },
};

export function usePurchaseData(tickets = []) {
  const queryClient = useQueryClient();
  const purchaseMutation = useMutation(
    ({
      purchaseTypeId,
      year,
      compTickets,
      countDownSeconds,
      events,
      IBO,
      orderId,
      owed,
      paid,
      payment,
      token,
      iboCount,
    }) =>
      makeAuthedRequest(
        `${purchaseEndPoints.post.purchase}?purchaseTypeId=${purchaseTypeId}?year=${year}`,
        {
          method: "post",
          body: JSON.stringify({
            compTickets,
            countDownSeconds,
            events,
            IBO,
            orderId,
            owed,
            paid,
            payment,
            token,
            iboCount,
          }),
        }, env.apiBaseOld
      ).then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          if (json.errors && json.errors.length > 0) {
            throw new Error(json.errors[0].message);
          }
        }
        return res.json();
      }),
    {

    }
  );

  return {
    purchaseMutation,
  };
}
