export default `
  <div>
    <div class="panel panel-default">
      <div class="panel-body">
        <h5 style="font-weight: 400;font-size: 15px;color: #243746;">Prices</h5>
        <ul style="padding-left: 40px;">
          <li>$150.00 U.S. Funds</li>
        </ul>
        <h5>Ticket Ordering</h5>
        <ul style="padding-left: 40px;">
          <li>
            All IBOs with a Dreambuilders Membership™ may order tickets online.
          </li>
          New first-time Premier Members receive complimentary admission to the{" "}
          <em>first WWG™ weekend major event they attend.</em>
          <ul style="padding-left: 40px;">
            <li>
              These new IBOs receive one free admission per registered IBO
              (single = 1 admission, couple = 2 admissions).
            </li>
            <li>
              IBOs must be current Premier Members at the time of the event to
              receive this complimentary admission.
            </li>
          </ul>
          <li>
            Live Support will be open to assist anyone with ticket issues.
          </li>
          <li>
            By purchasing event ticket(s), IBOs will gain access to the online
            event.
          </li>
          <li>
            Attendees must be 16 years of age or older to attend meetings;
            tickets are required.
          </li>
          <li>
            Children may only attend the Sunday morning non-denominational
            service.
          </li>
        </ul>
        <h5 style="font-weight: 400;font-size: 15px;color: #243746;">
          Ticket Refunds
        </h5>
        <ul style="padding-left: 40px;">
          <li>
            Cancelation requests may be processed online through the purchaser’s
            wwdb.com receipt until the online refund cutoff date. After the
            deadline, requests may be submitted via email to refunds@wwdb.com.{" "}
          </li>
          <li>
            Refunds are limited to tickets purchased for personal use only
            (maximum of two (2) tickets per business per location).
          </li>
          <li>
            Refund requests will only be accepted from the original ticket
            purchaser on record.
          </li>
          <li>
            Refunds will be issued to the credit card used to purchase the
            tickets.
          </li>
          <li>
            A $10 per ticket cancelation fee will be deducted from the refund
            amount.
          </li>
          <li>
            Please see the <em>Cancelation Request</em> PDF for detailed
            instructions after the online refund cutoff date.
          </li>
        </ul>
        Purchase of this ticket is optional.
        <br />
        <br />
        Recording or re-broadcasting of any portion of this event without the
        permission of World Wide Group™ is strictly prohibited.
        <br />
        <br />
        World Wide Group, LLC may capture your likeness in a photograph, video,
        or other digital media format. By agreeing to these Terms &amp;
        Conditions you give your permission for World Wide Group, LLC to use
        your likeness in any and all of its publications, including web-based
        publications, without payment or other consideration.
        <br />
        <br />
        This event is produced and offered by World Wide Group, LLC. It has not
        been reviewed or endorsed by any other corporate entity. While the
        techniques and approaches suggested at this event may have worked for
        others, there is no guarantee that they will work for you. We do hope,
        however, that these ideas will assist you in developing a strong and
        profitable business
      </div>
    </div>
  </div>
`