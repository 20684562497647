import React from 'react'
import styles from './DemoComponents.module.css'
import { TanComponents } from './TanComponents/TanComponents'
import { KhoaComponents } from './KhoaComponents/KhoaComponents'
import { PhucComponents } from './PhucComponents/PhucComponents'
import { TrungComponents } from './TrungComponents/TrungComponents'
interface IProps {

}

export const DemoComponents: React.FC<IProps> = props => {
  return (
    <div className={styles.page}>
      <div className={styles.section}>
        TanComponents
        <TanComponents />
      </div>
      <div className={styles.section}>
        KhoaComponents
        <KhoaComponents />
      </div>
      <div className={styles.section}>
        PhucComponents
        <PhucComponents />
      </div>
      <div className={styles.section}>
        TrungComponents
        <TrungComponents />
      </div>
    </div>
  )
}
