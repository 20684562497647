import { FC, useEffect } from "react";
import { IRadioButtonLocationItem } from "./RadioButtonLocationItem.types";
import classnames from "classnames";
import styles from "./RadioButtonLocationItem.module.css";
import "./override.css";

interface IProps extends IRadioButtonLocationItem {}

export const RadioButtonLocationItem: FC<IProps> = ({
  address1,
  address2,
  isSelected,
  name,
  onChange,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <label className="location-radio_label">
      <input
        type="radio"
        checked={isSelected}
        onChange={handleInputChange}
        name={name}
      />
      <div className="location-radio_check"></div>
      <div className="location-radio_content">
        <p>
          <b>{name}</b>
        </p>
        {address1 && <p>{address1}</p>}
        {address2 && <p>{address2}</p>}
      </div>
    </label>
  );
};
