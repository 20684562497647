import React, { useState, useEffect } from "react";
import styles from "./CardListModal.module.css";
import Button from "components/Button";
import { useCards } from "./useCards";
import { useCardUpdate } from "./useCardUpdate";

import InfoModal from "components/InfoModal";
import CardList from "components/CardList/CardList";
import Loader from "components/Loader";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";

interface CardListModalProps {
  isOpen: boolean;
  onClose?: Function;
  onClick?: Function;
}

export const CardListModal = (props: CardListModalProps) => {
  const { isOpen, onClose } = props;

  const { data: cards = [], isLoading } = useCards();
  const updateCard = useCardUpdate();

  const [selectedCards, setSelectedCards] = useState([]);
  const [currentSelectedCard, setCurrentSelectedCard] = useState(null);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [selectAsPrimary, setSelectAsPrimary] = useState(null);

  useEffect(() => {
    if (cards.length > 0) {
      setSelectedCards(cards);
      const existingPrimaryCard = cards.find((card) => card.isPrimary);
      if (existingPrimaryCard) {
        setCurrentSelectedCard(existingPrimaryCard);
      }
    }
  }, [cards]);

  const handleSetPrimary = (selectedCardId) => {
    const selectedCard = selectedCards.find(
      (card) => card.membershipCreditCardId === selectedCardId
    );

    setSelectAsPrimary(selectedCard);
    setIsOpenConfirmation(true);
  };

  const handleConfirmPrimaryCard = async () => {
    if (!selectAsPrimary) return;

    try {
      const updatedCardData = {
        cardHolder: selectAsPrimary.cardHolder,
        expireMonth: selectAsPrimary.expireMonth,
        expireYear: selectAsPrimary.expireYear?.slice(2),
        nickname: selectAsPrimary.nickname,
        zip: selectAsPrimary.zip,
        membershipCreditCardId: selectAsPrimary.membershipCreditCardId,
        isPrimary: true,
      };

      console.log("API CALL:", updatedCardData);

      await updateCard.mutateAsync(updatedCardData);

      setSelectedCards((prevSelectedCards) => {
        const updatedCards = prevSelectedCards.map((card) =>
          card.membershipCreditCardId === selectAsPrimary.membershipCreditCardId
            ? { ...card, isPrimary: true }
            : { ...card, isPrimary: false }
        );
        setCurrentSelectedCard(updatedCards.find((card) => card.isPrimary));
        return updatedCards;
      });

      setIsOpenConfirmation(false);
      setSelectAsPrimary(null);
    } catch (error) {
      console.error("Failed to update primary card", error);
    }
  };

  const handleOnClose = () => {
    if (currentSelectedCard && currentSelectedCard.isPrimary) {
      onClose(currentSelectedCard);
    } else {
      const existingPrimaryCard = selectedCards.find((card) => card.isPrimary);
      onClose(existingPrimaryCard || currentSelectedCard);
    }
  };

  if (isLoading)
    return (
      <div>
        <Loader color={"#415364"} size={85} />
      </div>
    );

  return (
    <InfoModal isOpen={isOpen} onClose={handleOnClose} className={styles.modal}>
      <div className={styles.container}>
        <CardList cards={selectedCards} onSelect={handleSetPrimary} />
        <div className={styles.btnContainer}>
          <Button
            className={styles.button}
            title={"Close"}
            onClick={handleOnClose}
          />
        </div>

        <ConfirmationModal
          isOpen={isOpenConfirmation}
          onClose={() => {
            setIsOpenConfirmation(false);
          }}
          message={"Do you want to set this card as your primary card?"}
          onConfirm={handleConfirmPrimaryCard}
        />
      </div>
    </InfoModal>
  );
};
