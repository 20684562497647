import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useQuery } from "react-query";
import env from "../../environment";

export function useLocalFunctions(state = "") {
  // const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery(
    ["localFunctions", state],
    () =>
      makeAuthedRequest(
        // "local-functions",
        // `local-functions${state ? `?state=${state}` : ""}`,
        `local-functions?state=${state}`,

        {
          method: "GET",
        },
        env.apiBase
      ).then(async (res) => {
        if (res.ok) {
          // return res.json();
          const jsonResponse = await res.json();
          // console.log("DATA:", jsonResponse);
          return jsonResponse;
        } else {
          throw new Error((await res.json()).message);
        }
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  // console.log("DATA:", data);

  return {
    data,
    isLoading,
    refetch,
  };
}
