import { useEffect } from "react";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useQuery, useMutation, useQueryClient } from "react-query";
import env from "../../environment";

interface Homesite {
  groupId: number;
}

export function useAddHomesite() {
  const queryClient = useQueryClient();

  return useMutation(
    (homesiteData: Homesite) =>
      makeAuthedRequest(
        `homesite-add`,
        {
          method: "POST",
          body: JSON.stringify(homesiteData),
        },
        env.apiBase
      ).then(async (res) => {
        if (res.ok) {
          return res;
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("homesite");
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );
}
