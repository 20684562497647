import React from "react";
import styles from "./ConfirmationModal.module.css";
import Button from "components/Button";
import classNames from "classnames";

interface ConfirmationModalProps {
  message: string;
  isOpen: boolean;
  onClose?: Function;
  onConfirm?: Function;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { isOpen, onClose, message, onConfirm } = props;

  return (
    <>
      {isOpen && (
        <div className={styles.overlay}>
          <div className={styles.container}>
            <div className={styles.message}>
              <p>{message}</p>
            </div>
            <div className={styles.btnContainer}>
              <Button
                className={classNames(styles.button, styles.btnNo)}
                title="No"
                onClick={() => onClose()}
              />
              <Button
                className={styles.button}
                title="Yes"
                onClick={() => onConfirm()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
