import React from "react";
import styles from "./Component.module.css";

interface IAlertProps {
  note: string;
}

export const Alert = React.memo((props: IAlertProps) => {
  const { note } = props;

  return (
    <div className={styles.container}>
      <div className={styles.svg}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.717 26">
          <g transform="translate(-20.414 -659.522)">
            <path
              fill="#ff9a00"
              d="M27 26H2.7a2.677 2.677 0 01-2.353-1.357 2.623 2.623 0 01.017-2.662L12.516 1.329a2.717 2.717 0 014.672 0l12.151 20.653a2.621 2.621 0 01.016 2.66A2.677 2.677 0 0127 26z"
              transform="translate(20.421 659.522)"
            ></path>
            <path
              d="M2.389 1.738l.267-9.152H.826l.267 9.152zm-.648 3.909a1.107 1.107 0 001.125-1.182 1.1 1.1 0 00-1.125-1.183A1.118 1.118 0 00.616 4.465a1.118 1.118 0 001.125 1.182z"
              data-name="IBO Number: 867"
              transform="translate(33.665 675.647)"
            ></path>
          </g>
        </svg>
      </div>
      <div className={styles.noteText}>{note}</div>
    </div>
  );
});
