import { FC } from "react";
import { IButton } from "./Button.types";
import classnames from "classnames";
import styles from "./Button.module.css";
interface IProps extends IButton {}

export const Button: FC<IProps> = (props) => {
  const { className, disabled, img, onClick, title } = props;

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classnames(
        className ? className : styles.default,
        styles.content
      )}
    >
      {title ? title : <img src={img} />}
    </button>
  );
};
