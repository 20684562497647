import { FC, useEffect, useRef, useState } from 'react'
import { ILocationModal } from './LocationModal.types'
import styles from './LocationModal.module.css'
import { ReactNodeLike } from 'prop-types'
import { Modal } from '@wwg/ui'
import { IconDelete } from '@wwg/icons'
import Map_Icon from 'assets/icons/Map_Icon.svg'
import check from 'assets/demo/check.png'
import googlePlaceholder from 'assets/demo/google-placeholder.png'
import Button from 'components/Button'
import { createPortal } from 'react-dom'
import './override.css'
import useCopyToClipboard from './useCopyToClipboard'
interface IProps extends ILocationModal {
  onChangeVisible?: (visible: boolean) => void
  duration?: number
}

export const LocationModal: FC<IProps> = props => {
  const { venueName, address, onChangeVisible, duration = 3 } = props
  const [width, setWidth] = useState(0)
  const [showToast, setShowToast] = useState(false)
  const { copiedText, copy } = useCopyToClipboard()

  const timerId = useRef<NodeJS.Timer>()

  useEffect(() => {
    return () => {
      clearInterval(timerId.current)
    }
  }, [])

  useEffect(() => {
    if (width > 100)
      onCloseToast()
  }, [width])

  const progress = () => {
    const percent = Math.floor(100 / duration)
    setWidth(w => w + percent)
  }

  const onClickCopy = () => {
    if (showToast) return
    setShowToast(true)
    copy(venueName + ' ' + address)
    timerId.current = setInterval(progress, 1000)
  }
  const onCloseToast = () => {
    setShowToast(false)
    clearInterval(timerId.current)
    setWidth(0)
  }

  const renderProgressBar = () => {
    return <div className="progress-bar">
      <div className="progress-bar-green" style={{ width: width + '%' }} />
    </div>
  }

  const renderToast = () => {
    return createPortal(<div className="modalCopy">
      <div className="modalCopy-noti">
        <img src={check} />
        <span>Text Copied.</span>
        <div className="modalCopy-remove" onClick={onCloseToast}></div>
      </div>
      {renderProgressBar()}
    </div>, document.body)
  }


  const renderHeader = () => {
    return <div className={styles.header}>
      <div className={styles.close} onClick={() => onChangeVisible(false)}>
        <IconDelete color='white' size={30} />
      </div>
    </div>

  }
  const renderContent = () => {
    return <div className={styles.content}>
      <div className={styles.photo}>
        <img src={Map_Icon} />
      </div>
      <p className={styles.name}>
        {venueName || 'Anaheim A, CA '}
      </p>
      <i className={styles.address}>
        {address || '12021 Harbor BLVD Garden Grove, CA 92840 USA'}
      </i>
      <div className={styles.map}>
        <img src={googlePlaceholder} />
      </div>
      <div className={styles.footer}>
        <Button title='Copy Address' className={styles.btnCopy} onClick={onClickCopy} />
      </div>
    </div>
  }
  const renderModal = () => {


    const element = () => (
      <div className={styles.wrapper}>
        {renderHeader()}
        {renderContent()}
      </div>
    ) as ReactNodeLike;

    return <>
      <Modal visible={true} onClick={() => onChangeVisible && onChangeVisible(false)}>
        {element()}
      </Modal>
      {showToast && renderToast()}
    </>
  }

  return renderModal()
}