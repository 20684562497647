import React from "react";
import styles from "./CartInfoItem.module.css";
import { ICartInfoItem } from "./CartInfoItem.types";
import { Button } from "@wwg/ui";
import VISA from "assets/demo/visa.svg";
import MASTERCARD from "assets/demo/mastercard.svg";
import DISCOVERCARD from "assets/demo/discover.svg";
import DINERS from "assets/demo/diners.svg";
import JCB from "assets/demo/jcb.svg";

interface ICartInfoItemProps {
  cardInfo?: ICartInfoItem;
  address?: string;
  onEdit?: Function;
}

export const CartInfoItem = (props: ICartInfoItemProps) => {
  const { cardInfo, address, onEdit } = props;

  const renderCard = () => {
    switch (cardInfo.cardType?.toLowerCase()) {
      case "visa":
        return (
          <div className={styles.card}>
            <img src={VISA} alt="Visa" />
          </div>
        );
      case "mastercard":
        return (
          <div className={styles.card}>
            <img src={MASTERCARD} alt="Mastercard" />
          </div>
        );
      case "discover card":
        return (
          <div className={styles.card}>
            <img src={DISCOVERCARD} alt="Discover Card" />
          </div>
        );
      case "diners club international":
        return (
          <div className={styles.card}>
            <img src={DINERS} alt="Diners Club International" />
          </div>
        );
      case "jcb":
        return (
          <div className={styles.card}>
            <img src={JCB} alt="JCB" />
          </div>
        );
      default:
        return <div className={styles.card}></div>;
    }
  };

  return (
    <div className={styles.container}>
      {address ? (
        <div>
          <div className={styles.title}>Billing Address</div>
          <div className={styles.address}>
            <div>{address}</div>
            <div>
              <Button
                className={styles.button}
                onClick={() => {
                  onEdit();
                }}
              >
                <span className={styles.buttonTitle}>Edit</span>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {cardInfo ? (
        <div>
          <div className={styles.title}>
            {onEdit ? "Pay With" : "Payment method"}
          </div>
          <div className={styles.cardContent}>
            <div className={styles.cardInfo}>
              {renderCard()}
              <div>*{cardInfo.lastFour}</div>
            </div>
            <div>
              {" "}
              <Button
                className={styles.button}
                onClick={() => {
                  onEdit();
                }}
              >
                <span className={styles.buttonTitle}>Edit</span>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
