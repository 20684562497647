import { FC } from "react";
import styles from "./SessionItem.module.css";
import { ISessionItem } from "./SessionItem.types";

interface IProps extends ISessionItem {}

export const SessionItem: FC<IProps> = (props) => {
  const { endTime, name, note, startTime } = props;
  // const {endTime,name,note,} = props

  const formatTime = (time: string) => {
    return time?.split(" ")[0]; // Get the time part without AM/PM
  };

  const renderItemTime = () => {
    if (!startTime && !endTime) return "";

    const formattedStartTime = formatTime(startTime);
    const formattedEndTime = formatTime(endTime);
    const period = endTime?.split(" ")[1]?.toLowerCase();

    return `${formattedStartTime} - ${formattedEndTime} ${period}`;
  };

  return (
    <div className={styles.item}>
      <p className={styles.itemName}>{name || "Seminar"}</p>
      <p className={styles.itemTime}>{renderItemTime()}</p>
      <p className={styles.itemNote}>
        <i>{note || ""}</i>
      </p>
    </div>
  );
};
