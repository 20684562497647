import { useQuery, useQueryClient, useMutation } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";

export function useEaglesData() {
  const queryClient = useQueryClient();

  const { isLoading, isSuccess, isError, data, error, fetch, refetch } =
    useQuery(["eagles"], () =>
      makeAuthedRequest(`/eagles`, { method: "get" }).then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          return json;
        } else {
          throw new Error(json.message);
        }
      })
    );

  const deleteEagleMutation = useMutation(
    (id) =>
      makeAuthedRequest(`/eagles/${id}`, {
        method: "delete",
      }).then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          if (json.errors && json.errors.length > 0) {
            throw new Error(json.errors[0].message);
          }
        }
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries("eagles");
      },
    }
  );

  const addEagleMutation = useMutation(
    (info) =>
      makeAuthedRequest(`/eagles/add`, {
        method: "POST",
        body: JSON.stringify({
          eventId: info.eventId,
          ibo: info.ibo,
          location: info.location,
          name: info.name,
          qualification: info.qualification,
        }),
      }).then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          if (json.errors && json.errors.length > 0) {
            throw new Error(json.errors[0].message);
          }
        }
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries("eagles");
      },
    }
  );

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    fetch,
    refetch,
    deleteEagleMutation,
    addEagleMutation,
  };
}
