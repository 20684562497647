import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./CartPage.module.css";
import CartList from "../../components/CartList";
import { CartHeader } from "../../components/CartHeader/CartHeader";
import Footer from "components/Footer";
import CartIcon from "assets/demo/Empty_Cart_Icon.png";
import { useCartInfo } from "pages/CartPage/useCartInfo";
import { useDeleteCartItem } from "pages/CartPage/useCartDelete";
import { useCart } from "stores/CartContext";
import Loader from "components/Loader";
import { extractDate } from "utils/formatDate";

interface DeleteCartItemParams {
  eventId: number;
  ticketId: number;
  contactId: number;
}

export const CartPage = () => {
  const history = useHistory();
  const { data: itemsData = [], isLoading } = useCartInfo();
  const deleteCartItem = useDeleteCartItem();

  // const { cartItems, updateCartItem } = useCart();
  const { updateCartItem } = useCart();

  // const eventDetails = cartItems.length > 0 ? cartItems[0] : null;
  // const iboImages =
  //   cartItems.length > 0 ? cartItems.map((item) => item.iboImage) : null;

  const eventDetails = itemsData.length > 0 ? itemsData[0] : null;
  const iboImages =
    itemsData.length > 0 ? itemsData.map((item) => item.iboImage) : null;

  const onClickCheckout = () => {
    history.push("/cart-checkout");
  };

  const handleCartChange = (ticketId, newPrice) => {
    updateCartItem(ticketId, newPrice);
  };

  const renderCartEmpty = () => {
    return (
      <div className={styles.cart}>
        <div className={styles.cartIcon}>
          <img src={CartIcon} alt={"Cart Icon"} className={styles.icon} />
        </div>
        <div className={styles.cartTitleBox}>
          <div className={styles.cartTitle}>{"Your Cart is Empty"}</div>
        </div>
      </div>
    );
  };

  //Delete cart item
  const handleCartItemDelete = (
    ticketId: number,
    contactId: number,
    eventId: number
  ) => {
    if (!contactId) {
      return;
    }

    const params: DeleteCartItemParams = {
      ticketId,
      eventId,
      contactId,
    };
    deleteCartItem.mutate(params);
  };

  // if ( isLoading) {
  //   <div>
  //     <Loader color={"#415364"} size={85} />
  //   </div>;
  // }

  console.log("ITEMS DATA", itemsData);
  console.log("CART EVENTDETAILS", eventDetails);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loader}>
          <Loader color={"#415364"} size={85} />
        </div>
      ) : itemsData.length > 0 ? (
        <>
          <div className={styles.header}>
            <CartHeader
              // events={events}
              name={eventDetails.title}
              className={"bg-color-243746"}
              isCartPage={true}
            />
          </div>
          <div className={styles.body}>
            <CartList
              name={eventDetails.title}
              location={eventDetails?.location?.city}
              eventDate={extractDate(eventDetails.startsOn)}
              // location={eventDetails.location}
              // eventDate={extractDate(eventDetails.eventDate)}
              items={itemsData}
              onItemsChange={handleCartChange}
              onDelete={handleCartItemDelete}
              isCartPage={true}
            />

            {/* <div className={styles.bottomContainer}>
            <TicketTypeDropdown volunteer={true} setVolunteer={() => {}} />
          </div> */}
          </div>

          <Footer isOpen={true} onClose={() => {}}>
            <div className={styles.footer}>
              <div className={styles.footerText}>
                You have 17:45 left to complete this purchase.
              </div>
              <div className={styles.buttonCheckout}>
                <button onClick={() => onClickCheckout()}>Check out</button>
              </div>
            </div>
          </Footer>
        </>
      ) : (
        renderCartEmpty()
      )}
    </div>
  );
};
