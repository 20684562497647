import React from "react";
import classNames from "classnames";
import styles from "./Popup.module.css";
interface IIPopupProps {
  isOpen?: boolean; //Getter to determine if Popup is displayed
  onClose?: () => void; //Callback function for when the Popup is closed
  children?: JSX.Element; //What’s displayed in the body of the Popup
  isCloseIcon?: boolean; //Show close icon,
  className?: string;
}

export const Popup: React.FC<IIPopupProps> = (props) => {
  const {
    isCloseIcon = props.isCloseIcon || true,
    children,
    isOpen,
    onClose,
    className,
  } = props;

  return (
    <div
      style={{
        visibility: isOpen ? "visible" : "hidden",
        opacity: isOpen ? "1" : "0",
      }}
      className={styles.overlay}
    >
      <div className={classNames(styles.popup, className)}>
        {isCloseIcon && (
          <span className={styles.close} onClick={onClose}>
            &times;
          </span>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
