import React from "react";
import styles from "./RadioButtonItem.module.css";

interface IRadioButtonItemProps {
  name: string;
  image: string;
  value: string;
  subValue: string;
  className?: string;
  isSelected: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

export const RadioButtonItem = (props: IRadioButtonItemProps) => {
  const {
    isSelected,
    value,
    onChange,
    name,
    image,
    subValue,
    className,
    disabled,
  } = props;

  return (
    <div
      className={`${styles.container} ${disabled ? styles.optionDisabled : ""}`}
    >
      <input
        type="checkbox"
        id={value}
        name={name}
        value={value}
        className={styles.checkboxBtn}
        onChange={onChange}
        checked={isSelected}
        style={{ display: "none" }}
        disabled={disabled}
      />
      <label htmlFor={value} className={styles.customRadioBtn}>
        {isSelected && <span className={styles.checkmark}>&#10003;</span>}
        <div className={styles.imageContainer}>
          <img
            src={image}
            alt={value}
            className={`${styles.icon} ${className}`}
          />
        </div>
        <div className={styles.labelContainer}>
          <span className={styles.labelText}>{value}</span>
          <span className={styles.subText}>{subValue}</span>
        </div>
      </label>
    </div>
  );
};
