import React from "react";
import PropTypes from "prop-types";
import styles from "./CardItem.module.css";
import VisaCard from "./cc_Visa.svg";
import CheckmarkIcon from "assets/icons/checkmark_Icon.png";

export function CardItem({ cardInfo, isPrimary, onSelect }) {
  return (
    <div className={styles.container}>
      <div className={`${styles.card} ${isPrimary ? styles.primaryBack : ""}`}>
        <div
          className={`${styles.button} 
          ${isPrimary ? styles.primaryBtn : styles.notPrimaryBtn}
          `}
        >
          <button
            className={`${styles.selectBtn} ${
              isPrimary ? styles.primaryBtn : styles.notPrimaryBtn
            }`}
            onClick={() => onSelect(cardInfo.membershipCreditCardId)}
          >
            {isPrimary ? (
              <img
                src={CheckmarkIcon}
                alt="Checkmark"
                className={styles.checkmark}
              />
            ) : (
              "Select"
            )}
          </button>
        </div>
        <div className={styles.cardImage}>
          <img src={VisaCard} alt="Card" className={styles.cardImage} />
        </div>
        <div className={styles.cardDetails}>
          <div className={styles.cardNumber}>
            <p className={styles.lastFour}>Ending in {cardInfo.lastFour}</p>
            <p className={styles.cardNickName}>{cardInfo.cardHolder}</p>
            {/* <p hidden>{cardInfo.zipCode}</p> */}
          </div>
          <div className={styles.cardExpire}>
            Exp: {cardInfo.expireMonth}/{cardInfo.expireYear}
          </div>
        </div>
      </div>
    </div>
  );
}

CardItem.propTypes = {
  cardInfo: PropTypes.shape({
    // zipCode: PropTypes.string,
    nickName: PropTypes.string,
    lastFour: PropTypes.string,
    expYear: PropTypes.string,
    expMonth: PropTypes.string,
    isPrimary: PropTypes.bool,
  }).isRequired,
  isPrimary: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default CardItem;
