import { FC } from 'react'
import styles from './QuestionMarkIcon.module.css';

interface IIQuestionMarkIconProps {
  width?: number,
  height?: number,
  fill?: string,
  fillText?: string,
  onClick?: () => void
}

export const QuestionMarkIcon: FC<IIQuestionMarkIconProps> = props => {
  const width = props.width || 26,
    height = props.width || 26,
    fill = props.fill || '#000',
    fillText = props.fillText || '#fff';

  return <svg className={styles.svg} onClick={() => props.onClick && props.onClick()} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26"><g transform="translate(-937 -150)"><g stroke={fill} strokeWidth="1" data-name="Ellipse 1287" transform="translate(937 150)"><circle cx="13" cy="13" r="13" stroke="none"></circle><circle cx="13" cy="13" r="12.5" fill={fill}></circle></g><path fill={fillText} d="M3.764 1.884l-.019-.5a3.766 3.766 0 011.087-2.8 5.16 5.16 0 001.64-3.318c0-1.449-.991-2.8-3.184-2.8a5 5 0 00-2.727.763l.458 1.2a3.389 3.389 0 011.926-.591c1.2.019 1.811.667 1.811 1.64A4.189 4.189 0 013.421-1.91a4.377 4.377 0 00-1.182 3.3l.038.5zm-.782 3.851a1.111 1.111 0 001.144-1.182A1.113 1.113 0 003 3.352a1.127 1.127 0 00-1.144 1.2 1.118 1.118 0 001.126 1.183z" data-name="IBO Number: 867" transform="translate(946.484 163.9)"></path></g></svg>
}
