import { useQuery, useQueryClient, useMutation } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import env from "../../environment"
import { useContainer } from "unstated-next";
import SalesContext from "../../stores/SalesContext";

/**
 *  QUERY events: useEventsData() -> [{...event}, ...]
 *  UPDATE events: useEventsData.updateEventsMutation([{...event},...])
 */

export const cartEndPoints = {
  // TODO: Check these before deployment
  get: {
    loadCart: "/api/cart/load/web",
  },
  post: {
    addTicket: "/api/cart/ticket/",
  },
  put: {
    ticket: "/api/cart/ticket/",
    ticketOptions: "/api/cart/ticket/",
    addTime: "/api/cart/add-time/",
  },
  delete: {
    ticket: "/api/cart/ticket/",
  },
};

export function useCartData() {
  const queryClient = useQueryClient();

  const { isLoading, isSuccess, isError, data, error, fetch, refetch } =
    useQuery(["cart"], () =>
      makeAuthedRequest(cartEndPoints.get.loadCart, { method: "get" }, env.apiBaseOld).then(
        async (res) => {
          const json = await res.json();
          if (res.ok) {
            return json;
          } else {
            throw new Error(json.message);
          }
        }
      )
    );

  const addTicketsMutation = useMutation(
    ({ eventId, qty }) =>
      makeAuthedRequest(cartEndPoints.post.addTicket, {
        method: "post",
        body: JSON.stringify({
          eventId,
          qty,
        }),
      }, env.apiBaseOld).then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          if (json.errors && json.errors.length > 0) {
            throw new Error(json.errors[0].message);
          }
        }
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries("cart");
      },
    }
  );

  const deleteTicketMutation = useMutation(
    (orderAccountingId) =>
      makeAuthedRequest(`${cartEndPoints.delete.ticket}${orderAccountingId}`, {
        method: "delete",
      }, env.apiBaseOld).then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          if (json.errors && json.errors.length > 0) {
            throw new Error(json.errors[0].message);
          }
        }
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries("cart");
      },
    }
  );

  const updateTicketMutation = useMutation(
    (ticket) =>
      makeAuthedRequest(cartEndPoints.put.ticket, {
        method: "put",
        body: JSON.stringify(ticket),
      }, env.apiBaseOld).then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          if (json.errors && json.errors.length > 0) {
            throw new Error(json.errors[0].message);
          }
        }
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries("cart");
      },
    }
  );

  const updateTicketOptionsMutation = useMutation(
    ({ accountingId, options }) =>
      makeAuthedRequest(cartEndPoints.put.ticketOptions, {
        method: "delete",
        body: JSON.stringify({ accountingId, options }),
      }, env.apiBaseOld).then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          if (json.errors && json.errors.length > 0) {
            throw new Error(json.errors[0].message);
          }
        }
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries("cart");
      },
    }
  );

  const addTimeMutation = useMutation(
    () =>
      makeAuthedRequest(cartEndPoints.put.addTime, {
        method: "put",
      }, env.apiBaseOld).then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          if (json.errors && json.errors.length > 0) {
            throw new Error(json.errors[0].message);
          }
        }
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries("cart");
      },
    }
  );

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    fetch,
    refetch,
    addTimeMutation,
    addTicketsMutation,
    deleteTicketMutation,
    updateTicketOptionsMutation,
    updateTicketMutation,
  };
}
