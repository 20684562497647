import React, { useState } from "react";
import styles from "./TicketTypeDropdown.module.css";
import classNames from "classnames";
import Down_Arrow from "assets/icons/Down_Arrow.svg";
import { ReactComponent as HomesiteIcon } from "assets/icons/HomeSite_Icon_Small.svg";
import Information from "assets/icons/Information.svg";
import Modal from "components/Modal";

interface ITicketTypeDropdownProps {
  title: string;
  options: { id: number; ticketType: string; price: number }[];
  // volunteer: boolean;
  selectedOption: { id: number; ticketType: string; price: number };
  onSelect: (option: { id: number; ticketType: string; price: number }) => void;
}

export const TicketTypeDropdown = (props: ITicketTypeDropdownProps) => {
  const { title, options, selectedOption, onSelect } = props;

  const [dropDownActive, setDropDownActive] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const handleSelection = (option: {
    id: number;
    ticketType: string;
    price: number;
  }) => {
    onSelect(option);
    setDropDownActive(false);
  };

  const renderDropdown = () => (
    <div className={styles.selectionDiv}>
      <div
        className={styles.selection}
        onClick={() => setDropDownActive(!dropDownActive)}
      >
        <div className={styles.selectionItem}>
          {`${selectedOption.ticketType} - $${selectedOption.price.toFixed(2)}`}
          <img
            src={Down_Arrow}
            alt=""
            className={classNames(
              styles.arrow,
              dropDownActive && styles.rotate
            )}
          />
        </div>
        {dropDownActive && (
          <div className={styles.optionsContainer}>
            {options.map((option) => (
              <div
                key={option.id}
                className={styles.option}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelection(option);
                }}
              >
                {`${option.ticketType} - $${option.price.toFixed(2)}`}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const renderTicketInfo = () => {
    return (
      <Modal
        icon={<HomesiteIcon />}
        isOpen={isInfoOpen}
        onClose={() => setIsInfoOpen(false)}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>Ticket Options</div>
          <div className={styles.modalContent}>
            <p className={styles.subtitle}>Local Function Credits</p>
            <ul className={styles.description}>
              {/* &#8226;{" "} */}
              <li className={styles.text}>
                You have credits that were previously purchased for a Local
                Function. Local function credits on your account are
                automatically added to your cart until they are all used up.
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.dropdownHeader}>
        <div className={styles.dropdownTitle}>{title}</div>
        <img
          src={Information}
          alt={""}
          className={styles.infoIcon}
          onClick={() => setIsInfoOpen(true)}
        />
      </div>
      <div className={styles.dropdownContent}>{renderDropdown()}</div>

      <div className={styles.ticketInfo}>{renderTicketInfo()}</div>
    </div>
  );
};
