import * as React from "react";
import { ReactNodeLike } from "prop-types";
import { Modal } from "@wwg/ui";
import styles from "./CartPopup.module.css";
import Cart_Icon from "assets/icons/Cart_Icon.svg";

interface IIModalProps {
  icon?: string; //Icon displayed above the header
  header?: string; //Header below the icon
  isOpen?: boolean; //Getter to determine if modal is displayed
  onClose?: () => void; //Callback function for when the modal is closed
  children?: JSX.Element; //What’s displayed in the body of the modal
  isCloseIcon?: boolean; //Show close icon
}

export const CartPopup = (props) => {
  const { isOpen, children } = props;

  const renderContent = () => {
    return (
      <div className={styles.content}>
        <div className={styles.photo}>
          <img src={Cart_Icon} alt={""} />
        </div>
        <p className={styles.name}>Added to Cart</p>
      </div>
    );
  };

  const element = () =>
    (<div className={styles.wrapper}>{renderContent()}</div>) as ReactNodeLike;

  return (
    <>
      <Modal visible={isOpen} onClick={() => {}}>
        {element()}
      </Modal>
    </>
  );
};
