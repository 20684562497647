import classNames from "classnames";
import { format } from "date-fns";
import { useState, FC, useEffect } from "react";
import styles from "./CountDown.module.css";
import { useCountdown } from "./useCountDown";
import { formatInUTC } from "utils/formatDate";

interface IProps {
  // location: string;
  date: string;
  onSaleDate: string;
}

export const CountDown: FC<IProps> = (props) => {
  //onSaleDate removed for not needed functionality,
  //if needed add onSaleDate back as a prop and revert changes.
  // const { date, onSaleDate, promoEndsOn } = props;
  const { date, onSaleDate } = props;

  const [days, hours, minutes] = useCountdown(date);
  const [isOnSale, setIsOnSale] = useState(false);

  const classCss = classNames(styles.wrapperCircle, {
    [styles.isOnSale]: onSaleDate,
    [styles.notOnSale]: !onSaleDate,
  });

  useEffect(() => {
    const isSale = new Date().getTime() <= new Date(date).getTime();
    setIsOnSale(isSale);
  }, [date, days, hours, minutes]);

  const renderTimeCircle = (title: string, time: number = 0) => {
    return (
      <div className={styles.circleItem}>
        <div className={styles.circle}>{time}</div>
        <p>{title}</p>
      </div>
    );
  };

  const renderDate = () => {
    const ISODate = new Date(date);
    if (!isNaN(ISODate.getTime()))
      return formatInUTC(new Date(date), "MMMM dd, yyyy");

    return date;
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title2}>{renderDate()}</p>
      <div className={classCss}>
        {renderTimeCircle("Days", days)}
        {renderTimeCircle("Hours", hours)}
        {renderTimeCircle("Minutes", minutes)}
      </div>
      {/* {!isOnSale && renderNotification()} */}

      {/* {onSaleDate && (
        <div className={styles.ticket}>
          Tickets on sale <strong>{onSaleDate}</strong>
        </div>
      )} */}

      {onSaleDate && (
        <div className={styles.ticket}>
          {isOnSale ? (
            <>
              Tickets on sale <strong>{renderDate()}</strong>
            </>
          ) : (
            <>
              Tickets went on sale <strong>{renderDate()}</strong>
            </>
          )}
        </div>
      )}
    </div>
  );
};
