import React, { useState, useEffect } from "react";
import SessionItem from "components/SessionItem";
import { FC } from "react";
import styles from "./FunctionInfoCard.module.css";
// import { IFunctionInfoCard } from "./FunctionInfoCard.types";
// interface IProps extends IFunctionInfoCard {}

interface IProps {
  sessions: any;
  speakerInfo: { name: string; image: string } | null;
  eventInfo:
    | { title: string; startTime: string; endTime: string; description: string }
    | null
    | Array<{
        title: string;
        startTime: string;
        endTime: string;
        description: string;
      }>;
  priceStr: string;
}

export const FunctionInfoCard: React.FC<IProps> = (props) => {
  const { eventInfo, speakerInfo, sessions, priceStr } = props;

  // const { image, firstName, lastName } = speakerInfo || {};
  // const { fullName,startTime, endTime, note } = eventInfo || {};
  const { image, name } = speakerInfo || {};

  const [isMobile, setIsMobile] = useState(window.innerWidth < 425);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 425);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderInfoLeftMobile = () => {
    // const { img, names } = speakerInfo || {};
    return (
      <div className={styles.mobileContainer}>
        <div className={styles.leftMobile}>
          <div className={styles.photoMobile}>
            <img src={image} alt="Speaker" />
          </div>
        </div>

        <div className={styles.namesContainer}>
          <div>
            <p className={styles.namesMobile}>
              {name}
              {/* <span>{firstName} </span>
              <span className={styles.lastNameMobile}> {lastName}</span> */}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderInfoRightMobile = () => {
    return (
      <div className={styles.right}>
        <div className={styles.sessionContent}>
          {Array.isArray(eventInfo) ? (
            eventInfo.map((event, index) => (
              <div key={index} className={styles.sessionItem}>
                <SessionItem
                  name={event?.title}
                  startTime={event?.startTime}
                  endTime={event?.endTime}
                  note={event?.description}
                />
              </div>
            ))
          ) : (
            <div className={styles.sessionItem}>
              <SessionItem
                name={eventInfo?.title}
                startTime={eventInfo?.startTime}
                endTime={eventInfo?.endTime}
                note={eventInfo?.description}
              />
            </div>
          )}
        </div>
        <div className={styles.priceMobile}>{priceStr}</div>
      </div>
    );
  };

  const renderInfoLeft = () => {
    return (
      <div className={styles.itemLeft}>
        <div className={styles.photo}>
          <img src={image} alt="" />
        </div>
      </div>
    );
  };

  const renderInfoRight = () => {
    return (
      <div className={styles.itemRight}>
        <div className={styles.names}>
          <span>{name}</span>
          {/* <span>{firstName}</span>
          <span className={styles.lastName}>{lastName}</span> */}
        </div>

        <div className={styles.sessionContent}>
          {Array.isArray(eventInfo) ? (
            eventInfo.map((event, index) => (
              <div key={index} className={styles.sessionItem}>
                <SessionItem
                  name={event?.title}
                  startTime={event?.startTime}
                  endTime={event?.endTime}
                  note={event?.description}
                />
              </div>
            ))
          ) : (
            <div className={styles.sessionItem}>
              <SessionItem
                name={eventInfo?.title}
                startTime={eventInfo?.startTime}
                endTime={eventInfo?.endTime}
                note={eventInfo?.description}
              />
            </div>
          )}
        </div>
        <div className={styles.price}>{priceStr}</div>
      </div>
    );
  };

  const eventFullName = Array.isArray(eventInfo)
    ? eventInfo[0]?.title
    : eventInfo?.title;

  return (
    <div
      className={
        eventFullName === "Organizational"
          ? styles.orgContainer
          : styles.container
      }
    >
      <div className={styles.itemLeft}>
        {isMobile ? renderInfoLeftMobile() : renderInfoLeft()}
      </div>
      <div>{isMobile ? renderInfoRightMobile() : renderInfoRight()}</div>
    </div>
  );
};
