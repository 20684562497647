import React from "react";
import { Modal as WWGModal } from "@wwg/ui";
import { ReactNodeLike } from "prop-types";
import styles from "./Modal.module.css";

interface IIModalProps {
  icon?: JSX.Element; //Icon displayed above the header
  header?: string; //Header below the icon
  isOpen?: boolean; //Getter to determine if modal is displayed
  onClose?: () => void; //Callback function for when the modal is closed
  children?: JSX.Element; //What’s displayed in the body of the modal
  isCloseIcon?: boolean; //Show close icon
}

export const Modal: React.FC<IIModalProps> = ({
  icon,
  header,
  isOpen,
  onClose,
  children,
  isCloseIcon = true,
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        {isCloseIcon && (
          <div className={styles.close} onClick={onClose}>
            {/* Simplified SVG for Close Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <g fill="none" stroke="#fff" strokeWidth="2">
                <circle cx="16" cy="16" r="16" stroke="none"></circle>
                <circle cx="16" cy="16" r="15"></circle>
              </g>
              <g
                fill="#fff"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
                transform="translate(8 8)"
              >
                <path d="M0 16 L16 0 M0 0 L16 16" />
              </g>
            </svg>
          </div>
        )}
        {icon}
        {header && <div className={styles.header}>{header}</div>}
        {children}
      </div>
    </div>
  );
};
