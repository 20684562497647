import React from "react";
import styles from "./CartHeader.module.css";
// import EventIcon from "../EventIcon";
import FamilyReunionIcon from "assets/icons/Family_Reunion_Icon.svg";
import SpringLeadershipIcon from "assets/icons/Spring_Leadership_Icon.svg";
import OrganizationalIcon from "assets/icons/Organizational_icon.svg";
import FreeEnterpriseIcon from "assets/icons/Free_Enterprise_icon.svg";
import DreambuildersIcon from "assets/icons/Dreambuilders_Membership_Circle_Icon.svg";
import DreamNightIcon from "assets/icons/DreamNight_Icon.svg";
import RegionalIcon from "assets/icons/Regional_Icon_White_Border.svg";

import SecondLookIcon from "assets/icons/Second_Look_Icon.svg";

interface ICartHeaderProps {
  name: string;
  //   events?: any[];
  className?: string;
  isCartPage: boolean;
}

export const CartHeader = (props: ICartHeaderProps) => {
  const { name, className, isCartPage } = props;

  const showIcon = (eventName) => {
    if (eventName.includes("Family Reunion")) {
      return FamilyReunionIcon;
    }

    if (eventName.includes("Spring Leadership")) {
      return SpringLeadershipIcon;
    }

    if (eventName.includes("Organizational")) {
      return OrganizationalIcon;
    }

    if (eventName.includes("Free Enterprise")) {
      return FreeEnterpriseIcon;
    }

    if (eventName.includes("Regional")) {
      return RegionalIcon;
    }

    switch (eventName) {
      case "Dreambuilders™ Membership":
        return DreambuildersIcon;
      //   case "Regional":
      //     return RegionalIcon;
      case "Dream Night":
        return DreamNightIcon;
      default:
        return SecondLookIcon;
    }
  };

  return (
    <>
      {isCartPage ? (
        <div className={styles.container}>
          <div className={styles.cartIcon}>
            {/* <EventIcon events={events} /> */}
            <img
              src={showIcon(name)}
              alt={"Event Icon"}
              className={styles.icon}
            />
          </div>
          <div className={styles.cartTitleBox + " " + styles[className]}>
            <div className={styles.cartTitle}>{name}</div>
          </div>
        </div>
      ) : (
        <div className={styles.checkoutContainer}>
          <img
            src={showIcon(name)}
            alt={"Event Icon"}
            className={styles.icon}
          />

          <div className={styles.eventName}>{name}</div>
        </div>
      )}
    </>
  );
};
