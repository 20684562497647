import { FC } from "react";
import { IWhatNewCard } from "./WhatNewCard.types";
import classnames from "classnames";
import styles from "./WhatNewCard.module.css";
import HomeLocationIcon from "components/HomeLocationIcon";

interface IProps extends IWhatNewCard {}

export const WhatNewCard: FC<IProps> = (props) => {
  const { children, description, footer, header, subHeader } = props;

  const renderLogo = () => {
    return (
      <div className={styles.logo}>
        <HomeLocationIcon className={styles.img} />
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <p>
          <b>{header}</b>
        </p>
        <p>{subHeader}</p>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      description && (
        <div className={styles.description}>
          <p className={styles.text}>{description}</p>
        </div>
      )
    );
  };

  const renderFooter = () => {
    return (
      footer && (
        <div className={styles.footer}>
          <p>
            <i>{footer}</i>
          </p>
        </div>
      )
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {renderLogo()}
        {renderHeader()}
        <div className={styles.contentContainer}>
          {renderDescription()}
          <div className={styles.contentList}>{children}</div>
        </div>
        {renderFooter()}
      </div>
    </div>
  );
};
