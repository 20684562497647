import React from 'react'
import styles from './Footer.module.css';

interface IFooterProps {
    isOpen: boolean,
    onClose: Function
    children: JSX.Element
}

export const Footer = (props: IFooterProps) => {
    const { isOpen, onClose, children } = props
    return (
        isOpen
            ? <div className={styles.footer}>
                {children}
            </div>
            : <></>
    )
}
