import React from 'react'
interface IProps {

}

export const TrungComponents: React.FC<IProps> = props => {
  return (
    <div className={''}>
      <div className={''}>
        TrungComponents
      </div>
    </div>
  )
}
