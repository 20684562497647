import styles from "./App.module.css";
import { ReactNodeLike } from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { IconDiamond } from "@wwg/icons";
import { AppFrame } from "@wwg/ui";
import Cart from "./pages/Cart";
import Purchase from "./pages/Purchase";
import mobileBridge from "./mobileBridge";
import EagleRegistration from "pages/EagleRegistration";
import SalesContext from "./stores/SalesContext";
import HomeSite from "pages/HomeSite";
import {
  LocalFunctions,
  LocalFunctionsEvent,
  LocalFunctionsInfo,
} from "pages/LocalFuntions";
import CartPage from "pages/CartPage";
import CartCheckout from "pages/CartPage/CartCheckout";
import MajorFunctions from "pages/MajorFunctions";
import * as Utils from "./utils";
import HomeSiteNoMeeting from "pages/HomeSiteNoMeeting";
import DemoComponents from "pages/DemoComponents";
import WhatsNewHomeSite from "pages/WhatsNewHomeSite";
import HomeSiteNoSetUp from "pages/HomeSiteNoSetUp";
import { TermsAndConditions } from "pages/TermsAndConditions";
import React, { useEffect, useState } from "react";
import { CartProvider } from "stores/CartContext";
import { LocalItemsProvider } from "stores/LocalItemContext";

// icons for nav
import { ReactComponent as HomeSiteIcon } from "../src/assets/icons/HomeSite_Nav_Icon.svg";
import { ReactComponent as MajorFuncIcon } from "../src/assets/icons/MajorFunctions_Nav_Icon.svg";
import { ReactComponent as LocalFuncIcon } from "../src/assets/icons/Local_Functions_Nav_Icon.svg";
import { ReactComponent as CartIcon } from "../src/assets/icons/Cart_Nav_Icon.svg";

/**
 * This is the main structure component where the top-level navigation is defined.
 */

export function App() {
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, [initialLoad]);
  return (
    // <TicketStatusProvider>
    <CartProvider>
      <SalesContext.Provider
        initialState={{ purchaseTypeId: 2048, year: 2021 }}
      >
        <div className={styles.app_frame}>
          <AppFrame
            mobileBridge={mobileBridge}
            navigation={[
              {
                title: "HomeSite",
                icon: <HomeSiteIcon />,
                path: Utils.PATHS.HOMESITE,
              },
              {
                title: "Major Functions",
                icon: <MajorFuncIcon />,
                path: Utils.PATHS.MAJOR_FUNCTIONS,
              },
              {
                title: "Local Functions",
                icon: <LocalFuncIcon />,
                path: Utils.PATHS.LOCAL_FUNCTIONS,
              },
              { title: "Cart", icon: <CartIcon />, path: Utils.PATHS.CART },
            ]}
            header={{
              title: "Event Tickets",
              icon: (<IconDiamond size={42} />) as ReactNodeLike,
            }}
          >
            <main className={styles.main}>
              <Switch>
                <Route path="/cart/:eventId">
                  <Cart />
                </Route>
                <Route path="/purchase">
                  <Purchase />
                </Route>
                <Route path="/eagle">
                  <EagleRegistration />
                </Route>

                {/* ************************** new Page ***************** */}
                <Route exact path={Utils.PATHS.HOMESITE}>
                  <HomeSite />
                </Route>
                <Route exact path={Utils.PATHS.CART}>
                  <CartPage />
                </Route>
                <Route exact path={Utils.PATHS.HOMESITE_NO_MEETING}>
                  <HomeSiteNoMeeting />
                </Route>
                <Route exact path={Utils.PATHS.MAJOR_FUNCTIONS}>
                  <MajorFunctions />
                </Route>

                {/* <LocalItemsProvider> */}
                <Route exact path={Utils.PATHS.LOCAL_FUNCTIONS}>
                  <LocalFunctions />
                </Route>
                <Route exact path={Utils.PATHS.LOCAL_FUNCTION_INFO}>
                  <LocalFunctionsInfo />
                </Route>
                {/* <Route exact path={Utils.PATHS.LOCAL_FUNCTION_EVENT}>
                  <LocalFunctionsEvent />
                </Route> */}

                <Route exact path={Utils.PATHS.LOCAL_FUNCTION_MEETING}>
                  <LocalFunctionsEvent />
                </Route>
                {/* </LocalItemsProvider> */}

                <Route exact path={Utils.PATHS.DEMO_COMPONENTS}>
                  <DemoComponents />
                </Route>
                <Route exact path={Utils.PATHS.WHAT_NEW_HOMESITE}>
                  <div className={styles.fullScreen}>
                    <WhatsNewHomeSite />
                  </div>
                </Route>
                <Route exact path={Utils.PATHS.CART_CHECKOUT}>
                  <CartCheckout />
                </Route>
                <Route exact path={Utils.PATHS.HOMESITE_NO_SETUP}>
                  <HomeSiteNoSetUp />
                </Route>
                <Route exact path={Utils.PATHS.TERMS_CONDITIONS}>
                  <TermsAndConditions />
                </Route>

                {/* ************************** end new Page ***************** */}

                <Route path="*">
                  <div className={styles.fullScreen}>
                    {initialLoad ? (
                      <Redirect to={Utils.PATHS.WHAT_NEW_HOMESITE} />
                    ) : (
                      <Redirect to={Utils.PATHS.HOME} />
                    )}
                  </div>
                </Route>
              </Switch>
            </main>
          </AppFrame>
        </div>
      </SalesContext.Provider>
    </CartProvider>
  );
}
