import { createContainer } from "unstated-next";
import { useState } from "react";

/**
 * React context store that provides data required for routes and Receipt page.
 */

function useSalesDataHook(initialState = {}) {

  let [value, setValue] = useState(initialState);
  let setPurchaseTypeId = (purchaseTypeId) => setValue({...value, purchaseTypeId })
  let setYear = (year) => setValue({...value, year})
  let setOrderId = (orderId) => setValue({ ...value, orderId });
  let setReceiptToken = (receiptToken) => setValue({ ...value, receiptToken })
  let setReceiptEvents = (receiptEvents) =>
    setValue({ ...value, receiptEvents });
  let setReceiptTickets = (receiptTickets) =>
    setValue({ ...value, receiptTickets });
  let setReceiptAmount = (receiptAmount) =>
    setValue({ ...value, receiptAmount });
  let setReceiptPurchasedOn = (receiptPurchasedOn) =>
    setValue({ ...value, receiptPurchasedOn });
  let setPaymentMethod = (paymentMethod) => setValue({...value, paymentMethod})

  return {
    value,
    purchaseTypeId: value.purchaseTypeId,
    year: value.year,
    orderId: value.orderId,
    receiptToken: value.receiptToken,
    receiptEvents: value.receiptEvents,
    receiptTickets: value.receiptTickets,
    paymentMethod: value.paymentMethod,
    setPurchaseTypeId,
    setYear,
    setOrderId,
    setReceiptToken,
    setReceiptEvents,
    setReceiptTickets,
    setReceiptAmount,
    setReceiptPurchasedOn,
    setPaymentMethod, 
  };
}

export default createContainer(useSalesDataHook);
