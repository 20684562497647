import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useQuery } from "react-query";
import env from "../../environment";

export function useHomesitesLocation(lat, lon, primary) {
  // const url = primary === 1 ? "homesites" : `homesites?lat=${lat}&lon=${lon}`;
  const url =
    primary === 1
      ? "homesites"
      : lat && lon
      ? `homesites?lat=${lat}&lon=${lon}`
      : null;

  const { data, isLoading, refetch } = useQuery(
    ["homesites", lat, lon, primary],
    () =>
      makeAuthedRequest(
        url,
        {
          method: "GET",
        },
        env?.apiBase
      ).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error((await res.json()).message);
        }
      }),
    {
      enabled: !!url,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return {
    data,
    isLoading,
    refetch,
  };
}
