import { AppFooter, AppHeader, Button, Modal } from "@wwg/ui";
import { useParams } from "react-router";
import { usePurchaseData } from "./usePurchaseData";
import { useCartData } from "../Cart/useCartData";
import { TicketSummary } from "../../components/TicketSummary/TicketSummary";
import { PaymentDetails } from "../../components/PaymentDetails/PaymentDetails";
import SalesContext from "../../stores/SalesContext";
import styles from "./Purchase.module.css";
import { useHistory } from "react-router-dom";
import { useState } from "react";

// import { useCountDownTimerData } from "./useCountDownTimerData";
// import styles from "./Purchase.module.css";

/**
 * Purchase page structure component.
 * Must be mounted inside a <Route> component.
 */

export function Purchase() {
  //
  // Dictionaries
  //

  //
  // API Hooks
  //
  const purchaseData = usePurchaseData();
  const cartData = useCartData();
  const {
    paymentMethod,
    purchaseTypeId,
    year,
    setOrderId,
    setReceiptToken,
    setReceiptEvents,
    setReceiptAmount,
    setReceiptPurchasedOn,
  } = SalesContext.useContainer();
  const history = useHistory();

  //
  // Dom States
  //

  const [modalState, setModal] = useState({ visible: false, children: [] });

  //
  // Callback Functions
  //

  function onPaymentMethodClick() {
    history.push("/card-selector");
  }

  function onBackClick() {
    history.push("/cart");
  }

  async function onPurchaseClick() {

    setReceiptEvents(cartData.data.events);

    const response = await purchaseData.purchaseMutation.mutateAsync({
      purchaseTypeId,
      year,
      compTickets: {
        available: 0,
        used: 0,
      },
      countDownSeconds: cartData.data.countDownSeconds,
      events: cartData.data.events,
      IBO: cartData.data.IBO,
      orderId: cartData.data.orderId,
      owed: cartData.data.owed,
      paid: cartData.paid,
      payment: {
        amount: cartData.data.owed,
        cardDisplay: null,
        creditCardId: paymentMethod.id,
        isCancelledAuth: false,
        isFailedAuth: false,
        isPendingAuth: false,
        nickname: null,
        purchasedOn: "0001-01-01T00:00:00",
        token: null,
      },
      token: null,
      iboCount: cartData.data.iboCount,
    });

    if (!response.isCancelledAuth && !response.isFailedAuth) {
      // If payment success
      setOrderId(response.orderAccountingId);
      setReceiptToken(response.token);
      setReceiptAmount(response.cost);
      setReceiptPurchasedOn(response.purchasedOn);
      history.push("/receipt");
    } else {
      // If payment failure
      setModal({ visible: true, children: [<CardDeclinedModal />] });
    }
  }

  function onModalCloseClick() {
    setModal({ visible: false, children: [] });
  }

  //
  // Build Objects
  //

  const CardDeclinedModal = () => (
    <div class={styles.containerCardDeclinedModal}>
      <p class={styles.cardDeclinedMessage}>
        Your credit card has been declined. Please try again.
      </p>
      <center>
        <Button onClick={onModalCloseClick}>Okay</Button>
      </center>
    </div>
  );

  const Summary = () => {
    const events = [];
    if (cartData.isSuccess) {
      Object.keys(
        cartData.data.events.filter((event) => event.tickets.length > 0)
      ).map((eventId, index) => {
        const event = cartData.data.events[eventId];
        events.push(
          <TicketSummary
            key={event.eventId}
            eventId={event.eventId}
            city={event.city}
            state={event.state}
            startsOn={event.startsOn}
            endsOn={event.endsOn}
            tickets={event.tickets}
          />
        );
      });
    }
    return events || <span> </span>;
  };

  const amountOwed = () => {
    let owed = 0;
    if (cartData.isSuccess) {
      owed = cartData.data.owed;
    }
    return owed || 0;
  };

  const TimeRemaining = () => {
    let seconds = 0;
    let remainder = 0;
    if (cartData.isSuccess) {
      seconds = (cartData.data.countDownSeconds / 60.0) | "0";
      remainder = cartData.data.countDownSeconds % 60.0 | "00";
    }
    return `${seconds}:${remainder.toString().padStart(2, 0)}`;
  };

  //
  // Return Objects
  //

  return (
    <div>
      <Summary />
      <hr />
      <PaymentDetails
        amountOwed={amountOwed()}
        paymentMethod={paymentMethod}
        onPaymentMethodClick={onPaymentMethodClick}
      />
      <AppFooter className={styles.footer}>
        <div className={styles.containerFooterContent}>
          <center className={styles.containerCheckoutButton}>
            <Button onClick={onBackClick}>Back</Button>
            <Button onClick={onPurchaseClick}>Purchase</Button>
          </center>
          <div className={styles.containerCountDownTimer}>
            <small className={styles.countDownTimer}>
              You have <TimeRemaining /> to complete this purchase
            </small>
          </div>
        </div>
      </AppFooter>
      <Modal visible={modalState.visible} children={modalState.children} />
    </div>
  );
}
