import { FC } from 'react'
import styles from './Notification.module.css'
import { INotification } from './Notification.types'
import Note_Icon from 'assets/icons/Note_Icon.svg'

interface IProps extends INotification {
}

export const Notification: FC<IProps> = props => {
  const { img, className, notification } = props

  return (
    <div
      style={className ? { background: className } : undefined}
      className={styles.section}
    >
      <div className={styles.left}>
        <img src={img || Note_Icon} alt={""} />
      </div>
      <div className={styles.right}>
        {notification || 'Please note, this meeting will begin at 6:00 p.m. instead of 8:00 p.m.'}
      </div>
    </div>
  )
}