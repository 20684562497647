import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useQuery } from "react-query";
import env from "../../environment";

export function useAddresses() {
  const { data, isLoading, refetch } = useQuery(["addresses"], () =>
    makeAuthedRequest(
      "addresses",
      {
        method: "GET",
      },
      env?.apiBase
    ).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error((await res.json()).message);
      }
    })
  );

  return {
    data,
    isLoading,
    refetch,
  };
}
