import React from 'react';
import styles from './TermsAndConditions.module.css';

interface ITermsAndConditionsProps {

}

export const TermsAndConditions = React.memo((props: ITermsAndConditionsProps) => {


  return <React.Fragment>
    <div className={styles.body}>
      <div className={styles.header}>Terms & Conditions</div>
      <div className={styles.title}>
        Agreement to use wwdb.com<br />Website and Mobile Application<br />and<br />Dreambuilders™ Membership<br />Terms and Conditions
      </div>
      <p>
        Welcome to Dreambuilders™ Membership, a Delaware<br />LLC. This website is being used to implement a new concept in the delivery of services to those World Wide Dreambuilders affiliated Independent Business Owners (IBOs) desiring to enhance their educational skills for development of their Amway™ business. As part of those services, users of this website shall be afforded the opportunity to purchase Business Support Materials at a preferred price. The following terms and conditions will control the use of this website, including the mobile application until further notification. By accessing this site and use of the services provided, you agree as a user to be bound by the terms and conditions of this Agreement.
        <br /><br />
        1. Purpose of This Website and Mobile<br />This website and associated mobile application’s purpose is to inform you of useful information as a World Wide Dreambuilders–affiliated Amway IBO, and is for your use in registering to acquire Business Support Materials. Premier Members may order Business Support Materials directly through this website and/or mobile application. If you have any questions regarding the site or mobile application, please contact ‘Support’ under the ‘Help’ button on the WWG mobile app or wwdb.com. If you have questions regarding the benefits, you should contact your upline Platinum
      </p>
    </div>
    <div className={styles.footer}>
      <button className={styles.btnNo}>No</button>
      <button className={styles.btnYes}>Yes</button>
    </div>
  </React.Fragment>
})
