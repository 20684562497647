//Extract first name from the full name
export const extractFirstName = (name) => {
  if (!name) return "";
  const firstName = name.split(" ")[0];
  // Capitalize each part of the first name, including hyphenated names
  return firstName
    .toLowerCase()
    .split("-")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join("-");
};
