import React from "react";
import styles from "./SpecialModal.module.css";
import CloseBtn from "assets/icons/Close_Button_Modal.svg";
import Button from "components/Button";

interface ISpecialModalProps {
  title: string;
  isOpen: boolean;
  children: React.ReactNode;
  buttonText: string;
  onClose?: () => void;
  onClick?: () => void;
}

export const SpecialModal = (props: ISpecialModalProps) => {
  const { title, isOpen, children, onClose, buttonText, onClick } = props;

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        {onClose && (
          <div className={styles.closeButton} onClick={onClose}>
            <img src={CloseBtn} alt={"Close Btn"} />
          </div>
        )}
        <div className={styles.header}>{title}</div>
        <div className={styles.body}>
          <div className={styles.content}>{children}</div>
          <div className={styles.button}>
            <Button
              title={buttonText}
              onClick={onClick}
              className={styles.specialBtn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialModal;
