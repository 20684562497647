import PropTypes from "prop-types";
import styles from "./RegisteredEagle.module.css";
import { IconDelete } from "@wwg/icons";

/**
 * This is an Interactive Component called RegisteredEagle, it displays information for a single IBO and allow the user to unregister
 * them by clicking the Delete button. This is a single list item to be used in a larger list of multiple IBOs who have been registered.
 */
export function RegisteredEagle({
  id,
  name,
  eventName,
  qualification,
  onDelete,
}) {
  return (
    <div
      data-testid={`registeredEagle${id}`}
      className={styles.registeredEagle}
    >
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <p className={styles.nameText}>{name}</p>
          <p className={styles.subText}>{eventName}</p>
          <p className={styles.subText}>{qualification}</p>
        </div>
        <div className={styles.rightSide}>
          <button
            aria-label={`Delete Button for Registered Eagle #${id}`}
            className={styles.deleteButton}
            onClick={onDelete}
          >
            <span className={styles.icon}>
              <IconDelete />
            </span>{" "}
            DELETE
          </button>
        </div>
      </div>
    </div>
  );
}

RegisteredEagle.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  qualification: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};
