import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { ChevronDown } from "react-ikonate";
import { ChevronUp } from "react-ikonate";
import styles from "./Select.module.css";
import classNames from "classnames";

/**
 *
 * Interactive component that displays a dropdown menu and allows selection
 *
 */

export function Select({
  options,
  onChange,
  placeholder,
  disabled,
  dropdownRight = false,
  className,
  isScrollable,
  isStateSelect,
}) {
  const [dropdownDisplay, changeDropDownDisplay] = useState(false);
  const [selectedOption, setSelectedOption] = useState(placeholder);
  const [isValid, setIsValid] = useState(false);

  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const wrapperRef = useRef(null);
  const filterInputRef = useRef(null);
  const dropdownRef = useRef(null);

  const allOptions = useMemo(
    () => (isStateSelect ? [placeholder, ...options] : options),
    [isStateSelect, options, placeholder]
  );

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    setSelectedOption(placeholder);
  }, [placeholder]);

  useEffect(() => {
    setIsValid(selectedOption !== placeholder && selectedOption !== "");
  }, [selectedOption, placeholder]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          changeDropDownDisplay(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (dropdownDisplay) {
      let filtered = allOptions.filter((option) =>
        option.display?.toLowerCase().startsWith(searchTerm?.toLowerCase())
      );

      if (filtered.length > 0) {
        setSelectedIndex(allOptions.indexOf(filtered[0]));
      } else {
        setSelectedIndex(null);
      }

      setFilteredOptions(filtered);
    } else {
      setSearchTerm("");
      setFilteredOptions(allOptions);
      setSelectedIndex(null);
    }
  }, [searchTerm, dropdownDisplay, allOptions]);

  useEffect(() => {
    if (dropdownDisplay && dropdownRef.current && selectedIndex !== null) {
      const selectedOption = dropdownRef.current.children[selectedIndex];
      if (selectedOption) {
        selectedOption.scrollIntoView({
          behavior: "instant",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, [dropdownDisplay, selectedIndex]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (!dropdownDisplay) return;
      const key = event.key?.toLowerCase();
      setSearchTerm(key);
      const index = allOptions.findIndex((option) =>
        option.display?.toLowerCase().startsWith(key)
      );
      if (index !== -1) {
        setSelectedIndex(index);
        setSelectedOption(allOptions[index].display);
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [dropdownDisplay, allOptions]);

  const handleOptionSelect = (option) => {
    // setSelectedOption(option);
    // onChange(option);
    // setSelectedOption(option.state);
    // onChange(option.abbreviation);

    setSelectedOption(option.display);
    onChange(option.value);
    changeDropDownDisplay(false);
  };

  const handleHover = () => {
    setSelectedIndex(null);
  };

  return (
    <>
      {isStateSelect ? (
        <div
          ref={wrapperRef}
          className={styles.container}
          onClick={() => changeDropDownDisplay(!dropdownDisplay)}
          disabled={disabled ? styles.disabled : ""}
        >
          <button
            className={classNames(
              styles.selectDropdown,
              className,
              dropdownDisplay
                ? styles.selectDropdownOpened
                : styles.selectDropdown
            )}
            onClick={() => {
              changeDropDownDisplay(!dropdownDisplay);
              if (!dropdownDisplay && filterInputRef.current) {
                filterInputRef.current.focus();
              }
            }}
            disabled={disabled}
          >
            <div className={styles.selectField}>
              <span className={styles.placeholder}>{selectedOption}</span>
              <span className={styles.selectChevron}>
                <ChevronUp fontSize="1em" />
                <ChevronDown fontSize="1em" />
              </span>
            </div>
          </button>
          {dropdownDisplay && (
            <div
              ref={dropdownRef}
              className={classNames(
                dropdownRight
                  ? styles.selectOptionsRight
                  : styles.selectOptions,
                { [styles.scrollable]: isScrollable }
              )}
              style={{ width: wrapperRef.current?.offsetWidth }}
            >
              {filteredOptions.map((option, index) => (
                <button
                  key={option.value}
                  className={classNames(
                    styles.selectMenuOptions,
                    selectedIndex === index ? styles.selected : ""
                  )}
                  onClick={() => handleOptionSelect(option)}
                  onMouseEnter={() => handleHover()}
                >
                  {/* {selectedOption === option && <span>✔️ </span>} */}
                  {option.display}

                  {/* {option.state} */}
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div ref={wrapperRef} className={styles.container}>
          <button
            className={classNames(
              styles.selectDropdown,
              className,
              dropdownDisplay
                ? styles.selectDropdownOpened
                : styles.selectDropdown
            )}
            onClick={() => changeDropDownDisplay(!dropdownDisplay)}
            disabled={disabled}
          >
            <div className={styles.selectField}>
              <span className={styles.placeholder}>{placeholder}</span>
              <span className={styles.selectChevron}>
                <ChevronUp fontSize="1em" />
                <ChevronDown fontSize="1em" />
              </span>
            </div>
          </button>
          {dropdownDisplay && (
            <div
              className={classNames(
                dropdownRight
                  ? styles.selectOptionsRight
                  : styles.selectOptions,
                { [styles.scrollable]: isScrollable }
              )}
              style={{ width: wrapperRef.current?.offsetWidth }}
            >
              {options.map((option, index) => (
                <button
                  className={styles.selectMenuOptions}
                  key={option.value}
                  onClick={() => handleOptionSelect(option)}
                >
                  {option.display}
                  {/* {selectedOption === option && <span>✔️ </span>} */}
                  {/* {option.state} */}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

Select.propTypes = {
  /**
   * Array of the options for the dropdown
   */
  options: PropTypes.array.isRequired,
  /**
   * Function that handles the user changing the option
   */
  onChange: PropTypes.func.isRequired,
  /**
   * String that contains the default value shown in the dropdown
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * Boolean that disables the dropdown
   */
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  disabled: false,
};
