import React from "react";
import { FC } from "react";
import EventIcon from "components/EventIcon";
import TicketPurchaseItem from "components/TicketPurchaseItem";
import styles from "./EventTicketItem.module.css";
import { EventInfo, EventItem, UserIBO } from "stores/homesiteInterface";
// import { UserIBO } from "stores/interfaces.types";

interface IProps {
  // event: EventInfo;
  event: EventItem;
  users: UserIBO[];
  onClickAdd: Function;
  onClickPurchased: Function;
  onClickCancel: Function;
}

export const EventTicketItem: FC<IProps> = ({
  event,
  users,
  onClickAdd,
  onClickPurchased,
  onClickCancel,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.left}>
        <EventIcon events={event} />
      </div>
      <div className={styles.right}>
        {users?.map((user, index) => {
          const userTicket = user.tickets.find(
            (t) =>
              t.eventId === event.eventId && t.eventTicketId === event.ticketId
          );

          return (
            <TicketPurchaseItem
              key={index}
              user={user}
              ticket={userTicket}
              onClickAdd={(eventSet) => onClickAdd(eventSet, user)}
              onClickPurchased={() => onClickPurchased(user)}
              onClickCancel={() => onClickCancel(user)}
            />
          );
        })}
      </div>
    </div>
  );
};
