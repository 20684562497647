import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./CardList.module.css";
import CardItem from "../CardItem";

/*
 * Displays info about a credit card with options to set as primary.
 */

export function CardList({ cards = [], onSelect }) {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {cards.map((card) => (
          <div className={styles.cardItem} key={card.membershipCreditCardId}>
            <CardItem
              cardInfo={card}
              isPrimary={card.isPrimary}
              // onSelect={() => onSelect(card.membershipCreditCardId)}
              onSelect={onSelect}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

CardList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
};

CardList.defaultProps = {
  cards: [],
};

export default CardList;
