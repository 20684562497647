//Temp States Data

export const stateOptions = [
  { stateId: 1, abbreviation: "AL", state: "Alabama", countryId: 220 },
  { stateId: 2, abbreviation: "AK", state: "Alaska", countryId: 220 },
  { stateId: 3, abbreviation: "AZ", state: "Arizona", countryId: 220 },
  { stateId: 4, abbreviation: "AR", state: "Arkansas", countryId: 220 },
  { stateId: 5, abbreviation: "CA", state: "California", countryId: 220 },
  { stateId: 6, abbreviation: "CO", state: "Colorado", countryId: 220 },
  { stateId: 7, abbreviation: "CT", state: "Connecticut", countryId: 220 },
  { stateId: 8, abbreviation: "DE", state: "Delaware", countryId: 220 },
  { stateId: 9, abbreviation: "FL", state: "Florida", countryId: 220 },
  { stateId: 10, abbreviation: "GA", state: "Georgia", countryId: 220 },
  { stateId: 11, abbreviation: "HI", state: "Hawaii", countryId: 220 },
  { stateId: 12, abbreviation: "ID", state: "Idaho", countryId: 220 },
  { stateId: 13, abbreviation: "IL", state: "Illinois", countryId: 220 },
  { stateId: 14, abbreviation: "IN", state: "Indiana", countryId: 220 },
  { stateId: 15, abbreviation: "IA", state: "Iowa", countryId: 220 },
  { stateId: 16, abbreviation: "KS", state: "Kansas", countryId: 220 },
  { stateId: 17, abbreviation: "KY", state: "Kentucky", countryId: 220 },
  { stateId: 18, abbreviation: "LA", state: "Louisiana", countryId: 220 },
  { stateId: 19, abbreviation: "ME", state: "Maine", countryId: 220 },
  { stateId: 20, abbreviation: "MD", state: "Maryland", countryId: 220 },
  { stateId: 21, abbreviation: "MA", state: "Massachusetts", countryId: 220 },
  { stateId: 22, abbreviation: "MI", state: "Michigan", countryId: 220 },
  { stateId: 23, abbreviation: "MN", state: "Minnesota", countryId: 220 },
  { stateId: 24, abbreviation: "MS", state: "Mississippi", countryId: 220 },
  { stateId: 25, abbreviation: "MO", state: "Missouri", countryId: 220 },
  { stateId: 26, abbreviation: "MT", state: "Montana", countryId: 220 },
  { stateId: 27, abbreviation: "NE", state: "Nebraska", countryId: 220 },
  { stateId: 28, abbreviation: "NV", state: "Nevada", countryId: 220 },
  { stateId: 29, abbreviation: "NH", state: "New Hampshire", countryId: 220 },
  { stateId: 30, abbreviation: "NJ", state: "New Jersey", countryId: 220 },
  { stateId: 31, abbreviation: "NM", state: "New Mexico", countryId: 220 },
  { stateId: 32, abbreviation: "NY", state: "New York", countryId: 220 },
  { stateId: 33, abbreviation: "NC", state: "North Carolina", countryId: 220 },
  { stateId: 34, abbreviation: "ND", state: "North Dakota", countryId: 220 },
  { stateId: 35, abbreviation: "OH", state: "Ohio", countryId: 220 },
  { stateId: 36, abbreviation: "OK", state: "Oklahoma", countryId: 220 },
  { stateId: 37, abbreviation: "OR", state: "Oregon", countryId: 220 },
  { stateId: 38, abbreviation: "PA", state: "Pennsylvania", countryId: 220 },
  { stateId: 39, abbreviation: "RI", state: "Rhode Island", countryId: 220 },
  { stateId: 40, abbreviation: "SC", state: "South Carolina", countryId: 220 },
  { stateId: 41, abbreviation: "SD", state: "South Dakota", countryId: 220 },
  { stateId: 42, abbreviation: "TN", state: "Tennessee", countryId: 220 },
  { stateId: 43, abbreviation: "TX", state: "Texas", countryId: 220 },
  { stateId: 44, abbreviation: "UT", state: "Utah", countryId: 220 },
  { stateId: 45, abbreviation: "VT", state: "Vermont", countryId: 220 },
  { stateId: 46, abbreviation: "VA", state: "Virginia", countryId: 220 },
  { stateId: 47, abbreviation: "WA", state: "Washington", countryId: 220 },
  { stateId: 48, abbreviation: "WV", state: "West Virginia", countryId: 220 },
  { stateId: 49, abbreviation: "WI", state: "Wisconsin", countryId: 220 },
  { stateId: 50, abbreviation: "WY", state: "Wyoming", countryId: 220 },
];
