import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./HomeSite.module.css";
import CountDown from "components/CountDown";
import { PATHS } from "utils";
import FunctionInfoCard from "components/FunctionInfoCard";
import Notification from "components/Notification";
import HomeSite_Icon from "assets/icons/HomeSite_Icon.svg";
import Map_Icon from "assets/icons/Map_Icon.svg";
import EventsList from "components/EventsList";
import LocationModal from "components/LocationModal";
import { TicketStatus } from "stores/interfaces.types";
import { EventInfo, EventItem } from "stores/homesiteInterface";
import CartPopup from "components/CartPopup";
import { useUser, useCart, useCartAddPost } from "../../stores/CartContext";
import Footer from "components/Footer";
import Button from "components/Button";
import { extractTimeAndTimezone } from "../../utils/formatDate";
import { useHomeSite } from "./useHomeSite";
// import { useCartAddPost } from "./useCartAddPost";

import Loader from "components/Loader";

interface IProps {}

const defaultEvent: EventInfo = {
  eventId: 0,
  eventSet: [],
  pinNotes: [],
  users: [],
};

// Function to update the eventTicketsList based on ticket status change
export const HomeSite: React.FC<IProps> = (props) => {
  const history = useHistory();
  const { addToCart } = useCart();
  const {
    data: homesiteData,
    isLoading: homesiteLoading,
    isSuccess,
    refetch,
  } = useHomeSite();

  const {
    data: userData,
    isLoading: userLoading,
    isSuccess: userIsSuccess,
    isError: userIsError,
    error: userError,
    refetch: userRefetch,
  } = useUser();

  const cartAddPost = useCartAddPost();

  const [isOpen, setIsOpen] = useState(false);
  const [isAddToCartOpen, setIsAddToCartOpen] = useState(false);
  const [isAddToCartVisible, setIsAddToCartVisible] = useState(false);
  const [isFooterOpen, setIsFooterOpen] = useState(false);

  //holds all selected tickets (eventId, ticketId, userId)
  const [selectedItems, setSelectedItems] = useState([]);
  //temporary state for holding full api call
  const [tempData, setTempData] = useState(null);
  //temp state for holding user api call
  const [apiUsers, setApiUsers] = useState(null);

  const [ticketCount, setTicketCount] = useState(1);
  const [eventCount, setEventCount] = useState(1);
  const [total, setTotal] = useState(0);

  const [currencyCodes, setCurrencyCodes] = useState([]);

  //react-query data set
  useEffect(() => {
    if (isSuccess && homesiteData) {
      setTempData(homesiteData);
    }
  }, [isSuccess, homesiteData]);

  useEffect(() => {
    if (userIsSuccess && userData) {
      setApiUsers(userData);
    }
  }, [userIsSuccess, userData]);

  //manipulated api data
  //used specifically for the functionInfoCard
  // const [subEventsHolder, setsubEventsHolder] = useState([]);
  //manipulated notes for pinNotes (concatenated via string)
  const [pinNotesHolder, setPinNotesHolder] = useState("");
  //holds a full list of subevents (manipulated to conform to necessary existing data structure, holds all values from response(some concatenated))
  const [eventsWithIbo, setEventsWithIbo] = useState([]);
  // const [eventsWithIbo, setEventsWithIbo] = useState<EventInfo | null>(
  //   defaultEvent
  // );

  //COMMENTED TEMP
  // useEffect(() => {
  //   if (isAddToCartOpen && selectedItems.length > 0) {
  //     cartAddPostMutate.mutate(selectedItems);
  //     //refetch users data
  //     // userRefetch();

  //     //clear the temp cart (selected items, ticketStatus.Selected)
  //     setSelectedItems([]);
  //   }
  // }, [selectedItems, isAddToCartOpen]);

  // useEffect(() => {
  //   if (isAddToCartOpen && selectedItems.length > 0) {
  //     //this trims the priceStr value from the selected items objects.
  //     //because the selected items needs a price value within the state to calculate the cost as each page might not have the same values to grab the price from
  //     const postData = selectedItems.map((item) => ({
  //       ticketId: item.ticketId,
  //       userId: item.userId,
  //       eventId: item.eventId,
  //     }));
  //     cartAddPostMutate.mutate(postData);
  //     //refetch users data
  //     userRefetch().then((res) => {
  //       setApiUsers(res.data);
  //     });

  //     //clear the temp cart (selected items, ticketStatus.Selected)
  //     setSelectedItems([]);
  //   }
  // }, [selectedItems, isAddToCartOpen]);

  //use effect to call functions that set values to their existing data structure
  useEffect(() => {
    if (tempData && tempData.eventSet && apiUsers) {
      // handleSubEventsSet(tempData);
      handleIboSet(tempData.eventSet);
    }
    if (tempData && tempData.pinNotes) {
      handlePinNotesSet(tempData.pinNotes);
    }
  }, [tempData, apiUsers]);

  //used for notification (pin notes)
  const handlePinNotesSet = (oldPinNotes) => {
    let retString = "";

    oldPinNotes.forEach((string) => {
      retString += string + ". ";
    });

    retString = retString.trim();
    setPinNotesHolder(retString);
  };

  //used to transform array into a single string used in children
  //does not include venueName
  const concatenateLocationToString = (locationArray) => {
    let retString = "";

    for (const key in locationArray) {
      if (locationArray.hasOwnProperty(key)) {
        if (key !== "venueName") {
          retString += locationArray[key] + " ";
        }
      }
    }

    retString = retString.trim();
    return retString;
  };

  const handleIboSet = (events) => {
    // If the ibos array is missing from the specific event
    let retArray = [];

    // Append the ibos to the event
    events.forEach((subEvent) => {
      let subEventDescription = "";
      if (subEvent.notes) {
        subEvent.notes.forEach((item) => {
          subEventDescription += item.note + ". ";
        });
        subEventDescription = subEventDescription.trim(); // Clean up trailing spaces
      }

      // let newIboStructure = apiUsers.map((user) => {
      //   // Find matching tickets for the subEvent
      //   const matchingTickets = user.tickets.filter(
      //     (ticket) =>
      //       ticket.eventId === subEvent.eventId &&
      //       ticket.eventTicketId === subEvent.ticketId
      //   );

      //   return {
      //     ...user,
      //   };
      // });

      // Construct the event object with all necessary information
      let eventToPass = {
        eventId: subEvent.eventId,
        ticketId: subEvent.ticketId,
        title: subEvent.title,
        speaker: subEvent.speaker,
        location: subEvent.location,
        startsOn: subEvent.startsOn, // used as startTime
        endsOn: subEvent.endsOn, // used as endTime
        description: subEventDescription,
        note: subEventDescription, // same as description???
        isVirtual: subEvent.isVirtual,
        isInPerson: subEvent.isInPerson,
        currencyCode: subEvent.currencyCode,
        isAvailableForPurchase: subEvent.isAvailable,
        // users: newIboStructure,
        price: subEvent.priceStr,
      };

      retArray.push(eventToPass);
    });

    setEventsWithIbo(retArray);
  };

  //formats the date from a date object to a string
  // const dateToFormatted = (date) => {
  //   let dateFormatted = extractDate(date);
  //   let timeFormatted = extractTime(date);

  //   let finalDateFormat = `${dateFormatted} ${timeFormatted}`;
  //   return finalDateFormat;
  // };

  //gets currency symbol based off of passed currrencyCode value (e.g. 'USD', 'JPY', 'EUR', etc)
  //without using regex.
  const getCurrencySymbol = (currCode) => {
    //only english users?
    const formatCurrency = new Intl.NumberFormat("en", {
      style: "currency",
      currency: currCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const parts = formatCurrency.formatToParts(0);

    const currencySymbol = parts.find((part) => part.type === "currency").value;

    return currencySymbol;
  };

  //Update ticketCount, total

  useEffect(() => {
    if (!selectedItems || selectedItems.length === 0) {
      setEventCount(0);
      setTicketCount(0);
      setTotal(0);
      setCurrencyCodes([]);
      setIsFooterOpen(false);
      return;
    }

    // Flatten tickets and filter those with TicketStatus.CART
    const updatedTicketList = selectedItems
      .flatMap((user) => user.tickets || [])
      .filter((ticket) => ticket.ticketStatusId === TicketStatus.CART);

    // Calculate the ticket count
    const newTicketCount = updatedTicketList.length;

    // Calculate unique event count using eventId
    const uniqueEventIds = new Set(
      updatedTicketList.map((ticket) => ticket.eventId)
    );
    const newEventCount = uniqueEventIds.size;

    // Calculate total and currency codes
    let newTotal = 0;
    const currencyCodesSet = new Set();

    updatedTicketList.forEach((ticket) => {
      const price = ticket.amount || 0;
      newTotal += price;
      if (ticket.currencyCode) {
        currencyCodesSet.add(ticket.currencyCode);
      }
    });

    // Set state values
    setTicketCount(newTicketCount);
    setEventCount(newEventCount);
    setTotal(newTotal);
    setCurrencyCodes(Array.from(currencyCodesSet));

    // Open the footer if there are tickets selected
    setIsFooterOpen(newTicketCount > 0);
  }, [selectedItems]);

  //Triggers the add button in user

  const onClickAdd = (eventSet, user) => {
    const amount = parseFloat(eventSet.price) || 0;

    // Create the new ticket object
    const newTicket = {
      companyId: user?.tickets[0]?.companyId || null,
      userId: user.contactId,
      eventId: eventSet.eventId,
      eventTicketId: eventSet.ticketId,
      ticketStatusId: TicketStatus.CART,
      statusStr: "CART",
      title: eventSet.title || "Local Function Ticket",
      type: eventSet.type || "",
      startsOn: eventSet.startsOn || null,
      endsOn: eventSet.endsOn || null,
      amount: amount,
      taxAmount: 0,
      taxRate: 0,
    };

    setSelectedItems((prevItems) => {
      // Check if the user already exists in selectedItems
      const existingUserIndex = prevItems.findIndex(
        (item) => item.userId === user.userId
      );

      if (existingUserIndex !== -1) {
        // If user already exists, add the new ticket to their tickets array
        return prevItems.map((item, index) => {
          if (index === existingUserIndex) {
            return {
              ...item,
              tickets: [...item.tickets, newTicket],
            };
          }
          return item;
        });
      } else {
        // If user does not exist, add a new user entry with the new ticket
        const newUserEntry = {
          ...user, // Spread user properties directly
          tickets: [newTicket], // Initialize tickets array with the new ticket
        };
        return [...prevItems, newUserEntry];
      }
    });
  };

  //Triggers the cancel button in user

  const onClickCancel = (eventSet, user) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = prevSelectedItems
        .map((item) => {
          if (item.userId === user.userId) {
            const updatedTickets = item.tickets.filter(
              (ticket) => ticket.eventTicketId !== eventSet.ticketId
            );

            if (updatedTickets.length === 0) {
              return null; // Remove user if no tickets remain
            }

            return {
              ...item,
              tickets: updatedTickets,
            };
          }
          return item;
        })
        .filter(Boolean);

      return updatedSelectedItems;
    });
  };

  //#endregion

  // Async function to add items to cart

  async function handleAddToCart() {
    if (selectedItems.length === 0) return;

    const newCartItems = selectedItems.flatMap((item) =>
      (item.tickets || [])
        .filter((ticket) => ticket.eventId && item.contactId)
        .map((ticket) => ({
          ticketId: ticket.eventTicketId,
          contactId: item.contactId,
          eventId: ticket.eventId,
          isTandem: false,
          amount: ticket.amount || 0,
        }))
    );

    if (newCartItems.length === 0) {
      return;
    }

    try {
      await cartAddPost.mutateAsync(newCartItems);
      setSelectedItems([]);
      setIsAddToCartOpen(true);
      setTimeout(() => {
        setIsAddToCartOpen(false);
        history.push(PATHS.CART);
      }, 700);
    } catch (error) {
      console.error("Error adding items to cart:", error);
    }
  }

  const onRedirectToCartCheckout = () => {
    history.push(PATHS.CART_CHECKOUT);
  };

  const handleTicketAdded = () => {
    setIsAddToCartVisible(true);
  };

  //used to test time countdown (2 min from now)
  // const currentTime = new Date().toISOString();
  // const now = new Date();
  // const twoMinutesFromNow = new Date(now.getTime() + 2 * 60 * 1000);
  // const isoString = twoMinutesFromNow.toISOString();

  // (tempData?.eventSet?.[0]?.startsOn)

  const startTime = tempData
    ? extractTimeAndTimezone(tempData?.eventSet?.[0]?.startsOn)
    : "";
  const endTime = tempData
    ? extractTimeAndTimezone(tempData?.eventSet?.[0]?.endsOn)
    : "";

  if (homesiteLoading || userLoading) {
    return (
      <div>
        <Loader color={"#415364"} size={85} />
      </div>
    );
  }

  // if (homesiteLoading) {
  //   return (
  //     <div>
  //       <Loader color={"#415364"} size={85} />
  //     </div>
  //   );
  // }

  return (
    <div className={styles.container}>
      {/* because the temp data is the indication that we got a successful response from react-query */}

      {tempData && eventsWithIbo?.length > 0 && (
        <>
          {/* 
            at the time of building this the response form the api was onl a single Object.
            If the response is an array of objects, then a loop through the objects is necessary here
            Note, that a call of the funciton handleSubEventsSet(item[index]) before each render of the
            function info card would be neccessary to populate the events object that is paassed to the funciton info card
            
            as well as the call of the function handlePinNotesSet(item[index]) to render pin note for event
            
            i.e. 
            
            tempData.map((item, index) => (
              handleSubEventsSet(item[index])
              handlePinNotesSet(item[index])
              or something like this
              
              REST OF WHOLE BODY
              ))
              
              also note the use of eventsWithIbo could be used here (different keys to match child components)
          */}
          <div className={styles.header}>
            <div className={styles.logo}>
              <img src={HomeSite_Icon} alt="HomeSite Icon" />
            </div>
            <div className={styles.countMapContainer}>
              <div className={styles.countDown}>
                <div className={styles.location}>
                  {eventsWithIbo[0].location?.line1}
                </div>

                <CountDown
                  // date="2024-07-04T01:00:00.000Z"
                  date={eventsWithIbo[0].startsOn || ""}
                  // date={eventsWithIbo[2].startTime}

                  //mock 2 minutes from now to test countdown functionality (uncomment date={isoString})
                  // date={isoString}
                  onSaleDate={""}
                  // location=""
                />
              </div>
              <div className={styles.map} onClick={() => setIsOpen(true)}>
                <img
                  src={Map_Icon}
                  alt="Map Icon"
                  className={styles.map}
                  onClick={() => setIsOpen(true)}
                />
              </div>
            </div>
          </div>
          <div className={styles.body}>
            <FunctionInfoCard
              //setting first item in event set as the main event for now
              //eventually needs to be adjusted based on popularity etc.
              priceStr={`${getCurrencySymbol(eventsWithIbo[0].currencyCode)}${
                eventsWithIbo[0].price
              }`}
              sessions={[]}
              speakerInfo={{
                //   image: eventsWithIbo[0].speaker.src,
                //   firstName: eventsWithIbo[0].speaker.name,
                // lastName: "MAIN EVENT",

                name: eventsWithIbo[0].speaker.name,
                image: eventsWithIbo[0].speaker.src,
              }}
              eventInfo={eventsWithIbo.map((event) => ({
                title: event.title,
                startTime: startTime.split(" ")[0],
                endTime: `${endTime.split(" ")[0]} ${endTime
                  .split(" ")[1]
                  .toLowerCase()}`,
                description: event.description || "No description available",
              }))}
            />

            <div className={styles.noti}>
              <Notification
                className="#243746"
                notification={pinNotesHolder}
                img=""
              />
            </div>

            {/* {eventsWithIbo && ( */}
            <EventsList
              eventTickets={eventsWithIbo}
              users={userData}
              onClickAdd={(eventSet, user) => onClickAdd(eventSet, user)}
              onClickCancel={(eventSet, user) => {
                onClickCancel(eventSet, user);
              }}
              onClickPurchased={() => {}}
            />
          </div>

          {isOpen && (
            <LocationModal
              venueName={eventsWithIbo[0].location.venueName}
              address={concatenateLocationToString(eventsWithIbo[0].location)}
              onChangeVisible={(visible) => setIsOpen(visible)}
            />
          )}

          {isFooterOpen && (
            <>
              <Footer isOpen={isFooterOpen} onClose={() => {}}>
                <div className={styles.footerAddToCart}>
                  <div className={styles.cartPrice}>
                    <b>{eventCount}</b> Events <b>x {ticketCount}</b> Tickets ={" "}
                    <b>
                      {Array.from(currencyCodes).join(", ")} ${total.toFixed(2)}
                    </b>
                  </div>
                  <div className={styles.btn}>
                    <Button
                      title="Add to Cart"
                      onClick={handleAddToCart}
                      className={styles.add}
                    />
                    <Button
                      title="Buy Now"
                      onClick={onRedirectToCartCheckout}
                      className={styles.buy}
                    />
                  </div>
                </div>
              </Footer>

              <CartPopup isOpen={isAddToCartOpen} />
            </>
          )}
        </>
      )}
    </div>
  );
};
