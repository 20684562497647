export const DEMO_COMPONENTS = "/demo-components";
export const HOME = "/home";
export const MAJOR_FUNCTIONS = "/major-functions";
export const LOCAL_FUNCTIONS = "/local-functions";
export const LOCAL_FUNCTION_INFO = "/local-function-info";
export const LOCAL_FUNCTION_EVENT = "/local-function-event";
export const CART = "/cart";
export const HOMESITE = "/home-site";
export const HOMESITE_NO_MEETING = "/home-site-no-meeting";
export const WHAT_NEW_HOMESITE = "/what-new-home-site";
export const CART_CHECKOUT = "/cart-checkout";
export const TERMS_CONDITIONS = "/terms-conditions";
export const HOMESITE_NO_SETUP = "/home-site-no-set-up";
export const LOCAL_FUNCTION_MEETING = "/local-function-event/details/:eventId";
