import React, { useState, useEffect } from "react";
import styles from "./LocalFunctionsEvent.module.css";
import { useHistory, useParams, useLocation } from "react-router-dom";
import CountDown from "components/CountDown";
import { Alert } from "../components/Alert";
import Notification from "components/Notification";
import { useCart } from "stores/CartContext";
import {
  EventItem,
  EventInfo,
  LocalFunctionsData,
  Tickets,
  TicketStatus,
  LocationState,
  UserIBO,
  EventSessions,
} from "stores/interfaces.types";
import LocalFunctionEventsList from "components/LocalFunctionComponents/LocalFunctionEventsList";
import LocalFunctionInfoCard from "components/LocalFunctionComponents/LocalFunctionInfoCard";
import Map_Icon from "assets/icons/Map_Icon.svg";
import LocationModal from "components/LocationModal";
import { extractTimeAndTimezone, formatDateRange } from "utils/formatDate";
import { formatCurrency } from "utils/formatCurrency";
import Footer from "components/Footer";
import Button from "components/Button";
import { PATHS } from "utils";
import CartPopup from "components/CartPopup";
import ArrowLeftIcon from "./Arrow_Left.svg";
import Loader from "components/Loader";
// import { useUser } from "stores/useUser";
import { useCartAddPost, useUser } from "stores/CartContext";

// interface ILocalFunctionsEventProps {
//   onAddToCart: (item: any) => void;
// }

interface ILocalFunctionsEventProps {
  // meetings: Meeting[];
  // items: EventItem[];
}

interface RouteParams {
  eventId: string;
}

const defaultEvent: EventItem = {
  eventId: 0,
  title: "",
  subtitle: "",
  isAvailable: false,
  startsOn: "",
  endsOn: "",
  isInPerson: false,
  isVirtual: false,
  location: {
    city: "",
    country: "",
    line1: "",
    line2: "",
    state: "",
    zip: "",
    venueName: "",
  },
  notes: [],
  speaker: {
    name: "",
    src: "",
  },
  eventSessions: [],
  eventSets: [],
  tickets: [],
  users: [],
};

// const userData: UserIBO[] = [
//   {
//     contactId: 1463,
//     name: "RANDY JASSMAN",
//     userId: 99,
//     src: "https://wwgusdev.blob.core.windows.net/wwg-id-card/99",
//     loa: {
//       pinLevel: 4,
//       sponsor: 1002340,
//       platinum: 1002340,
//       emerald: 1002340,
//       diamond: 1002340,
//     },
//     tickets: [
//       {
//         amount: 40,
//         attendeeTicketId: 28,
//         commonCartTypeId: 4,
//         commonItemId: 18,
//         companyId: "1478107",
//         eventId: 133810,
//         eventTicketId: 139127,
//         eventType: "Regional Rally™",
//         title: "Virtual - Seminar/Rally",
//         startsOn: "2023-06-17T07:00:00.000Z",
//         endsOn: null,
//         isInPerson: true,
//         isVirtual: false,
//         location: {
//           line1: "3005 SOUTH FORK BLVD",
//           line2: null,
//           city: "IDAHO FALLS",
//           state: "ID",
//           zip: "83402",
//           country: "US",
//           venueName: "HOLIDAY INN & SUITES IDAHO",
//         },
//         statusStr: "INCLUDED",
//         subTitle: "Regional Rally™",
//         taxAmount: 0,
//         taxRate: 0,
//         template: "Free Enterprise",
//         ticketStatusId: 5,
//         type: "LOCAL",
//         userId: 99,
//       },
//     ],
//   },
//   {
//     contactId: 1464,
//     name: "RAYE-LYNNS JASSMAN",
//     userId: 100,
//     src: null,
//     loa: {
//       pinLevel: 4,
//       sponsor: 1002340,
//       platinum: 1002340,
//       emerald: 1002340,
//       diamond: 1002340,
//     },
//     tickets: [
//       // {
//       //   amount: 20,
//       //   attendeeTicketId: 46,
//       //   commonCartTypeId: 3,
//       //   commonItemId: 14,
//       //   companyId: "1478107",
//       //   eventId: 1,
//       //   eventTicketId: 11,
//       //   eventType: "Free Enterprise",
//       //   title: "Seminar",
//       //   startsOn: "2024-10-25T17:54:09.385Z",
//       //   endsOn: "2024-10-25T18:54:09.385Z",
//       //   isInPerson: true,
//       //   isVirtual: true,
//       //   location: {
//       //     line1: "Test Town, WA",
//       //     line2: "Test Line 2",
//       //     city: "Spokane",
//       //     state: "WA",
//       //     zip: "99999",
//       //     country: "US",
//       //     venueName: "Test Arena",
//       //   },
//       //   statusStr: "PURCHASED",
//       //   subTitle: "",
//       //   taxAmount: 0,
//       //   taxRate: 0,
//       //   template: "Free Enterprise",
//       //   ticketStatusId: 3,
//       //   type: "MAJOR",
//       //   userId: 100,
//       // },
//     ],
//   },
// ];

export const LocalFunctionsEvent = React.memo(
  (props: ILocalFunctionsEventProps) => {
    // const { items } = props;
    // const location = useLocation();
    const history = useHistory();
    const { addToCart } = useCart();
    const { eventId } = useParams<RouteParams>();
    const location = useLocation<LocationState>();
    // const { data, isLoading } = useLocalFunctions();
    const { data: userData, isLoading: userLoading } = useUser();
    const cartAddPost = useCartAddPost();

    const [isOpen, setIsOpen] = useState(false);
    const [isAddToCartOpen, setIsAddToCartOpen] = useState(false);
    const [isFooterOpen, setIsFooterOpen] = useState(false);
    const [currencyCodes, setCurrencyCodes] = useState([]);

    // const [eventDetails, setEventDetails] = useState(null);

    // const [eventSessions, setEventSessions] = useState<EventSessions[]>([]);

    const [eventDetails, setEventDetails] = useState<EventItem | null>(
      defaultEvent
    );

    const [eventSessions, setEventSessions] = useState<EventSessions[]>([]);

    const [selectedItems, setSelectedItems] = useState([]);

    const [items, setItems] = useState<EventItem[]>(
      location.state?.items || []
    );

    const [ticketCount, setTicketCount] = useState(1);
    const [eventCount, setEventCount] = useState(1);

    const [total, setTotal] = useState(0);

    //Include only the eventSessions data
    useEffect(() => {
      if (!items || !eventId) return;

      // Find the event with the specified eventId
      const event = items.find(
        (item: EventItem) => item.eventId === parseInt(eventId)
      );

      if (event) {
        const eventInfo = { ...event };
        setEventDetails(eventInfo);
        setEventSessions(event.eventSessions);
      }
    }, [eventId, items, userData, history]);

    //With users
    useEffect(() => {
      if (userData && userData.length > 0) {
        setEventDetails((prevDetails) => {
          const updatedDetails = {
            ...prevDetails,
            users: [...userData],
          };

          return updatedDetails;
        });
      }
    }, [userData]);

    //Adding to cart
    // useEffect(() => {
    //   if (isAddToCartOpen && selectedItems.length > 0) {
    //     selectedItems.forEach((item) => addToCart(item));
    //     setSelectedItems([]);
    //   }
    // }, [selectedItems, isAddToCartOpen, addToCart]);

    //Update ticketCount, total

    useEffect(() => {
      if (!selectedItems || selectedItems.length === 0) {
        setEventCount(0);
        setTicketCount(0);
        setTotal(0);
        setCurrencyCodes([]);
        setIsFooterOpen(false);
        return;
      }

      const updatedTicketList = selectedItems
        .flatMap((user) => user.tickets || [])
        .filter((ticket) => ticket.ticketStatusId === TicketStatus.CART);

      // Calculate ticket count
      const newTicketCount = updatedTicketList.length;

      // Calculate unique event count using eventId
      const uniqueEventTicketIds = new Set(
        updatedTicketList.map((ticket) => ticket.eventTicketId)
      );
      const newEventCount = uniqueEventTicketIds.size;

      // Calculate total price and collect currency codes
      let newTotal = 0;
      const currencyCodesSet = new Set();

      updatedTicketList.forEach((ticket) => {
        const matchingEventSet = eventDetails.eventSets.find(
          (eventSet) =>
            eventSet.eventId === ticket.eventId &&
            eventSet.eventSetTickets.some(
              (setTicket) => setTicket.ticketId === ticket.eventTicketId
            )
        );

        if (!matchingEventSet) return;

        const matchingEventSetTicket = matchingEventSet.eventSetTickets.find(
          (setTicket) => setTicket.ticketId === ticket.eventTicketId
        );

        if (!matchingEventSetTicket) return;

        const price = parseFloat(matchingEventSetTicket.priceStr);
        const validPrice = isNaN(price) ? 0 : price;

        newTotal += validPrice;

        if (matchingEventSetTicket.currencyCode) {
          currencyCodesSet.add(matchingEventSetTicket.currencyCode);
        }
      });

      // Convert the currency code set to an array
      const currencyCodesArray = Array.from(currencyCodesSet);

      // Set state with calculated values
      setEventCount(newEventCount);
      setTicketCount(newTicketCount);
      setTotal(newTotal);
      setCurrencyCodes(currencyCodesArray);

      // Show the footer only if there are selected tickets
      setIsFooterOpen(newTicketCount > 0);
    }, [selectedItems, eventDetails.eventSets]);

    //Triggers the add button in user
    const onClickAdd = (eventSet, user) => {
      if (
        !eventSet ||
        !eventSet.eventSetTickets ||
        eventSet.eventSetTickets.length === 0
      ) {
        return;
      }

      const selectedTicket = eventSet.eventSetTickets[0];
      const amount = parseFloat(selectedTicket.priceStr) || 0;

      const newTicket = {
        companyId: user?.tickets[0]?.companyId,
        contactId: user.contactId,
        userId: user.userId,
        eventId: eventSet.eventId,
        eventTicketId: selectedTicket.ticketId,
        ticketStatusId: TicketStatus.CART,
        statusStr: "CART",
        title: selectedTicket.title || "",
        type: "LOCAL",
        startsOn: eventSet.startsOn,
        endsOn: eventSet.endsOn,
        amount: amount,
        taxAmount: 0,
        taxRate: 0,
      };

      setSelectedItems((prev) => {
        const existingUserIndex = prev.findIndex(
          (item) => item.userId === user.userId
        );

        if (existingUserIndex !== -1) {
          const updatedSelectedItems = [...prev];
          updatedSelectedItems[existingUserIndex] = {
            ...updatedSelectedItems[existingUserIndex],
            tickets: [
              ...updatedSelectedItems[existingUserIndex].tickets,
              newTicket,
            ],
          };
          return updatedSelectedItems;
        } else {
          // If user does not exist, create a new entry
          return [
            ...prev,
            {
              ...user,
              tickets: [newTicket],
            },
          ];
        }
      });
    };

    const onClickCancel = (eventSet, user) => {
      setSelectedItems((prevSelectedItems) => {
        const updatedSelectedItems = prevSelectedItems
          .map((item) => {
            if (item.userId === user.userId) {
              const ticketIdToRemove = eventSet.eventSetTickets[0]?.ticketId;

              const updatedTickets = item.tickets.filter(
                (ticket) => ticket.eventTicketId !== ticketIdToRemove
              );

              if (updatedTickets.length === 0) {
                return null;
              }

              return {
                ...item,
                tickets: updatedTickets,
              };
            }

            return item;
          })
          .filter(Boolean);

        return updatedSelectedItems;
      });
    };

    // Async function to add items to cart

    async function handleAddToCart() {
      if (selectedItems.length === 0) return;

      const newCartItems = selectedItems.flatMap((item) =>
        item.tickets.map((ticket) => ({
          ticketId: ticket.eventTicketId,
          // userId: item.userId,
          contactId: item.contactId,
          eventId: ticket.eventId,
          isTandem: false,
          amount: ticket.amount || 0,
        }))
      );
      // );

      if (newCartItems.length === 0) {
        return;
      }

      try {
        await cartAddPost.mutateAsync(newCartItems);

        // setSelectedItems((prevSelectedItems) =>
        //   prevSelectedItems.map((item) => ({
        //     ...item,
        //     userData: {
        //       ...item.userData,
        //       tickets: item.userData.tickets.map((ticket) => ({
        //         ...ticket,
        //         ticketStatusId: TicketStatus.CART,
        //         statusStr: "CART",
        //       })),
        //     },
        //   }))
        // );

        // Set cart open state and redirect after a timeout
        setIsAddToCartOpen(true);
        setTimeout(() => {
          setIsAddToCartOpen(false);
          history.push(PATHS.CART);
        }, 700);
      } catch (error) {
        console.error("Error adding items to cart:", error);
      }
    }

    // const onRedirectToCart = () => {
    //   setIsAddToCartOpen(true);
    //   setTimeout(() => {
    //     setIsAddToCartOpen(false);
    //     history.push(PATHS.CART);
    //   }, 700);
    // };

    const onRedirectToCartCheckout = () => {
      history.push(PATHS.CART_CHECKOUT);
    };

    //Dates and time
    // const promoStartsOn = new Date(eventDetails?.startsOn);
    // const promoEndsOn = new Date(eventDetails?.endsOn);

    // const onSaleDate =
    //   promoStartsOn && promoEndsOn
    //     ? formatDateRange(promoStartsOn, promoEndsOn)
    //     : "";

    //Function triggers in clicking locatin map
    const locationMap = (locationArray) => {
      let retString = "";

      for (const key in locationArray) {
        if (locationArray.hasOwnProperty(key)) {
          if (key !== "venueName") {
            // Add comma after line1 and city
            if (key === "line1" || key === "city") {
              retString += locationArray[key] ? locationArray[key] + ", " : "";
            } else {
              retString += locationArray[key] ? locationArray[key] + " " : "";
            }
          }
        }
      }

      retString = retString.trim();
      return retString;
    };

    //Extract the eventSessions with eventType === "EVENT"
    const eventTypeSessions = eventSessions.filter(
      (session) => session.sessionType === "EVENT"
    );

    //Extract the eventSessions with eventType === "EVENT"
    const allTypeSessions =
      eventSessions.filter((session) => session.sessionType === "SESSION") ||
      [];

    //Extract the eventSessions
    const startTime = allTypeSessions
      ? extractTimeAndTimezone(allTypeSessions[0]?.startsOn)
      : "";

    const endTime = allTypeSessions
      ? extractTimeAndTimezone(allTypeSessions[0]?.endsOn)
      : "";

    //Get the price from the first ticket with the priority === 1
    const getPriceStr = (tickets) => {
      const priorityOneTicket = tickets.find((ticket) => ticket.priority === 1);

      if (priorityOneTicket) {
        const price = parseFloat(priorityOneTicket.priceStr);
        return formatCurrency(price, priorityOneTicket.currencyCode);
      } else {
        return "0.00";
      }
    };

    const priceStr = eventDetails ? getPriceStr(eventDetails.tickets) : "";

    if (!eventDetails || !userData || userLoading) {
      return (
        <div>
          <Loader color={"#415364"} size={85} />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.header} onClick={() => history.goBack()}>
          <img
            src={ArrowLeftIcon}
            alt={"Back Icon"}
            className={styles.backIcon}
          />
          <span className={styles.headerText}>Local Functions</span>
        </div>
        {/* <div> */}
        <div className={styles.topContainer}>
          <div className={styles.locationMapContainer}>
            <div className={styles.location}>
              {/* {eventDetails?.location?.line1} */}
              {eventDetails.title}
            </div>
            <div className={styles.map} onClick={() => setIsOpen(true)}>
              <img
                src={Map_Icon}
                alt="Map Icon"
                className={styles.mapIcon}
                onClick={() => setIsOpen(true)}
              />
            </div>
          </div>

          <div className={styles.countDown}>
            <CountDown
              date={eventDetails?.startsOn || null}
              onSaleDate={eventDetails?.startsOn || null}
              // onSaleDate={""}
            />
          </div>
          {/* </div> */}
        </div>

        <div className={styles.body}>
          <LocalFunctionInfoCard
            speakerInfo={
              eventDetails.speaker
                ? {
                    firstName: eventDetails.speaker.name.split(" ")[0] || "",
                    lastName:
                      eventDetails.speaker.name.split(" ").slice(1).join(" ") ||
                      "",
                    src: eventDetails.speaker.src || "",
                  }
                : null
            }
            sessionInfo={
              allTypeSessions.length > 0
                ? allTypeSessions.map((session) => ({
                    title: session.title || "",
                    startTime: startTime || "",
                    endTime: endTime || "",
                    description: session.sessionNote || "",
                  }))
                : null
            }
            priceStr={priceStr}
          />

          {eventDetails?.notes?.map((item, index) => (
            <div className={styles.alert} key={index}>
              <Alert note={item.note} />
            </div>
          ))}

          {/* //No pinNote */}
          {/* {eventDetails?.pinNotes?.map((note, index) => (
            <div className={styles.noti} key={index}>
              <Notification
                className="#243746"
                notification={note.pinNote}
                img=""
              />
            </div>
          ))} */}

          {/* //Not sure if this is included
          <Note
            icon={<QuestionMarkIcon fill="#fff" fillText="#243746" />}
            content="This meeting is tentative."
          /> */}

          <div className={styles.section}>
            <div className={styles.eventItem}>
              <LocalFunctionEventsList
                eventSets={eventDetails?.eventSets}
                users={userData}
                onClickAdd={(eventSet, user) => onClickAdd(eventSet, user)}
                onClickCancel={(eventSet, user) =>
                  onClickCancel(eventSet, user)
                }
                onClickPurchased={() => {}}
              />
            </div>
          </div>
        </div>

        {isOpen && (
          <LocationModal
            venueName={eventDetails.location.venueName}
            address={locationMap(eventDetails.location)}
            onChangeVisible={(visible) => setIsOpen(visible)}
          />
        )}

        {isFooterOpen && (
          <>
            <Footer isOpen={isFooterOpen} onClose={() => {}}>
              <div className={styles.footerAddToCart}>
                <div className={styles.cartPrice}>
                  <b>{eventCount}</b> Events <b>x {ticketCount}</b> Tickets ={" "}
                  <b>
                    {Array.from(currencyCodes).join(", ")} ${total.toFixed(2)}
                  </b>
                </div>
                <div className={styles.btn}>
                  <Button
                    title="Add to Cart"
                    onClick={handleAddToCart}
                    className={styles.add}
                  />
                  <Button
                    title="Buy Now"
                    onClick={onRedirectToCartCheckout}
                    className={styles.buy}
                  />
                </div>
              </div>
            </Footer>

            <CartPopup isOpen={isAddToCartOpen} />
          </>
        )}
      </div>
    );
  }
);
