import React, { useState, useEffect } from "react";
import Footer from "components/Footer";
import styles from "./TermAndCondition.module.css";
import CloseBtn from "assets/icons/Close_Button_Modal.svg";
import InfoModal from "components/InfoModal";

interface IIModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onClick?: () => void;
}

export const TermAndCondition = (props: IIModalProps) => {
  const { isOpen, onClose, onClick } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <InfoModal isOpen={isOpen} onClose={handleClose} className={styles.modal}>
      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>Terms & Conditions</div>
          <div className={styles.title}>
            <p> Agreement to use wwdb.com Website and Mobile Application</p>
            <p>and</p>
            <p>Dreambuilders™ Membership Terms and Conditions</p>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.description}>
            <div>
              <p>
                Welcome to Dreambuilders™ Membership, a Delaware LLC. This
                website is being used to implement a new concept in the delivery
                of services to those World Wide Dreambuilders affiliated
                Independent Business Owners (IBOs) desiring to enhance their
                educational skills for development of their Amway™ business. As
                part of those services, users of this website shall be afforded
                the opportunity to purchase Business Support Materials at a
                preferred price. The following terms and conditions will control
                the use of this website, including the mobile application until
                further notification. By accessing this site and use of the
                services provided, you agree as a user to be bound by the terms
                and conditions of this Agreement.
              </p>
            </div>
            <div>
              <p>
                1. Purpose of This Website and Mobile This website and
                associated mobile application's purpose is to inform you of
                useful information as a World Wide Dreambuilders–affiliated
                Amway IBO, and is for your use in registering to acquire
                Business Support Materials. Premier Members may order Business
                Support Materials directly through this website and/or mobile
                application. If you have any questions regarding the site or
                mobile application, please contact 'Support' under the 'Help'
                button on the WWG mobile app or wwdb.com. If you have questions
                regarding the benefits, you should contact your upline Platinum
                If you have any questions regarding the site or mobile
                application, please contact 'Support' under the 'Help' button on
                the WWG mobile app or wwdb.com. If you have questions regarding
                the benefits, you should contact your upline Platinum If you
                have any questions regarding the site or mobile application,
                please contact 'Support' under the 'Help' button on the WWG
                mobile app or wwdb.com. If you have questions regarding the
                benefits, you should contact your upline Platinum
              </p>
            </div>
          </div>

          <Footer
            isOpen={true}
            onClose={() => {}}
            children={
              <div className={styles.footer}>
                <div className={styles.buttonNo}>
                  <button onClick={onClose}>No</button>
                </div>
                <div className={styles.buttonYes}>
                  <button onClick={onClick}>Yes</button>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </InfoModal>
  );
};
