import React, { useState } from "react";
import styles from "./CartSpecialNeedsModal.module.css";
import SpecialModal from "components/SpecialModal";
import Jeff from "assets/demo/Jeff3x.png";
import Britt from "assets/demo/Britt3x.png";
import AdASeatingIcon from "assets/icons/ADA_Seating_Green.svg";
import AdaSeatingIconBlue from "assets/icons/ADA_Seating_Blue.svg";
import AdaCompanionIcon from "assets/icons/ADA_Companion.svg";
import HearingIcon from "assets/icons/Hearing_Device.svg";
import SignLanguageIcon from "assets/icons/Sign_Language.svg";
import NursingIcon from "assets/icons/Nursing_bottle_Icon.svg";
import AdaCompanionIconGreen from "assets/icons/ADA_Companion_Green.svg";
import AdaSeatingIconGreen from "assets/icons/ADA_Seating_Green.svg";
import HearingIconGreen from "assets/icons/Hearing_Device_Green.svg";
import SignLanguageIconGreen from "assets/icons/Sign_Language_Green.svg";
import RadioButtonItem from "components/RadioButtonItem";
import { useCart } from "stores/CartContext";
import { ICartItem } from "../CartItem/CartItem";

interface ICartSpecialNeedsModalProps {
  cartItems: ICartItem[];
  isOpen: boolean;
  onClose?: () => void;
  onPassIboNeeds: (selectedSpecialNeeds: {
    [iboName: string]: string[];
  }) => void;
}

export const CartSpecialNeedsModal = (props: ICartSpecialNeedsModalProps) => {
  const { cartItems, isOpen, onClose, onPassIboNeeds } = props;

  const [selectedIbo, setSelectedIbo] = useState(cartItems[0]?.iboName || "");
  const [selectedSpecialNeeds, setSelectedSpecialNeeds] = useState<{
    [iboName: string]: string[];
  }>({});

  const [showSpecialNeedsOptions, setShowSpecialNeedsOptions] = useState(false);

  const options = [
    {
      name: "optionGroup1",
      image: AdaSeatingIconBlue,
      value: "Accessible seating",
      subValue: "",
    },
    {
      name: "optionGroup1",
      image: AdaCompanionIcon,
      value: "Accessible seating companion ticket",
      subValue: "",
    },
    {
      name: "optionGroup1",
      image: HearingIcon,
      value: "Use of a hearing amplification device",
      subValue: "",
    },
    {
      name: "optionGroup1",
      image: SignLanguageIcon,
      value: "ASL Interpreter",
      subValue: "(At least 4 weeks advance notice required)",
    },
    {
      name: "optionGroup1",
      image: NursingIcon,
      value: "Nursing Mother",
      subValue: "",
    },
  ];

  const iconsInGreen = {
    "Accessible seating": AdaSeatingIconGreen,
    "Accessible seating companion ticket": AdaCompanionIconGreen,
    "Use of a hearing amplification device": HearingIconGreen,
    "ASL Interpreter": SignLanguageIconGreen,
    "Nursing Mother": NursingIcon,
  };

  const isCompanionDisabled =
    cartItems.length === 1 || selectedIbo === cartItems[0]?.iboName;

  const handleCheckboxChange = (value: string) => {
    setSelectedSpecialNeeds((prevSelectedSpecialNeeds) => {
      const currentSelections = prevSelectedSpecialNeeds[selectedIbo] || [];
      const newSelections = currentSelections.includes(value)
        ? currentSelections.filter((v) => v !== value)
        : // Limit to 2 selections
          [...currentSelections, value].slice(0, 2);

      return {
        ...prevSelectedSpecialNeeds,
        [selectedIbo]: newSelections,
      };
    });
  };

  const handleIboChange = (iboName: string) => {
    setSelectedIbo(iboName);
  };

  const handleOnAgree = () => {
    setShowSpecialNeedsOptions(true);
  };

  const handleClose = () => {
    setShowSpecialNeedsOptions(false);
    onClose();
  };

  const handleContinue = () => {
    onPassIboNeeds(selectedSpecialNeeds);
    handleClose();
  };

  return (
    <div className={styles.container}>
      {!showSpecialNeedsOptions ? (
        <SpecialModal
          title={"Special Needs"}
          isOpen={isOpen}
          onClose={onClose}
          children={
            <div className={styles.content}>
              <p className={styles.text}>
                You are requesting to purchase a "RESERVED" designated
                accessible ticket. Seats are reserved and guaranteed for your
                ease and use
              </p>
              <p>
                You must sit in the designated sections provided. General
                seating does not apply and early admittance is no longer
                available due to your guearanteed reserved placement. A
                designated accessible entry door will be assigned for your
                convenience.
              </p>
              <p>
                You are allowed one additional companion ticket. Both guests
                must be registereed on the same order to guarantee availability.
                Please remember to request your companion ticket. This is not
                automatic and will only be assigned if the option is selected.
              </p>
              <p>
                You confirm the individual(s) requesting disability seating has
                need of the features provided by accessible seating as stated by
                law. If there is good cause to believe that accessible seating
                has been purchased fraudulently further investigation of the
                purchase may be pursued.
              </p>
              <p>
                By continuing, you acknowledge that you understand and will
                honor the terms as stated above.
              </p>
              <p>Do you wish to continue?</p>
            </div>
          }
          buttonText={"I Agree"}
          onClick={handleOnAgree}
        />
      ) : (
        <SpecialModal
          title={"Special Needs"}
          isOpen={showSpecialNeedsOptions}
          onClose={() => {
            setShowSpecialNeedsOptions(false);
          }}
          children={
            <div className={styles.contentSelection}>
              <p className={styles.text}>
                Who would you like to register for a special need?
              </p>

              <div className={styles.imageContainer}>
                {cartItems.map((item, index) => (
                  <div
                    key={index}
                    className={styles.iboGroup}
                    onClick={() => handleIboChange(item.iboName)}
                  >
                    <div className={styles.image}>
                      <img
                        src={item.iboImage}
                        alt="Ibo"
                        className={
                          item.iboName === selectedIbo
                            ? styles.activeIbo
                            : styles.inactive
                        }
                        // className={styles.imageIbo}
                      />
                    </div>
                    <div className={styles.iconContainer}>
                      {selectedSpecialNeeds[item.iboName]?.map(
                        (selectedValue) => (
                          <img
                            key={selectedValue}
                            src={iconsInGreen[selectedValue]}
                            alt="Selected Special Needs Icon"
                            className={`${
                              item.iboName === selectedIbo || index === 3
                                ? styles.activeNeeds
                                : styles.inactiveNeeds
                            } ${
                              index === 1 || index === 3
                                ? styles.specialIcon
                                : ""
                            }`}
                          />
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.descriptionContainer}>
                <p className={styles.description}>
                  Special needs requests are associated to an individual ticket.
                  If you have multiple tickets requiring a special request,
                  please make sure you mark each ticket with the appropriate
                  response in order to secure your place in the accessible
                  seating section. We cannot guarantee availability for onsite
                  requests.
                </p>
              </div>

              <div className={styles.optionsContainer}>
                {options.map((option, index) => (
                  <RadioButtonItem
                    key={index}
                    name={option.name}
                    image={option.image}
                    value={option.value}
                    subValue={option.subValue}
                    // isSelected={selectedSpecialNeeds.includes(option.value)}
                    isSelected={
                      selectedSpecialNeeds[selectedIbo]?.includes(
                        option.value
                      ) || false
                    }
                    className={
                      index === 1 || index === 3 ? styles.specialIcon : ""
                    }
                    onChange={() => handleCheckboxChange(option.value)}
                    disabled={index === 1 && isCompanionDisabled}
                  />
                ))}
              </div>
            </div>
          }
          buttonText={"Continue"}
          onClick={handleContinue}
        />
      )}
    </div>
  );
};

export default CartSpecialNeedsModal;
