import PropTypes from "prop-types";
import styles from "./PaymentDetails.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcDiscover,
  faCcDinersClub,
  faCcJcb,
  faCcMastercard,
} from "@fortawesome/free-brands-svg-icons";

/**
 *  Interactive component that displays the amount owed for tickets and displays the selected payment method. If the user clicks anywhere on the payment method they should be redirected to the card select page. This component will handle the click action by calling an onPaymentMethodClick callback.
 */

export function PaymentDetails({
  amountOwed,
  paymentMethod = {} as any,
  onPaymentMethodClick,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.payment}>Payment</div>
      <div className={styles.amount}>
        Amount Owed: <b>${amountOwed}</b>
      </div>
      <div className={styles.method}>Payment Method:</div>
      <div className={styles.paymentMethod} onClick={onPaymentMethodClick} data-testid="paymentMethod">
        {(() => {
          if (paymentMethod.type == "VISA") {
            return (
              <div className={styles.iconContainer}>
                <FontAwesomeIcon
                  icon={faCcVisa}
                  className={styles.iconClass}
                />
              </div>
            );
          } else if (paymentMethod.type == "MASTERCARD") {
            return (
              <div className={styles.iconContainer}>
                <FontAwesomeIcon
                  icon={faCcMastercard}
                  className={styles.iconClass}
                />
              </div>
            );
          } else if (paymentMethod.type == "DISCOVER CARD") {
            return (
              <div className={styles.iconContainer}>
                <FontAwesomeIcon
                  icon={faCcDiscover}
                  className={styles.iconClass}
                />
              </div>
            );
          } else if (paymentMethod.type == "DINERS CLUB INTERNATIONAL") {
            return (
              <div className={styles.iconContainer}>
                <FontAwesomeIcon
                  icon={faCcDinersClub}
                  className={styles.iconClass}
                />
              </div>
            );
          } else if (paymentMethod.type == "JCB") {
            return (
              <div className={styles.iconContainer}>
                <FontAwesomeIcon
                  icon={faCcJcb}
                  className={styles.iconClass}
                />
              </div>
            );
          } else {
            return <div className={styles.iconContainer}></div>;
          }
        })()}
        <b className={styles.lastFour}>ending in {paymentMethod.lastFour}</b>
      </div>
    </div>
  );
}

PaymentDetails.propTypes = {
  amountOwed: PropTypes.string,
  paymentMethod: PropTypes.shape({
    type: PropTypes.string,
    lastFour: PropTypes.string,
    expires: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  onPaymentMethodClick: PropTypes.func,
};
