import React from "react";
import styles from "./MajorFunctionHeader.module.css";
import ArrowLeft from "assets/demo/ArrowLeft.svg";
import ArrowRight from "assets/demo/ArrowRight.svg";
// import { IFunction } from "models";
import { IMajorFunctions } from "stores/majorInterface";
import SLIcon from "assets/icons/Spring_Leadership_Icon.svg";
import FRIcon from "assets/icons/Family_Reunion_Icon.svg";
import FEIcon from "assets/icons/Free_Enterprise_icon_Header.svg";

import DreamNightIcon from "assets/icons/DreamNight_Icon.svg";

interface IProps {
  model: IMajorFunctions;
  onNext: Function;
  onPrevious: Function;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
}
export const MajorFunctionHeader: React.FC<IProps> = (props) => {
  const { model, onNext, onPrevious, isNextDisabled, isPreviousDisabled } =
    props;

  const getIconByName = (templateName) => {
    if (templateName === "Spring Leadership") {
      return SLIcon;
    } else if (templateName === "Family Reunion") {
      return FRIcon;
    } else if (templateName === "Free Enterprise") {
      return FEIcon;
    } else if (templateName === "DreamNight") {
      return DreamNightIcon;
    }
    return null;
  };

  return (
    <div className={styles.header}>
      <div
        className={`${styles.button} ${
          isPreviousDisabled ? styles.disabled : ""
        }`}
        onClick={() => !isPreviousDisabled && onPrevious()}
      >
        <img src={ArrowLeft} alt="Arrow Left" className={styles.arrowIcon} />
      </div>
      <div className={styles.headerContent}>
        {/* <img className={styles.icon} src={model.icon} /> */}
        <img
          className={styles.icon}
          alt="Event Icon"
          src={getIconByName(model.templateName)}
        />

        <div className={styles.title}>{model.templateName}</div>
      </div>
      <div
        className={`${styles.button} ${isNextDisabled ? styles.disabled : ""}`}
        onClick={() => !isNextDisabled && onNext()}
      >
        <img src={ArrowRight} alt="Arrow Right" className={styles.arrowIcon} />
      </div>
    </div>
  );
};
