import { FC } from 'react'
import styles from './LocalFunctionTypeItem.module.css'
import { ILocalFunctionTypeItem } from './LocalFunctionTypeItem.types'
import demoImg from 'assets/icons/Second_Look_Icon.svg'

interface IProps extends ILocalFunctionTypeItem {
}

export const LocalFunctionTypeItem: FC<IProps> = props => {
  const { info } = props
  const { icon, endTime, footer, name, note, startTime } = info

  return <div className={styles.section}>
    <img src={icon || demoImg} />
    <div className={styles.info}>
      <div>{name}</div>
      <div className={styles.note}>{note}</div>
      <div>{startTime}-{endTime}</div>
      <div className={styles.footer}>{footer}</div>
    </div>
  </div>
}