import { FC } from 'react';
import { IPlayIcon } from './PlayIcon.types';
import styles from './PlayIcon.module.css';
import PlayIconSVG from 'assets/icons/Play_Icon.svg';

interface IProps extends IPlayIcon {
  onClick: () => void; // Ensure onClick handler is required
  className?: string; // Optional className
}

export const PlayIcon: FC<IProps> = ({ onClick, className = 'btn-play' }) => {
  return (
    <div className={`${styles.playIcon} ${className}`} onClick={onClick}>
      <img src={PlayIconSVG} alt="Play Icon" className={styles.playIconImage} />
    </div>
  );
};
