import React from "react";
import styles from "./AboutButton.module.css";
import PropTypes from "prop-types";

interface IAboutButtonProps {
  title: string;
  onClick: Function;
  isSummit: boolean;
}
export const AboutButton = (props: IAboutButtonProps) => {
  const { title, isSummit, onClick } = props;
  return (
    <div className={styles.buttonDiv}>
      <button
        className={isSummit ? styles.summitBtn : styles.button}
        onClick={() => onClick()}
      >
        <div className={styles.title}>
          <span>{title}</span>
        </div>
      </button>
    </div>
  );
};
AboutButton.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AboutButton;
