import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./InfoModal.module.css";
import classNames from "classnames";
import CloseBtn from "assets/icons/Close_Button_Modal.svg";

/**
 * Basic layout for modals used in the app
 */

export function InfoModal({ isOpen, children, className, onClose }) {
  return (
    <>
      {isOpen && (
        <div className={styles.overlay}>
          <div className={classNames(styles.container, className)}>
            {onClose && (
              <div className={styles.closeButton} onClick={onClose}>
                <img src={CloseBtn} alt={"Close Btn"} />
              </div>
            )}
            <div className={styles.body}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
}

InfoModal.propTypes = {
  /**
   * Boolean to determine if modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * Function called to close modal
   */
  onClose: PropTypes.func,
  /**
   * Children components to render in the modal
   */
  children: PropTypes.any,
  /**
   * Passed in to make changes to the modals style
   */
  className: PropTypes.string,
};
