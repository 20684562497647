import { FC } from "react";
import styles from "./LockIcon.module.css";

interface IILockIconProps {
  isLocked?: boolean;
  // onClick?: () => void;
}

export const LockIcon: FC<IILockIconProps> = (props) => {
  const { isLocked } = props;

  return isLocked ? (
    <svg
      className={styles.svg}
      // onClick={() => onClick && onClick()}
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="26"
      viewBox="0 0 19.5 26"
    >
      <g fill="#adb7c0" transform="translate(-12.5 .001)">
        <path
          d="M17.25 40.625h14.625A1.746 1.746 0 0133.5 42.25v11.375a1.625 1.625 0 01-1.625 1.625H17.25a1.746 1.746 0 01-1.625-1.625V42.25a1.625 1.625 0 011.625-1.625z"
          data-name="Path 40696"
          transform="translate(-2.313 -30.063)"
        ></path>
        <path
          d="M29.562 53.75H14.937a2.438 2.438 0 01-2.437-2.438V39.937a2.438 2.438 0 012.437-2.437h14.625A2.438 2.438 0 0132 39.937v11.375a2.438 2.438 0 01-2.438 2.438zM14.937 39.125a.812.812 0 00-.812.812v11.375a.812.812 0 00.812.812h14.625a.812.812 0 00.812-.812V39.937a.812.812 0 00-.812-.812z"
          data-name="Path 40697"
          transform="translate(0 -27.751)"
        ></path>
        <path
          d="M36.916 11.916a1.082 1.082 0 01-1.083-1.083V6.5a4.334 4.334 0 00-8.667 0v4.334a1.083 1.083 0 01-2.166 0V6.5a6.5 6.5 0 1113 0v4.334a1.082 1.082 0 01-1.084 1.083z"
          data-name="Path 40698"
          transform="translate(-9.25)"
        ></path>
      </g>
    </svg>
  ) : (
    <svg
      className={styles.svg}
      // onClick={() => onClick && onClick()}
      xmlns="http://www.w3.org/2000/svg"
      width="27.148"
      height="26"
      viewBox="0 0 27.148 26"
    >
      <g fill="#43ba32" transform="translate(-18.584 4336)">
        <path
          d="M17.25 40.625h14.625A1.746 1.746 0 0133.5 42.25v11.375a1.625 1.625 0 01-1.625 1.625H17.25a1.746 1.746 0 01-1.625-1.625V42.25a1.625 1.625 0 011.625-1.625z"
          data-name="Path 40696"
          transform="translate(11.419 -4366.063)"
        ></path>
        <path
          d="M29.562 53.75H14.937a2.438 2.438 0 01-2.437-2.438V39.937a2.438 2.438 0 012.437-2.437h14.625A2.438 2.438 0 0132 39.937v11.375a2.438 2.438 0 01-2.438 2.438zM14.937 39.125a.812.812 0 00-.812.812v11.375a.812.812 0 00.812.813h14.625a.812.812 0 00.812-.812V39.937a.812.812 0 00-.812-.812z"
          data-name="Path 40697"
          transform="translate(13.732 -4363.75)"
        ></path>
        <path
          d="M36.916 11.916a1.082 1.082 0 01-1.083-1.083V6.5a4.334 4.334 0 00-8.667 0v4.334a1.083 1.083 0 01-2.166 0V6.5a6.5 6.5 0 1113 0v4.334a1.082 1.082 0 01-1.084 1.083z"
          data-name="Path 40699"
          transform="translate(-6.416 -4336)"
        ></path>
      </g>
    </svg>
  );
};
