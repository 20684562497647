import React from "react";
import styles from "./LocalFunctionsInfo.module.css";
import { useHistory } from "react-router-dom";
import LocalFunctionTypeItem from "components/LocalFunctionComponents/LocalFunctionTypeItem";

interface ILocalFunctionsInfoProps {}

export const LocalFunctionsInfo = React.memo(
  (props: ILocalFunctionsInfoProps) => {
    const history = useHistory();

    const events = [
      {
        title: "Regional Rally Meeting™",
        items: [
          {
            name: "Event 1 - Seminar",
            startTime: "2:00",
            endTime: "4:30 pm",
            note: "(1 ticket per person, per session)",
            footer: "IBO Only",
            icon: "",
          },
          {
            name: "Event 2 - Organizational",
            startTime: "8:00",
            endTime: "11:00 pm",
            note: "(1 ticket per person, per session)",
            footer: "Guests welcome, and free!",
            icon: "",
          },
        ],
        footer:
          "The Seminar offers instruction on “the basics of building the business” and is for IBOs only.",
      },
      {
        title: "Second Look Meeting™",
        items: [
          {
            name: "Event - Second Look Meeting (SLM)",
            startTime: "2:00",
            endTime: "4:30 pm",
            note: "(1 ticket per person, per session)",
            footer: "IBO Only",
            icon: "",
          },
        ],
        footer:
          "The Second Look Meeting is an opportunity to bring prospects to see the Amway Business Plan from a successful IBO. \n The motivational <strong>Rally</strong> is dedicated to the bigger picture, along with the dreams and rewards that can be realized from building an independent business. This session is excellent for guests as well as active Business Builder IBOs.",
      },
      {
        title: "Organizational Meeting",
        items: [
          {
            name: "Event - Organizational",
            startTime: "8:00",
            endTime: "11:00 pm",
            note: "(1 ticket per person, per session)",
            footer: "Guests welcome, and free!",
            icon: "",
          },
        ],
        footer:
          "Organizational Open meetings are presented by qualified Executive Diamond IBOs within their organization by providing an in-depth          explanation and overview of the Independent Business Ownership Plan. \n   These meetings are designed for IBOs or potential IBOs who have aready seen the business plan. They are designed to help expand your knowledge and scope of the business as an IBO; along with sharing          the achievements and benefits that can be realized when building a           business.",
      },
    ];

    const renderHeader = () => {
      return (
        <div className={styles.headerContainer}>
          <div className={styles.header} onClick={() => history.goBack()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8.839"
              height="14.334"
              viewBox="0 0 8.839 14.334"
            >
              <path
                fill="#9E703C"
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
                d="M.707 1.767l6.364 6.364L8.132 7.07 1.768.707.707 1.767zm1.061 11.86l6.364-6.364-1.061-1.061-6.364 6.365 1.061 1.061z"
              ></path>
            </svg>
            <span>Local Functions</span>
          </div>
          <div className={styles.title}>
            Local Function Tickets: $20 Per Person, per event
          </div>
        </div>
      );
    };

    return (
      <div className={styles.container}>
        {renderHeader()}
        {events.map((event, index) => (
          <div key={index} className={styles.content}>
            <div className={styles.rally}>{event.title}</div>
            {event.items.map((item, index) => (
              <div key={index} className={styles.typeItem}>
                <LocalFunctionTypeItem
                  info={{
                    name: item.name,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    icon: item.icon,
                    note: item.note,
                    footer: item.footer,
                  }}
                />
              </div>
            ))}
            <div className={styles.footer}>
              <p>{event.footer}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
);

const renderFooter1 = () => {
  return (
    <div className={styles.footer}>
      <p>
        The <strong>Seminar</strong> offers instruction on “the basics of
        building the business” and is for IBOs only.
      </p>
      <br />
    </div>
  );
};
