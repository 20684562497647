import Button from "components/Button";
import PastMeetingItem from "components/PastMeetingItem";
import React from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "utils";
import styles from "./HomeSiteNoSetUp.module.css";
import HomeSite_Icon from "assets/icons/HomeSite_Icon.svg";
interface IProps {}

export const HomeSiteNoSetUp: React.FC<IProps> = (props) => {
  const history = useHistory();

  const onRedirectToPage = () => {
    history.push(PATHS.WHAT_NEW_HOMESITE);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <img src={HomeSite_Icon} alt="" />
      </div>
      <div className={styles.text}>
        <p>You have not set your HomeSite yet. </p>
        <p>Press the button below to set it up!</p>
      </div>
      <Button
        title="Set Up HomeSite"
        onClick={onRedirectToPage}
        className={styles.action}
      />
    </div>
  );
};
