import { FC } from 'react'
import { IHomeLocationIcon } from './HomeLocationIcon.types'
import classnames from 'classnames'
import styles from './HomeLocationIcon.module.css'
import HomeSite_Icon from 'assets/icons/HomeSite_Icon.svg'
interface IProps extends IHomeLocationIcon {
}

export const HomeLocationIcon: FC<IProps> = props => {
  const { className } = props

  return (
    <div className={classnames(className,styles.wrapper)}>
      <img src={HomeSite_Icon} alt="" />
    </div>
  )
}