import { FC } from "react";
import styles from "./PinIcon.module.css";

interface IIPinIconProps {
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

export const PinIcon: FC<IIPinIconProps> = (props) => {
  const width = props.width || 13,
    height = props.width || 26,
    fill = props.fill || "#000";

  return (
    <svg
      className={styles.svg}
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => props.onClick && props.onClick()}
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 12.868 26"
    >
      <g transform="translate(-27.133 -3.994)">
        <g data-name="Group 9898" transform="translate(27.133 3.994)">
          <path
            d="M83.3 78.5h8.953l.33.33-1.473 1.628v7.567l3.058 3.08-.55.55H81.784L81.3 91.1l3.234-3.08v-7.562l-1.5-1.562z"
            data-name="Path 37210"
            transform="translate(-81.3 -78.5)"
          ></path>
          <path
            d="M107.5 142.3h1.474v10.536l-.726 1.43-.748-1.43z"
            data-name="Path 37211"
            transform="translate(-101.737 -128.266)"
          ></path>
        </g>
      </g>
    </svg>
  );
};
