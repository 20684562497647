import React from "react";
import styles from "./InputRange.module.css";
import classnames from "classnames";

interface IInputRangeProps {
  max?: number;
  className?: string;
}

export const InputRange: React.FC<IInputRangeProps> = (props) => {
  const { max = props.max || 500, className } = props;
  const [value, setValue] = React.useState(0);

  const onChangeValue = (value) => {
    setValue(value);
  };

  const getBackgroundSize = () => {
    return { backgroundSize: `${(value * 100) / max}% 100%` };
  };
  return (
    <div className={styles.container}>
      <input
        className={classnames(className, styles.input)}
        type="range"
        min="0"
        max={max}
        onChange={(e) => onChangeValue(e.target.value)}
        style={getBackgroundSize()}
        value={value}
      />

      <p>500 Miles</p>
    </div>
  );
};
