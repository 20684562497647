//Data for each item
export interface EventInfo {
  eventId: number;
  eventSet: EventItem[];
  // user: UserIBO[];
  // tickets: Ticket[];
  pinNotes: string[];
  users?: UserIBO[];
}

//Correct structure
export interface EventItem {
  ticketId: number;
  eventId: number;
  title: string;
  currencyCode: string;
  priceStr: string;
  isAvailable: boolean;
  isVirtual: boolean;
  isInPerson: boolean;
  location: Location;
  speaker: Speaker;
  startsOn: string;
  endsOn: string;
  notes: Notes[];
  // users?: UserIBO[];
}

//Item
// export interface EventItem {
//   eventId: number;
//   title: string;
//   subTitle: string;
//   speaker: Speaker;
//   startsOn: string | null | undefined;
//   endsOn: string | null | undefined;
//   isAvailable: boolean;
//   isVirtual: boolean;
//   isInPerson: boolean;
//   location: Location;
//   notes: Notes[];
//   // tickets: Tickets[];
//   // pinNotes: string[];
//   users?: UserIBO[];
// }

export interface Speaker {
  name: string;
  src: string;
}

export interface Notes {
  id: number;
  note: string;
}

interface PrimaryButtonAction {
  component: string;
  data: {
    contentId: string;
  };
}

interface SecondaryButtonAction {
  // Not sure what data in here
}

// export interface PinNotes {
//   id: number;
//   pinNote: string;
// }

// export interface IBO {
//   image: string;
//   firstName: string;
//   ticketStatus: TicketStatus;
// }

export interface Tickets {
  ticketId: number;
  title: string;
  currencyCode: string;
  priceStr: string;
  priority: number;
  acceptedCreditTypeId: number;
  commonItemId: number;
  creditsRequired: number;
  isLimitedToGroups: boolean;
  onlyCreditsAllowed: boolean | null;
  tandemEventId: number;
  tandemTicketId: number;
  tandemTicketStatusId: number;

  status: TicketStatus;
  tickets: UserTickets[];
}

export interface UserIBO {
  contactId: number;
  name: string;
  userId: number;
  src: string | null;
  loa: LOA;
  tickets: UserTickets[];
}

export interface UserTickets {
  amount: number;
  attendeeTicketId: number;
  commonCartTypeId: number;
  commonItemId: number;
  companyId: string;
  eventId: number;
  eventTicketId: number;
  eventType: string;
  isInPerson: boolean;
  isVirtual: boolean;
  location: Location;
  startsOn: string;
  endsOn: string | null;
  statusStr: string;
  subTitle: string;
  taxAmount: number;
  taxRate: number;
  template: string;
  ticketStatusId: number;
  title: string;
  type: string;
  userId: number;
}

export interface Location {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  venueName: string;
}

export interface LOA {
  pinLevel: number;
  diamond: number;
  emerald: number;
  platinum: number;
  sponsor: number;
}

export enum TicketStatus {
  // NEW = 1,
  // SELECTED = 2,
  // PURCHASED = 3,
  // CART = 4,
  // INCLUDED = 5,
  // UNAVAILABLE = 6,

  AVAILABLE = 0,
  CART = 1,
  CART_INCLUDED = 2,
  PURCHASED = 3,
  PURCHASED_INCLUDED = 4,
  UNAVAILABLE = 6,
}

// export interface User {
//   Name: string;
//   UserId: number;
//   src: string;
//   tickets: Ticket[];
// }
