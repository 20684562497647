import { AppFooter, AppHeader, Button, Modal } from "@wwg/ui";
import { useParams } from "react-router";
import { useToggle } from "react-use";
import { EventListItem } from "../../components/EventListItem/EventListItem.jsx";
import { SpecialNeedsModal } from "../../components/SpecialNeedsModal/SpecialNeedsModal.jsx";
import { ScrollRequiredModal } from "../../components/ScrollRequiredModal/ScrollRequiredModal.jsx";
import termsAndConditions from "./termsAndConditions.js";
import { Ticket } from "../../components/Ticket/Ticket.jsx";
import { useCartData } from "./useCartData";
// import { useCountDownTimerData } from "./useCountDownTimerData";
import styles from "./Cart.module.css";
import { Selector } from "@wwg/ui";
import { useState } from "react";

/**
 * Example Cart structure component with demo of AppHeader and AppFooter controls.
 * Must be mounted inside a <Route> component.
 */

export function Cart() {
  //
  // Dictionaries
  //

  const locations = {
    dateAsc: {
      label: "Sort by Date ↑",
      value: "dateAsc",
    },
    dateDesc: {
      label: "Sort by Date ↓",
      value: "dateDesc",
    },
    cityAsc: {
      label: "Sort by City ↑",
      value: "cityAsc",
    },
    cityDesc: {
      label: "Sort by City ↓",
      value: "cityDesc",
    },
    stateAsc: {
      label: "Sort by State ↑",
      value: "stateAsc",
    },
    stateDesc: {
      label: "Sort by State ↓",
      value: "stateDesc",
    },
  };

  //
  // API Hooks
  //

  const cartData = useCartData();
  // const countDownTimerData = useCountDownTimerData();

  //
  // Dom States
  //

  const [footerIsOpen, toggleFooter] = useToggle(false);
  const [locationState, setLocation] = useState(locations.dateAsc.value);
  const [modalState, setModal] = useState({ visible: false, children: [] });

  //
  // Callback Functions
  //

  function onTicketsChanged({ target, eventId }) {
    const qty = Number(target.value);
    cartData.addTicketsMutation.mutate({ eventId, qty });
    cartData.addTimeMutation.mutate();
  }

  function onSpecialNeedsSave(ticket) {
    setModal({ visible: false, children: [] });
    cartData.updateTicketMutation.mutateAsync(ticket);
    cartData.addTimeMutation.mutate();
  }

  function onModalCloseClick() {
    setModal({ visible: false, children: [] });
  }

  function onSpecialNeedsClick(ticket) {
    setModal({
      visible: true,
      children: (
        <SpecialNeedsModal
          ticket={ticket}
          onSave={onSpecialNeedsSave}
          onCancel={onModalCloseClick}
        />
      ),
    });
  }

  function onDeleteClick(orderAccountingId) {
    const tickets = [orderAccountingId];
    cartData.deleteTicketMutation.mutate(orderAccountingId);
    cartData.addTimeMutation.mutate();
  }

  function onTicketTypeChange({ event, orderAccountingId }) {
    const selectedTicketType = event.target.value;
    cartData.updateTicketMutation.mutate([
      { orderAccountingId: orderAccountingId, selectedTicketType },
    ]);
    cartData.addTimeMutation.mutate();
  }

  function onLocationChange(event) {
    setLocation(event.target.value);
  }

  function onCheckoutClick() {
    setModal({
      visible: true,
      children: (
        <ScrollRequiredModal
          title={"Terms & Conditions"}
          content={termsAndConditions}
          onYes={() => {}}
          onNo={onModalCloseClick}
        />
      ),
    });
  }

  //
  // Build Objects
  //

  const Tickets = (event) => {
    let tickets = [];

    if (cartData.isSuccess == true) {
      event.tickets.forEach((ticket, index) => {
        console.log(ticket);

        tickets.push(
          <Ticket
            key={ticket.orderAccountingId}
            orderAccountingId={ticket.orderAccountingId}
            cost={ticket.cost}
            selectedTicketType={ticket.selectedType}
            priceTypeId={ticket.priceTypeId}
            onSpecialNeedsClick={() => onSpecialNeedsClick(ticket)}
            onDeleteClick={() => onDeleteClick(ticket.orderAccountingId)}
            onTicketTypeChange={(event) =>
              onTicketTypeChange({
                event,
                orderAccountingId: ticket.orderAccountingId,
              })
            }
          />
        );
      });
    }
    return tickets || <span> </span>;
  };

  const EventListItems = () => {
    const events = [];

    if (cartData.isSuccess == true) {
      const sortedEvents = cartData.data.events.sort((a, b) => {
        switch (locationState) {
          case locations.cityAsc.value:
            return a.city.localeCompare(b.city);
          case locations.cityDesc.value:
            return b.city.localeCompare(a.city);
          case locations.dateAsc.value:
            return Date.parse(a.startsOn) - Date.parse(b.startsOn);
          case locations.dateDesc.value:
            return Date.parse(b.startsOn) - Date.parse(a.startsOn);
          case locations.stateAsc.value:
            return a.state.localeCompare(b.state);
          case locations.stateDesc.value:
            return b.state.localeCompare(a.state);
        }
      });
      let ticketsExist = false; // Determine footer state
      Object.keys(sortedEvents).map((eventId, index) => {
        const event = sortedEvents[eventId];
        const tickets = Tickets(event);
        if (tickets.length > 0) {
          ticketsExist = true;
        }
        console.log(event.ticketsPurchased);
        events.push(
          <EventListItem
            key={event.eventId}
            eventId={event.eventId}
            city={event.city}
            state={event.state}
            startsOn={event.startsOn}
            endsOn={event.endsOn}
            ticketsPurchased={Number(event.ticketsPurchased)}
            iboTicketsLeft={Number(event.iboTicketsLeft)}
            tickets={event.tickets}
            onTicketsChanged={({ target }) =>
              onTicketsChanged({ target, eventId: event.eventId })
            }
          >
            {tickets}
          </EventListItem>
        );
      });

      toggleFooter(ticketsExist); // Set footer state
    }

    // console.log("RENDER", Date.now());

    return events || <span> </span>;
  };

  const LocationOptions = () =>
    Object.keys(locations).map((key, index) => (
      <option key={key} value={locations[key].value}>
        {locations[key].label}
      </option>
    ));

  const TimeRemaining = () => {
    const seconds = (cartData.data.countDownSeconds / 60.0) | "0";
    const remainder = cartData.data.countDownSeconds % 60.0 | "00";
    return `${seconds}:${remainder.toString().padStart(2, 0)}`;
  };

  //
  // Return Objects
  //

  return (
    <div className={styles.container}>
      {/* <AppHeader title="Event Name" /> */}
      <div className={styles.containerLocations}>
        <div className={styles.containerLocationHeading}>
          <h2 className={styles.locationHeading}>Locations</h2>
        </div>
        <div className={styles.containerLocationSelector}>
          <Selector
            className={styles.locationSelector}
            value={locationState}
            onChange={onLocationChange}
          >
            <LocationOptions />
          </Selector>
        </div>
      </div>

      <EventListItems />

      {footerIsOpen && (
        <AppFooter className={styles.footer}>
          <div className={styles.containerFooterContent}>
            <center className={styles.containerCheckoutButton}>
              <Button
                onClick={() => onCheckoutClick()}
                className={styles.checkoutButton}
              >
                Checkout
              </Button>
            </center>
            <div className={styles.containerCountDownTimer}>
              <small className={styles.countDownTimer}>
                You have <TimeRemaining /> to complete this purchase
              </small>
            </div>
          </div>
        </AppFooter>
      )}
      <Modal visible={modalState.visible} children={modalState.children} />
    </div>
  );
}
