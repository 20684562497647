import React, { FC } from "react";
import EventTicketItem from "components/EventTicketItem";
import styles from "./EventsList.module.css";
import { EventInfo, EventItem, UserIBO } from "stores/homesiteInterface";
// import { UserIBO } from "stores/interfaces.types";

interface IProps {
  eventTickets: EventItem[];
  // eventTickets: EventInfo[];
  users: UserIBO[];
  onClickAdd: Function;
  onClickCancel: Function;
  onClickPurchased: Function;
}

export const EventsList: FC<IProps> = ({
  eventTickets,
  users,
  onClickAdd,
  onClickCancel,
  onClickPurchased,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.title}>
        <p>Event</p>
        <p>Tickets</p>
      </div>
      {eventTickets.map((item, index) => {
        const usersWithMatchingTickets = users.map((user) => {
          const matchingTickets = user.tickets.filter(
            (ticket) =>
              ticket.eventId === item.eventId &&
              ticket.eventTicketId === item.ticketId
          );

          return {
            ...user,
            tickets: matchingTickets,
          };
        });

        return (
          <div key={index} className={styles.eventItem}>
            <EventTicketItem
              event={item}
              users={usersWithMatchingTickets}
              // onClickAdd={onClickAdd}
              onClickPurchased={onClickPurchased}
              // onClickCancel={onClickCancel}
              onClickAdd={(user) => onClickAdd(item, user)}
              // onClickPurchased={(user) => onClickPurchased(item, user)}
              onClickCancel={(user) => onClickCancel(item, user)}
            />
          </div>
          // ))}
        );
      })}
    </div>
  );
};
