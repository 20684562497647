//Data for each item
export interface EventInfo {
  event: EventItem[];
  // user: UserIBO[];
  // ticket: Ticket[];
}

//Item
export interface EventItem {
  eventId: number;
  title: string;
  subtitle: string;
  speaker: Speaker;
  startsOn: string | null | undefined;
  endsOn: string | null | undefined;
  isAvailable: boolean;
  isVirtual: boolean;
  isInPerson: boolean;
  location: Location;
  eventSessions: EventSessions[];
  eventSets: EventSets[];

  notes: Notes[];
  tickets: Tickets[];
  //added
  users?: UserIBO[];
}

export const defaultEvent: EventItem = {
  eventId: 0,
  title: "",
  subtitle: "",
  isAvailable: false,
  startsOn: "",
  endsOn: "",
  isInPerson: false,
  isVirtual: false,
  location: {
    city: "",
    country: "",
    line1: "",
    line2: "",
    state: "",
    zip: "",
    venueName: "",
  },
  notes: [],
  speaker: {
    name: "",
    src: "",
  },
  tickets: [],
  eventSessions: [],
  eventSets: [],

  users: [],
};

export interface Location {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  venueName: string;
}

export interface Speaker {
  name: string;
  src: string;
}

export interface LocalFunctionsData {
  eventTemplateId: number;
  templateName: string;
  templateType: string;
  icon: string;
  headerText: string;
  description: string | null;
  promoStartsOn: string;
  promoEndsOn: string;
  primaryButtonAction: PrimaryButtonAction;
  primaryButtonText: string;
  secondaryButtonText: string | null;
  secondaryButtonAction: SecondaryButtonAction | null;
  items: EventItem[];

  users: UserIBO[];
}

export interface Notes {
  id: number;
  note: string;
}

export interface PrimaryButtonAction {
  component: string;
  data: {
    contentId: string;
  };
}

interface SecondaryButtonAction {
  // Not sure what data in here
}

export interface Tickets {
  ticketId: number;
  title: string;
  currencyCode: string;
  priceStr: string;
  priority: number;
  acceptedCreditTypeId: number;
  commonItemId: number;
  creditsRequired: number;
  isLimitedToGroups: boolean;
  onlyCreditsAllowed: boolean | null;
  tandemEventId: number;
  tandemTicketId: number;
  tandemTicketStatusId: number;

  status: TicketStatus;
  tickets: UserTickets[];
}

export interface EventSessions {
  eventId: number;
  parentSessionId: number;
  title: string;
  image: string;
  sessionId: number;
  sessionNote: string;
  sessionType: string;
  startsOn: string;
  endsOn: string;
  primaryCaption: string;
  secondaryCaption: string;
  room: string;
}

export interface EventSets {
  eventId: number;
  eventSetIdId: number;
  icon: string;
  limitPerRule: number;
  limitRule: string;
  sort: number;
  subtitle: string;
  title: string;
  eventSetTickets: EventSetTickets[];
}

export interface EventSetTickets {
  ticketId: number;
  title: string;
  acceptedCreditTypeId: number;
  commonItemId: number;
  creditsRequired: number;
  currencyCode: string;
  isLimitedToGroups: boolean;
  onlyCreditsAllowed: boolean | null;
  priceStr: string;
  priority: number;
  tandemEventId: number;
  tandemTicketId: number;
  tandemTicketStatusId: number;
}

// export interface UserIBO {
//   Name: string;
//   UserId: number;
//   src: string;
//   tickets: Ticket[];
// }

export interface UserIBO {
  contactId: number;
  name: string;
  userId: number;
  src: string;
  loa: LOA;
  tickets: UserTickets[];
}

export interface UserTickets {
  amount: number;
  attendeeTicketId: number;
  commonCartTypeId: number;
  commonItemId: number;
  companyId: string;
  eventId: number;
  eventTicketId: number;
  eventType: string;
  isInPerson: boolean;
  isVirtual: boolean;
  endsOn: string;
  location: Location;
  startsOn: string;
  statusStr: string;
  subTitle: string;

  taxAmount: number;
  taxRate: number;
  template: string;
  ticketStatusId: number;
  title: string;
  type: string;
  userId: number;
  // isValidMatch: boolean;
  // eventTicketDetails: Tickets;
}

export interface LOA {
  pinLevel: number;
  diamond: number;
  emerald: number;
  platinum: number;
  sponsor: number;
}

export enum TicketStatus {
  // NEW = 1,
  // SELECTED = 2,
  // PURCHASED = 3,
  // CART = 4,
  // INCLUDED = 5,
  // UNAVAILABLE = 6,

  AVAILABLE = 0,
  CART = 1,
  CART_INCLUDED = 2,
  PURCHASED = 3,
  PURCHASED_INCLUDED = 4,
  UNAVAILABLE = 6,
}

export interface LocationState {
  event: EventItem | null;
  items: EventItem[];
}

// export interface User {
//   Name: string;
//   UserId: number;
//   src: string;
//   tickets: Ticket[];
// }
